import FileInputService from '@isceco/widget-library2/basic-elements/FileInput/FileInputService.js'
import ConfigUtil from './ConfigUtil.js'

export default class VzavgFileInputService extends FileInputService {

  constructor(url) {
    super(url);
    this.configUtil = new ConfigUtil()
  }

  setUrl(url) {
    this.url = url
  }

  async upload(file) {
    await this.configUtil.updateToken();
    const form = new FormData();
    form.append('fileName', file.name);
    form.append('file', file);
    return fetch(this.url, this.getOptions(form));
  }

}
