import {html} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../../WebComponent.js'

export default class HilfeInfoText extends WebComponent {

  get translationFile() {
    return './views/Administration/HilfeInfoText/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    this.render()
  }

  getTemplate() {
    return html`
      <h1>${this.i18n.translate('hilfeinfotext.header')}</h1>
    `
  }

}
customElements.define('vzavg-frontend-hilfeinfotext', HilfeInfoText)
