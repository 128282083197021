import {css, html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/List/List.js'
import WebComponent from '../../../WebComponent.js'
import AdresseColumns from './AdresseColumns.js'
import AdresseRowVariant from './AdresseRowVariant.js'
import VzavgListService from '../../../services/VzavgListService.js'
import DefaultMobileItem from '../../../components/DefaultMobileItem/DefaultMobileItem.js'
import GesuchService from '../../../services/GesuchService.js'
import I18n from '../../../i18n.js'
import AuthorizationFilter from '../../Authorization/AuthorizationFilter.js'
import {geschaeftsVorfallNavigationLinks, geschaeftsVorfallNavigationLinksHelper} from '../Geschaeftsvorfall'

export default class Adresse extends WebComponent {

  static get EVENT_KEYS() {
    return {
      TRIGGER_ADRESSE_TABLE_RELOAD: 'geschaeftvorfall.adresse.table.reload.event'
    }
  }

  constructor(isClosedForKanton, isClosedForSeco) {
    super()
    this.isClosedForKanton = isClosedForKanton
    this.isClosedForSeco = isClosedForSeco
    this.gesuchService = new GesuchService()
  }

  get css() {
    return css`
      .buttons {
        margin-top: 3em;
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        gap: 0.5em;
      }

      .title-content {
        display: flex;
        gap: 1em;
        margin-bottom: 0.5em;
        align-items: center;
      }

      .title-content .status {
        font-size: 1.5em;
        margin-top: 0.5em;
      }
    `
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Adresse/i18n.json'
  }

  async connectedCallback() {
    super.connectedCallback();

    this.columns = [
      AdresseColumns.STATUS_COLUMN(),
      AdresseColumns.TYP_COLUMN(this.i18n),
      AdresseColumns.UID_COLUMN(),
      AdresseColumns.FIRMENBEZ_COLUMN(),
      AdresseColumns.STREET_COLUMN(),
      AdresseColumns.PLZ_COLUMN(),
      AdresseColumns.ORT_COLUMN(),
      AdresseColumns.DATUM_BIS_COLUMN({hasWriteAccess: !AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton,this.isClosedForSeco), adresseObject: this}),
      AdresseColumns.VALIDITAET_COLUMN(),
      AdresseColumns.AKTIONEN_COLUMN({hasWriteAccess: !AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton,this.isClosedForSeco) ,callback: this._rowActionMenu})
    ]

    this._refresh = () => {
      send(Adresse.EVENT_KEYS.TRIGGER_ADRESSE_TABLE_RELOAD)
      this.reload()
    }
    document.addEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)

    this.gesuchId = getNavigationId()

    this.url = await this.gesuchService.getBackendListUrl(`${this.gesuchId}/adresse`)
    this.render()
  }

  getTemplate() {
    return html`
      <isceco-title text="${this.i18n.translate('geschaeftsvorfall.adresse.header')}">
        <div class="title-content">
          <isceco-button
            id="buttonDokumenteAnzeigen"
            icon="file pdf outline"
            variant="secondary"
            @click="${_ => this._openDokumenteViewer()}"
          ></isceco-button>
          <isceco-button
            id="adresse-add-adresse-btn"
            title="${this.i18n.translate('geschaeftsvorfall.adresse.button.create')}"
            icon="plus"
            variant="primary"
            @click="${_ => navigate({to: geschaeftsVorfallNavigationLinks.adresseEdit.hash, id: this.gesuchId})}"
            ?disabled="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton,this.isClosedForSeco)}"
          ></isceco-button>
          <isceco-button
            id="buttonWeiter"
            variant="primary"
            icon="chevron right"
            @click="${_ => this._handleClickNext()}"
          ></isceco-button>
        </div>
      </isceco-title>

      ${this._getTemplateList()}
    `
  }

  _getTemplateList() {
    return html`
      <isceco-list
        id="adresse_list"
        .listService="${new VzavgListService(this.url)}"
        .columns="${this.columns}"
        .rowVariant="${new AdresseRowVariant()}"
        .i18n="${this.i18n}"
        .mobileView="${DefaultMobileItem.getDefaultRenderer(this.i18n)}"
        order="desc"
        update-event="${Adresse.EVENT_KEYS.TRIGGER_ADRESSE_TABLE_RELOAD}"
      ></isceco-list>
    `
  }

  _openDokumenteViewer() {
    const to = `#${geschaeftsVorfallNavigationLinks.documentViewer.hash}`
    const id = this.gesuchId
    window.open(constructURL({to, id}), "_blank");
  }

  _rowActionMenu = (row, action) => {
    if (action === 'edit') {
      navigate({to: geschaeftsVorfallNavigationLinks.adresseEdit.hash, id: `${this.gesuchId}/${row.id}`})
    }
  }
  _handleClickNext() {
    const link = geschaeftsVorfallNavigationLinksHelper.next()
    navigate({to: link.hash, id: this.gesuchId})
  }

  _updateDateBis(row, e) {
    if (e.detail || e.detail === '') {
      row.bis = e.detail === '' ? null : e.detail
      row.updateFromAdressePage = true
      this.gesuchService.update(row, `${this.gesuchId}/adresse`)
        .then(_ => {
          send(Adresse.EVENT_KEYS.TRIGGER_ADRESSE_TABLE_RELOAD)
          showAlert(
            this.i18n.translate('geschaeftsvorfall.adresse.update.success.title'),
            this.i18n.translate('geschaeftsvorfall.adresse.update.success.message'),
            'success'
          )
        })
    }
  }

}
customElements.define('vzavg-frontend-geschaeftsvorfall-adresse', Adresse);
