import {html} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../../WebComponent.js'
import '@isceco/widget-library2/basic-elements/ModalDialog/ModalDialog.js'
import '@isceco/widget-library2/basic-elements/TextInput/TextInput.js'
import '@isceco/widget-library2/basic-elements/Dropdown/Dropdown.js'
import '@isceco/widget-library2/basic-elements/List/List.js'
import VzavgListService from '../../../services/VzavgListService.js'
import DefaultMobileItem from '../../../components/DefaultMobileItem/DefaultMobileItem.js'
import RechteColumns from './RechteColumns.js'
import StammdatenService from '../../../services/StammdatenService.js'
import RechtService from '../../../services/RechtService.js'
import I18n from '../../../i18n.js'

export default class Rechte extends WebComponent {

  static IDENTIFIER = 'rechte'

  static get EVENT_KEYS() {
    return {
      RELOAD_TRIGGERED: 'rechte-reload-triggered'
    }
  }

  get translationFile() {
    return './views/Administration/Rechte/i18n.json'
  }

  constructor() {
    super()
    this.rechtService = new RechtService()
    this.stammdatenService = new StammdatenService()
    this.oe = []
    this.data = {}
  }

  connectedCallback() {
    super.connectedCallback()

    this._refresh = () => {
      send(Rechte.EVENT_KEYS.RELOAD_TRIGGERED)
    }
    document.addEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)

    this.columns = [
      RechteColumns.EMAIL_COLUMN(),
      RechteColumns.KANTON_COLUMN(),
      RechteColumns.AKTIONEN_COLUMN({
        callback: this._rowActionMenu
      })
    ]

    const catchOEPromise = () => {
      showAlert('', this.i18n.translate('rechte.kantone.stammdaten.init.error'), 'error')
    }

    Promise.all([
      this.stammdatenService.getOrganisation().catch(catchOEPromise),
      this.rechtService.getBackendListUrl()])
      .then(([oes, url]) => {
        this.oe = oes.map(oe => ({value: oe.code, name: oe.bezeichnung ?? ''}))
        this.url = url
        this.render()
      }).catch(_ => this.reload())
  }

  disconnectedCallback() {
    document.removeEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)
  }

  getTemplate() {
    return html`
      <isceco-title text="${this.i18n.translate('rechte.header')}">
        <isceco-button icon="plus"
                       variant="primary"
                       size="small"
                       @click="${_ => this._openAddDialog()}"
                       id="rechte-add-btn"
        ></isceco-button>
      </isceco-title>

      <isceco-list
        id="rechte-berechtigungen-list"
        max="20"
        order="asc"
        .listService="${new VzavgListService(this.url)}"
        identifier="${Rechte.IDENTIFIER}"
        update-event="${Rechte.EVENT_KEYS.RELOAD_TRIGGERED}"
        .columns="${this.columns}"
        .i18n="${this.i18n}"
        .mobileView="${DefaultMobileItem.getDefaultRenderer(this.i18n)}">
      </isceco-list>

      <isceco-dialog
        id="rechte-add-dialog"
        hidden
        confirm-button="${this.i18n.translate('rechte.add.dialog.bestaetigen')}"
        cancel-button="${this.i18n.translate('rechte.add.dialog.abbrechen')}"
        @submit="${e => this._closeDialog(e)}">
        <isceco-text-input style="margin-bottom: 10px;"
                           id="rechte-add-dialog-email"
                           label="${this.i18n.translate('rechte.add.email')}*"
                           type="email"
                           maxlength="255"
                           value=""
                           required
                           @change="${e => this._updateValue('email', e)}">
        </isceco-text-input>
        <isceco-dropdown
          id="rechte-add-dialog-kanton"
          label="${this.i18n.translate('rechte.add.kanton')}"
          value=""
          .items="${this.oe}"
          @change="${e => this._updateValue('codeKanton', e)}">
        </isceco-dropdown>
      </isceco-dialog>
    `
  }

  _rowActionMenu = (row, actions) => {
    this.rechtService.delete(row.id)
      .then(_ => {
        showAlert('', this.i18n.translate('rechte.liste.delete.msg'), 'success')
        this._refresh()
      });
  }

  _updateValue(key, event) {
    this.data[key] = event.detail.value
  }

  _openAddDialog() {
    const textinputEmail = document.getElementById('rechte-add-dialog-email')
    textinputEmail.value = ''
    const textinputKanton = document.getElementById('rechte-add-dialog-kanton')
    textinputKanton.value = ''
    const dialog = document.getElementById('rechte-add-dialog');
    dialog.hidden = false;
  }

  _closeDialog(event) {
    if (event.detail.confirmed) {
      this.rechtService.create(this.data)
        .then(_ => {
          event.target.hidden = true
          showAlert('', this.i18n.translate('rechte.liste.create.msg'), 'success')
          this._refresh()
        });
    } else {
      event.target.hidden = true
    }
  }

}
customElements.define('vzavg-frontend-rechte', Rechte)
