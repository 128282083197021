import BaseService from '../BaseService'


export default class KautionService extends BaseService {

  constructor() {
    super('kaution')
  }

}
