import BaseService from '../BaseService'
import VzavgListService from './VzavgListService'


export default class BewilligungService extends BaseService {

  constructor() {
    super('gesuch')
  }

  async getListService(gesuchId) {
    return this.getBackendListUrl(`${gesuchId}/bewilligung`)
      .then(url => new VzavgListService(url))
  }

  async getBewilligung(gesuchId, bewilligungId) {
    await this.retrieveBackendUrl();
    return this.checkError(
      fetch(`${this.backendUrl}/${gesuchId}/bewilligung/${bewilligungId}`, {
        method: 'GET',
        cache: 'reload',
        headers: this.getJsonHeader()
      })
    )
  }

  updateBewilligung(formDatas, gesuchId) {
    return super.update(formDatas, `${gesuchId}/bewilligung`)
  }
}
