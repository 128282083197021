import GenericColumn from '@isceco/widget-library2/basic-elements/List/column/GenericColumn'
import DateColumn from '@isceco/widget-library2/basic-elements/List/column/DateColumn'
import StringColumn from '@isceco/widget-library2/basic-elements/List/column/StringColumn'
import DefaultActionCell from '../../common/list/DefaultActionCell'

export default class CockpitPendenzenColumns {

  static EINGANGSDATUM_COLUMN() {
    return new DateColumn({
      key: 'eingangsdatum',
      text: 'cockpit.pendenzen.list.eingangsdatum',
      weight: 2,
      sortable: true
    })
  }

  static BETRIEB_COLUMN() {
    return new StringColumn({
      key: 'firmenbezeichnung',
      text: 'cockpit.pendenzen.list.betrieb',
      weight: 2,
      sortable: true
    })
  }

  static BETREFF_COLUMN() {
    return new StringColumn({
      key: 'betreff',
      text: 'cockpit.pendenzen.list.betreff',
      weight: 2,
      sortable: true
    })
  }

  static SENDER_COLUMN() {
    return new StringColumn({
      key: 'sender',
      text: 'cockpit.pendenzen.list.sender',
      weight: 2,
      sortable: true
    })
  }

  static EMPFAENGER_COLUMN() {
    return new StringColumn({
      key: 'empfaenger',
      text: 'cockpit.pendenzen.list.empfaenger',
      weight: 2,
      sortable: true
    })
  }

  static TERMIN_COLUMN() {
    return new DateColumn({
      key: 'termin',
      text: 'cockpit.pendenzen.list.termin',
      weight: 2,
      sortable: true
    })
  }

  static AKTIONEN_COLUMN(configuration) {
    const {callback} = configuration
    return new GenericColumn({
      key: 'aktionen',
      text: '',
      weight: 0.5,
      align: 'right',
      cell: new DefaultActionCell({
        callback: callback,
        buttons: i18n => {
          const buttons = []

          buttons.push({
            action: 'edit',
            icon: 'clipboard',
            variant: 'primary',
            title: i18n.translate('cockpit.pendenzen.list.actions.detail')
          })

          return buttons
        }
      })
    })
  }

}
