import BaseService from '../BaseService';
import VzavgListService from './VzavgListService.js';

export default class NotizService extends BaseService {
  constructor() {
    super('gesuch')
  }

  async getListService(gesuchId) {
    return this.getBackendListUrl(`${gesuchId}/notiz`)
      .then(url => new VzavgListService(url))
  }

  async getNotiz(gesuchId, notizId) {
    return super.read(gesuchId, `notiz/${notizId}`)
  }

  async updateNotiz(formDatas, gesuchId) {
    return super.update(formDatas, `${gesuchId}/notiz`)
  }

  async createNotiz(formDatas, gesuchId) {
    return super.create(formDatas, `${gesuchId}/notiz`)

  }

  async deleteNotiz(gesuchId, notizId) {
    return super.delete(gesuchId, `notiz/${notizId}`)
  }
}
