import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Button/Button'

export default class VerantwortlichePersonPrioCell {

  static EVENT_KEY_MOVE_PRIO_UP = 'move-prio-up'
  static EVENT_KEY_MOVE_PRIO_DOWN = 'move-prio-down'

  /**
   * @inheritDoc
   * @param {int} maxPrio
   */
  constructor(maxPrio) {
    this.maxPrio = maxPrio;
  }

  render(i18n, cell, row) {
    return html`
        <div class="prio-wrapper" style="display: flex; align-items: center">
            <div style="${cell === undefined ? 'display: none' : ''}">
                <isceco-button variant="tertiary" icon="angle up" size="small" ?disabled="${Number(cell) === 1}"
                               @click="${_ => send(VerantwortlichePersonPrioCell.EVENT_KEY_MOVE_PRIO_UP, this._getPrioPayload(cell, row))}"></isceco-button>
                <isceco-button variant="tertiary" icon="angle down" size="small"
                               ?disabled="${Number(cell) === this.maxPrio}"
                               @click="${_ => send(VerantwortlichePersonPrioCell.EVENT_KEY_MOVE_PRIO_DOWN, this._getPrioPayload(cell, row))}"></isceco-button>
            </div>
            <span>${cell}</span>
        </div>
    `
  }

  _getPrioPayload = (cell, row) => ({value: {currentPrio: Number(cell), rowId: row.id}})
}
