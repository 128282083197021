import StringColumn from '@isceco/widget-library2/basic-elements/List/column/StringColumn.js'
import DateColumn from '@isceco/widget-library2/basic-elements/List/column/DateColumn.js'
import GenericColumn from '@isceco/widget-library2/basic-elements/List/column/GenericColumn.js'
import DefaultActionCell from '../../../common/list/DefaultActionCell.js'

export default class ChecklistenColumns {
  static TYP_COLUMN() {
    return new StringColumn({
      key: 'kategorie',
      text: 'checklisten.kategorie',
      sortable: true
    })
  }

  static BEREICH_COLUMN() {
    return new StringColumn({
      key: 'bereich',
      text: 'checklisten.bereich',
      sortable: true
    })
  }

  static ERSTELLEN_VON_COLUMN() {
    return new StringColumn({
      key: 'erstellenVon',
      text: 'checklisten.erstellen.von',
      sortable: true
    })
  }

  static ERSTELLEN_AM_COLUMN() {
    return new DateColumn({
      key: 'erstellenAm',
      text: 'checklisten.erstellen.am',
      sortable: true
    })
  }

  static GUELTIG_VON_COLUMN() {
    return new DateColumn({
      key: 'gueltigVon',
      text: 'checklisten.gueltig.von',
      sortable: true
    })
  }

  static GUELTIG_BIS_COLUMN() {
    return new DateColumn({
      key: 'gueltigBis',
      text: 'checklisten.gueltig.bis',
      sortable: true
    })
  }

  static AKTIONEN_COLUMN(configuration) {
    const {callback} = configuration
    return new GenericColumn({
      key: 'aktionen',
      text: '',
      weight: 0.5,
      align: 'right',
      cell: new DefaultActionCell({
        callback: callback,
        buttons: i18n => {
          const buttons= []

          buttons.push({
            action: 'edit',
            icon: 'edit',
            variant: 'primary',
            title: i18n.translate('checklisten.actions.edit')
          })

          buttons.push({
            action: 'copy',
            icon: 'copy',
            variant: 'primary',
            title: i18n.translate('checklisten.actions.copy')
          })

          return buttons
        }
      })
    })
  }
}
