export default class Authorization {

  static ROLES() {
    return {
      user: 'SECO-vzavg.ALLOW',
      betrieb: 'SECO-vzavg.Betrieb',
      super: 'SECO-vzavg.Super',
      kanton: 'SECO-vzavg.Kanton',
      seco: 'SECO-vzavg.Seco'
    }
  }

  static VIEWS() {
    return {
      notAuthorized: 'NotAuthorized'
    }
  }

}
