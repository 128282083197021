import GenericColumn from '@isceco/widget-library2/basic-elements/List/column/GenericColumn.js'
import DateColumn from '@isceco/widget-library2/basic-elements/List/column/DateColumn.js'
import StringColumn from '@isceco/widget-library2/basic-elements/List/column/StringColumn.js'
import DefaultActionCell from '../../common/list/DefaultActionCell.js'
import KautionCurrencyCell from '../Geschaeftsvorfall/Kaution/KautionCurrencyCell.js'
import CurrencyCell from './CurrencyCell.js'

export default class CockpitKautionenColumns {

  static KAUTIONSKANTON_COLUMN() {
    return new StringColumn({
      key: 'codeKanton',
      text: 'cockpit.kautionen.list.kautionsKanton',
      weight: 2,
      sortable: true
    })
  }

  static KAUTIONSORT_COLUMN() {
    return new StringColumn({
      key: 'ort',
      text: 'cockpit.kautionen.list.ort',
      weight: 2,
      sortable: true
    })
  }

  static KAUTIONSUMME_COLUMN() {
    return new GenericColumn({
      key: 'summeKautionen',
      text: 'cockpit.kautionen.list.kautionsSumme',
      weight: 3,
      sortable: true,
      cell: new CurrencyCell()
    })
  }

  static BENOETIGTEKAUTIONSUMME_COLUMN() {
    return new GenericColumn({
      key: 'benoetigteKautionssumme',
      text: 'cockpit.kautionen.list.benoetigtekautionssumme',
      weight: 3,
      sortable: true,
      cell: new CurrencyCell()
    })
  }

  static BETRIEB_COLUMN() {
    return new StringColumn({
      key: 'firmenbezeichnung',
      text: 'cockpit.kautionen.list.betrieb',
      weight: 2,
      sortable: true
    })
  }


  static KAUTIONSDATUM_COLUMN() {
    return new DateColumn({
      key: 'kautionsDatum',
      text: 'cockpit.kautionen.list.kautionsdatum',
      weight: 2,
      sortable: true
    })
  }


  static KAUTIONSGEBER_COLUMN() {
    return new StringColumn({
      key: 'name',
      text: 'cockpit.kautionen.list.kautionsgeber',
      weight: 2,
      sortable: true
    })
  }

  static KAUTIONSART_COLUMN() {
    return new StringColumn({
      key: 'kautionsArt',
      text: 'cockpit.kautionen.list.kautionsart',
      weight: 2,
      sortable: true
    })
  }

  static KAUTIONSBETRAG_COLUMN() {
    return new GenericColumn({
      key: 'kautionsBetrag',
      text: 'cockpit.kautionen.list.kautionsbetrag',
      weight: 2,
      sortable: true,
      cell: new KautionCurrencyCell()
    })
  }

  static SPERRFRIST_COLUMN() {
    return new DateColumn({
      key: 'sperrfrist',
      text: 'cockpit.kautionen.list.sperrfrist',
      weight: 2,
      sortable: true
    })
  }

  static BEFRIST_BIS_COLUMN() {
    return new DateColumn({
      key: 'befristBis',
      text: 'cockpit.kautionen.list.befrist.bis',
      weight: 2,
      sortable: true
    })
  }

  static KAUTIONSSTATUS_COLUMN() {
    return new StringColumn({
      key: 'kautionsStatus',
      text: 'cockpit.kautionen.list.kautionsstatus',
      weight: 1,
      sortable: true
    })
  }

  static AKTIONEN_COLUMN(configuration) {
    const {callback} = configuration
    return new GenericColumn({
      key: 'aktionen',
      text: '',
      weight: 1,
      align: 'right',
      cell: new DefaultActionCell({
        callback: callback,
        buttons: i18n => {
          const buttons = []

          buttons.push({
            action: 'edit',
            icon: 'clipboard',
            variant: 'primary',
            title: i18n.translate('cockpit.kautionen.list.actions.detail')
          })

          return buttons
        }
      })
    })
  }

}
