export default class Navigation {

  static get NAVIGATION_HAPPEND() {
    return 'vzavg-frontend-navigation-happened'
  }

  constructor() {
    window.onhashchange = e => {
      if (hasChanges) {
        history.replaceState(null, null, e.oldURL)
        return window.send('beforeunload', {
          leavingFrom: getNavigationHash(),
          leavingTo: this._convertUrlToNavigableObject(e.newURL)
        })
      }
      window.hasChanges = false
      return window.send(Navigation.NAVIGATION_HAPPEND, getNavigationHash())
    }
  }

  _convertUrlToNavigableObject(url) {
    const appPath = url.split('#')[1];
    const split = appPath.split('/');

    const to = split[0];

    let id1 = null
    if (split.length > 1) {
      id1 = split[1]
    }

    let id2 = null
    if (split.length > 2) {
      id2 = split[2]
    }

    let params = null
    if (appPath.split('?').length > 0) {
      params = appPath.split('?')[1]
    }

    let id = id1
    if (id2) {
      id = `${id1}/${id2}`
    }

    return {id: id, to, params}
  }
}
const navigation = new Navigation()
