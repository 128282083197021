import WebComponent from '../../../WebComponent.js'
import {css, html} from '@isceco/widget-library2/external/lit/index.js'
import SucheTemplateService from '../../../services/SucheTemplateService.js'
import {suchenNavigationLinkMap} from '../Suchen.js'
import SuchenNavigation from '../Navigation/SuchenNavigation.js'
import '@isceco/widget-library2/basic-elements/Form/Form'
import {List} from '@isceco/widget-library2/basic-elements/List/List.js'

export default class SuchenTemplate extends WebComponent {

  static TEMPLATE_NAME_INPUT = 'search-template-name-input'

  constructor() {
    super()
    this.sucheTemplateService = new SucheTemplateService()
    this.selectedFilterTemplate = null
    this.scope = this._getByValue(suchenNavigationLinkMap, getNavigationHash())
    this.activeFilters = null
  }

  get css() {
    return css`
      .save-filter-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 1em;
        gap: 1em;
        flex-wrap: wrap;

      }


      .save-filter-wrapper isceco-dropdown {
        flex-grow: 1;
        align-self: baseline;
      }

      .save-filter-wrapper isceco-text-input {
        flex-grow: 1;
        align-self: baseline;
      }
    `
  }

  get translationFile() {
    return './views/Suchen/Template/i18n.json'
  }

  get filterTemplateItems() {
    return this.filterTemplates.map(ft => ({name: ft.name, value: ft.id}))
  }

  get selectedFilterTemplateItem() {
    return this.selectedFilterTemplate?.id
  }

  _getByValue(map, searchValue) {
    for (const [key, value] of map.entries()) {
      if (value === searchValue) {
        return key
      }
    }
    return undefined
  }

  async connectedCallback() {
    super.connectedCallback()
    this.filterTemplates = await this.sucheTemplateService.readSucheTemplates()
    this._setActiveFilters = event => {
      this.activeFilters = {}
      for (const [key, value] of Object.entries(event.detail.params)) {
        if (value) {
          this.activeFilters[key] = value
        }
      }
    }
    window.addEventListener(List.EVENT_KEYS.RELOAD_TRIGGERED, this._setActiveFilters)
    this.render()
  }

  disconnectedCallback() {
    window.removeEventListener(List.EVENT_KEYS.RELOAD_TRIGGERED, this._setActiveFilters)
  }

  getTemplate() {
    return html`
        <div class="save-filter-wrapper">
            <isceco-dropdown
                    id="search-template-filters"
                    .items="${this.filterTemplateItems}"
                    label="${this.i18n.translate('suchen.template.saved')}"
                    .value="${this.selectedFilterTemplateItem}"
                    placeholder="${this.i18n.translate(this.filterTemplates?.length ? 'suchen.template.saved.select' : 'suchen.template.saved.no')}"
                    @change="${e => this._selectFilterTemplate(e.detail.value)}"></isceco-dropdown>
            <isceco-text-input
                    id="${SuchenTemplate.TEMPLATE_NAME_INPUT}"
                    value="${this.selectedFilterTemplate?.name}"
                    label="${this.i18n.translate('suchen.template.name')}"
                    placeholder="${this.i18n.translate('suchen.template.name.placeholder')}"
                    maxlength="100"
                    required
                    @input="${_ => this._updateSelectedTemplate()}"
            ></isceco-text-input>
            <isceco-button
                    id="search-template-save-button"
                    text="${this.i18n.translate(this.selectedFilterTemplateItem ? 'suchen.template.update' : 'suchen.template.save')}"
                    @click="${e => this._saveFilters(e)}"
            ></isceco-button>
            <isceco-button
                    id="search-template-delete-button"
                    text="${this.i18n.translate('suchen.template.delete')}"
                    .disabled="${!this.selectedFilterTemplateItem}"
                    @click="${() => this._deleteSelectedTemplate()}"
            ></isceco-button>
        </div>
    `
  }

  _selectFilterTemplate(templateId) {
    this.selectedFilterTemplate = this.filterTemplates.find(ft => ft.id === templateId)
    if (this.selectedFilterTemplate) {
      const searchParams = new URLSearchParams()
      for (const [key, value] of Object.entries(this.selectedFilterTemplate.filters)) {
        searchParams.append(key, value.toString())
      }
      const url = `${location.origin}#${suchenNavigationLinkMap.get(this.selectedFilterTemplate.scope.toLowerCase())}`
        + `?${searchParams.toString()}`
      history.pushState({}, '', url)
      window.send(SuchenNavigation.EVENT_KEYS.SCOPE_CHANGED, this.selectedFilterTemplate.scope.toLowerCase())
    }
    this.render()
  }

  _saveFilters() {
    if (!document.getElementById(SuchenTemplate.TEMPLATE_NAME_INPUT).validate()) {
      return
    }

    if (!this.activeFilters) {
      return
    }

    const templateName = document.getElementById(SuchenTemplate.TEMPLATE_NAME_INPUT)?.value

    const filterTemplate = {name: templateName, filters: this.activeFilters, scope: this.scope}
    const updateOrCreateRequest = this.selectedFilterTemplate?.id ? this._updateTemplate : this._createTemplate

    updateOrCreateRequest(filterTemplate).then(res => {
      let id
      if (this.selectedFilterTemplate?.id) {
        id = this.selectedFilterTemplate.id
      } else {
        const location = res.headers.get('location')
        id = Number(location.split('/').pop())
      }
      return Promise.all([Promise.resolve(id), this.sucheTemplateService.readSucheTemplates()])
    }).then(([id, res]) => this._reloadAndSelectFilter(res, id))
  }

  _reloadAndSelectFilter = (filterTemplates, id) => {
    this.filterTemplates = filterTemplates
    this.selectedFilterTemplate = this.filterTemplates.find(ft => ft.id === id)
    this.render()
  }

  _deleteSelectedTemplate() {
    this.sucheTemplateService.delete(this.selectedFilterTemplate.id)
      .then(_ => showAlert(
        this.i18n.translate('suchen.template.delete.success.title'),
        this.i18n.translate('suchen.template.delete.success.message'),
        'success'
      ))
      .then(() => this.sucheTemplateService.readSucheTemplates())
      .then(res => {
        this.filterTemplates = res
        this.selectedFilterTemplate = null
        this.render()
      })
  }

  _updateTemplate = filterTemplate => this.sucheTemplateService.update({
    ...filterTemplate,
    id: this.selectedFilterTemplate.id
  }).then(res => {
    showAlert(
      this.i18n.translate('suchen.template.update.success.title'),
      this.i18n.translate('suchen.template.update.success.message'),
      'success'
    )
    return res
  })

  _createTemplate = filterTemplate => this.sucheTemplateService.create(filterTemplate)
    .then(res => {
      showAlert(
        this.i18n.translate('suchen.template.save.success.title'),
        this.i18n.translate('suchen.template.save.success.message'),
        'success'
      )
      return res
    })

  _updateSelectedTemplate() {
    if (this.selectedFilterTemplateItem) {
      document.getElementById(SuchenTemplate.TEMPLATE_NAME_INPUT).value = this.selectedFilterTemplate?.name
      this.selectedFilterTemplate = null
    }
    this.render()
  }
}
customElements.define('vzavg-frontend-suchen-template', SuchenTemplate)
