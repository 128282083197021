import {css, html} from '@isceco/widget-library2/external/lit';
import WebComponent from '../../../WebComponent.js';
import './StatistikInput'
import StatistikService from '../../../services/StatistikService'
import '@isceco/widget-library2/basic-elements/Form/Form'
import GesuchService from '../../../services/GesuchService'
import '@isceco/widget-library2/basic-elements/Popup/Popup.js'
import {noYesInternationalFlOptions} from '../Gesuch/Gesuch'
import '@isceco/widget-library2/basic-elements/NumberInput/NumberInput'

export default class StatistikdatenVorjahre extends WebComponent {

  static AV_FORM_ID = 'av-form'
  static PV_FORM_ID = 'pv-form'
  static YEAR_INPUT_ID = 'year-input'

  get css() {
    return css`
      .wrapper {
        max-width: 1500px;
      }

      .button-wrapper {
        display: flex;
        gap: 1em;
        justify-content: flex-end;
        margin-top: 2em;
      }

      .betrieb-info {
        display: grid;
        grid-template-columns: max-content auto;
        gap: 0.5em 1em;
      }

      .form-elements {
        display: grid;
        grid-template-columns: 3fr 1fr 1fr 1fr 1fr 1fr;
        gap: 1em;
      }

      isceco-form, statistik-input {
        display: grid;
        grid-template-columns: subgrid;
        grid-column: 1 / 7;
      }

      .input-wrapper {
        display: grid;
        grid-template-columns: subgrid;
        grid-column: 1 / 7;
        align-items: center;
        text-align: center;
      }

      .input-wrapper label {
        margin-right: auto;
      }

      .year-nav-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .year {
        margin-left: 1em;
        margin-right: 1em;
        font-size: 1.2em;
      }
    `
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Statistikdaten/i18n.json'
  }

  get hasData() {
    return !!this.backendData && this.backendData.length > 0
  }

  get hasAV() {
    return !!this.gesuch.arbeitsVermittlung && this.gesuch.arbeitsVermittlung !== 'no'
  }

  get hasPV() {
    return !!this.gesuch.personalVerleih && this.gesuch.personalVerleih !== 'no'
  }

  get betriebsArt() {
    let betriebsArt = ""
    if (this.hasAV) {
      const arbeitsVermittlungOption = noYesInternationalFlOptions(this.i18n).find(av => av.value === this.gesuch.arbeitsVermittlung).name
      betriebsArt = `${this.i18n.translate("geschaeftsvorfall.statistikdaten.av")} (${arbeitsVermittlungOption})`
    }

    if (this.hasPV) {
      const personalverleihOption = noYesInternationalFlOptions(this.i18n).find(av => av.value === this.gesuch.personalVerleih).name;
      const personalverleih = `+ ${this.i18n.translate("geschaeftsvorfall.statistikdaten.pv")} (${personalverleihOption})`
      betriebsArt = betriebsArt.length ? `${betriebsArt} ${personalverleih}` : personalverleih;
    }
    if (!this.hasPV && !this.hasAV) {
      betriebsArt = this.i18n.translate('geschaeftsvorfall.gesuch.noAVorPV.vorjahre')
    }
    return betriebsArt
  }

  constructor() {
    super()
    this.refYear = new Date().getFullYear()
    this.oldestYear = this.refYear
  }

  connectedCallback() {
    super.connectedCallback()
    this.statistikService = new StatistikService();
    this.gesuchService = new GesuchService()
    this.backendData = {}
    this.loadData()
  }

  disconnectedCallback() {
    super.disconnectedCallback()
  }

  loadData() {
    const betrieb = window.betrieb$.getValue()
    Promise.all([this.statistikService.list(`${betrieb.id}/vorjahre?refYear=${this.refYear}`), this.gesuchService.read(betrieb.gesuchId)]).then(([statistikData, gesuch]) => {
        this.gesuch = gesuch
        this.backendData = statistikData['vorjahre']
        this.oldestYear = statistikData['oldestYear']
        this.render()
      }
    )
  }

  getTemplate() {
    return html`
      <div class="wrapper">
        <h1>${this.i18n.translate('geschaeftsvorfall.statistikdaten.header')}</h1>
        ${!this.hasData ? this.renderBetriebInfo() : html``}
        ${this.hasData ? this.renderYearNavButtons() : html``}
        ${this.hasAV && this.hasData ? this.renderAVStatistik() : html``}
        ${this.hasPV && this.hasData ? this.renderPVStatistik() : html``}
      </div>
    `
  }

  getYearRow(year) {
    return html`
      <isceco-text-input style="font-weight: bold;" value="${year}" type="number" readonly></isceco-text-input>
    `
  }

  getStatistikRow(n) {
    return html`
      <isceco-number-input value="${n}" maximum-fraction-digits="${0}" readonly></isceco-number-input>
    `
  }

  renderBetriebInfo() {
    return html`
      <div class="betrieb-info">
        <span>${this.i18n.translate('geschaeftsvorfall.statistikdaten.email')}</span>
        <span>${window.betrieb$.getValue().email}</span>
        <span>${this.i18n.translate('geschaeftsvorfall.statistikdaten.betriebsart')}</span>
        <span>${this.betriebsArt}</span>
      </div>
    `
  }

  renderYearNavButtons() {
    return html`
      <div class="year-nav-wrapper">
        <isceco-button variant="secondary" icon="angle left" size="small"
                       @click="${_ => this._backward()}"
                       ?disabled="${this.isBackwardDisabled}">
        </isceco-button>
        <div class="year"></div>
        <isceco-button variant="secondary" icon="angle right" size="small"
                       @click="${_ => this._forward()}"
                       ?disabled="${this.isForwardDisabled}">
        </isceco-button>
      </div>
    `
  }

  renderAVStatistik() {
    return html`
      <h2>${this.i18n.translate("geschaeftsvorfall.statistikdaten.av")}</h2>

      <isceco-form>
        <div slot="form-elements" class="form-elements">
          <div class="input-wrapper">
            <label><b>${this.i18n.translate('geschaeftsvorfall.statistikdaten.berichtsjahr')}</b></label>
            ${this.backendData.map(data => this.getYearRow(data.year))}
          </div>
          <div class="input-wrapper">
            <label>${this.i18n.translate('geschaeftsvorfall.statistikdaten.anzahlSchweizer')}</label>
            ${this.backendData.map(data => this.getStatistikRow(data.arbeitsvermittlung.anzahlSchweizer))}
          </div>
          <div class="input-wrapper">
            <label>${this.i18n.translate('geschaeftsvorfall.statistikdaten.anzahlSchweizerinnen')}</label>
            ${this.backendData.map(data => this.getStatistikRow(data.arbeitsvermittlung.anzahlSchweizerinnen))}
          </div>
          <div class="input-wrapper">
            <label>${this.i18n.translate('geschaeftsvorfall.statistikdaten.anzahlAuslaender')}</label>
            ${this.backendData.map(data => this.getStatistikRow(data.arbeitsvermittlung.anzahlAuslaender))}
          </div>
          <div class="input-wrapper">
            <label>${this.i18n.translate('geschaeftsvorfall.statistikdaten.anzahlAuslaenderinnen')}</label>
            ${this.backendData.map(data => this.getStatistikRow(data.arbeitsvermittlung.anzahlAuslaenderinnen))}
          </div>
          <div class="input-wrapper">
            <label>${this.i18n.translate('geschaeftsvorfall.statistikdaten.anzahlVermittletePersonen')}</label>
            ${this.backendData.map(data => this.getStatistikRow(data.arbeitsvermittlung.total))}
          </div>
          <div class="input-wrapper">
            <label style="max-width: 20em; text-align: left">${this.i18n.translate('geschaeftsvorfall.statistikdaten.av.online.placement')}</label>
            ${this.backendData.map(data => html`
              <isceco-checkbox disabled ?value="${data.arbeitsvermittlung.onlinePlacement}" style="justify-self: center""></isceco-checkbox>`)}
          </div>
        </div>
      </isceco-form>
    `
  }

  renderPVStatistik() {
    return html`
      <h2>${this.i18n.translate("geschaeftsvorfall.statistikdaten.pv")}</h2>

      <isceco-form>
        <div slot="form-elements" class="form-elements">
          <div class="input-wrapper">
            <label><b>${this.i18n.translate('geschaeftsvorfall.statistikdaten.berichtsjahr')}</b></label>
            ${this.backendData.map(data => this.getYearRow(data.year))}
          </div>
          <div class="input-wrapper">
            <label>${this.i18n.translate('geschaeftsvorfall.statistikdaten.anzahlSchweizer')}</label>
            ${this.backendData.map(data => this.getStatistikRow(data.personalverleih.anzahlSchweizer))}
          </div>
          <div class="input-wrapper">
            <label>${this.i18n.translate('geschaeftsvorfall.statistikdaten.anzahlSchweizerinnen')}</label>
            ${this.backendData.map(data => this.getStatistikRow(data.personalverleih.anzahlSchweizerinnen))}
          </div>
          <div class="input-wrapper">
            <label>${this.i18n.translate('geschaeftsvorfall.statistikdaten.anzahlAuslaender')}</label>
            ${this.backendData.map(data => this.getStatistikRow(data.personalverleih.anzahlAuslaender))}
          </div>
          <div class="input-wrapper">
            <label>${this.i18n.translate('geschaeftsvorfall.statistikdaten.anzahlAuslaenderinnen')}</label>
            ${this.backendData.map(data => this.getStatistikRow(data.personalverleih.anzahlAuslaenderinnen))}
          </div>
          <div class="input-wrapper">
            <label>${this.i18n.translate('geschaeftsvorfall.statistikdaten.anzahlVerliehenePersonen')}</label>
            ${this.backendData.map(data => this.getStatistikRow(data.personalverleih.total))}
          </div>
          <div class="input-wrapper">
            <label>${this.i18n.translate('geschaeftsvorfall.statistikdaten.geleisteteEinsatzstunden')}</label>
            ${this.backendData.map(data => this.getStatistikRow(data.personalverleih.geleisteteEinsatzstunden))}
          </div>
        </div>
      </isceco-form>
    `
  }

  _backward() {

    this.refYear -= 1
    this.loadData()
  }

  get isBackwardDisabled() {
    return (this.refYear === this.oldestYear + 4) || (this.backendData.length < 5)
  }

  _forward() {
    this.refYear += 1
    this.loadData()
  }

  get isForwardDisabled() {
    return (this.refYear === new Date().getFullYear()) || (this.backendData.length < 5);
  }

}
customElements.define('vzavg-frontend-geschaeftsvorfall-statistikdaten-vorjahre', StatistikdatenVorjahre)
