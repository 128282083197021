export default class AdresseRowVariant {
  get(row) {
    if (!row.validitaet) {
      return 'summary'
    }
    if(row.referenced) {
      return ''
    }
    return ''
  }
}
