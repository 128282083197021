import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Button/Button'

export default class KautionCurrencyCell {

  _getformatedAmount(value) {
    const formatter = new Intl.NumberFormat('de-CH', {style: 'currency', currency: 'CHF'})
    let result = formatter.format(value)
    if (result.length >= 3){
      result = result.substring(0, result.length-3)
    }
    return result
  }

  render(i18n, cell) {
    return html`
      <span>${this._getformatedAmount(cell)}</span>
    `
  }


}
