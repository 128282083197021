export default class VzavgPattern {
  static get emailPattern() {
    return '^[^@\\s]+@[^@\\s]+\.[a-zA-Z0-9]{2,}$'
  }

  static get telefonPattern() {
    return '^[+]?[0-9\\s]{10,20}$'
  }

  static get urlPattern() {
    return '(https:\\/\\/www\\.|http:\\/\\/www\\.|https:\\/\\/|http:\\/\\/)?[a-zA-Z0-9]{2,}(\\.[a-zA-Z0-9]{2,})(\\.[a-zA-Z0-9]{2,})?'
  }

  static get chfBetragIntPattern() {
    return '^\\d{1,3}(?:\'\\d{3})*$|^\\d+$'
  }

}
