import {css, html} from '@isceco/widget-library2/external/lit'
import TranslationElement from '../../../../common/TranslationElement'
import {geschaeftsVorfallNavigationLinks} from '../../Geschaeftsvorfall'

export default class GeschaeftsstellenUebersicht extends TranslationElement {

  static get properties() {
    return {
      adressen: {type: Array},
      gesuchId: {type: String}
    }
  }

  render() {
    if (!this.adressen?.length) {
      return html`
        ${this._renderTitel()}
        <span>${this.i18n.translate('geschaeftsvorfall.uebersicht.geschaeftsstellen.no.items')}</span>
      `
    }
    return html`
      ${this._renderTitel()}
      <div>
        ${this.adressen?.map(adresse => this._renderAdresse(adresse))}
      </div>
    `
  }

  static get styles() {
    return css`
      a {
        cursor: pointer;
      }
    `
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Uebersicht/GeschaeftsstellenUebersicht/i18n.json'
  }

  _renderAdresse(adresse) {
    return html`
      <adresse-element .adresse="${adresse}" gesuchId="${this.gesuchId}"></adresse-element>`
  }

  _renderTitel() {
    return html`<a href="${this.link}">
      <isceco-title size="medium"
                    text="${this.i18n.translate('geschaeftsvorfall.uebersicht.geschaeftsstellen.title')}"></isceco-title>
    </a>`
  }

  get link() {
    const baseUrl = location.origin + location.pathname + location.search
    return `${baseUrl}#${geschaeftsVorfallNavigationLinks.adresse.hash}/${this.gesuchId}`
  }
}
customElements.define('geschaeftsstellen-uebersicht', GeschaeftsstellenUebersicht)
