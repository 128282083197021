import TranslationElement from '../../../common/TranslationElement';
import {css, html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/NumberInput/NumberInput.js'

export default class StatistikInput extends TranslationElement {
  static get styles() {
    return css`
      .input-wrapper {
        display: grid;
        grid-template-columns: subgrid;
        grid-column: 1 / 5;
        align-items: center;
      }

      .input-wrapper label {
        margin-right: auto;
      }

      isceco-popup {
        min-width: 150px;
      }
    `
  }
  static get properties() {
    return {
      firstValue: {type: String},
      secondValue: {type: String},
      label: {type: String},
      readonly: {type: Boolean},
      showStatus: {type: Boolean},
      disabled: {type: Boolean}
    }
  }
  get translationFile() {
    return './views/Geschaeftsvorfall/Statistikdaten/i18n.json'
  }

  get state() {
      if(!this.secondValue || !this.firstValue) {
        return 0
      }
      // Calculate the percentage deviation
      return Math.round(((this.secondValue - this.firstValue) / this.firstValue) * 100);
  }

  /**
   * implementation of getValue for the FormElement
   * @returns {number}
   */
  getValue = () => this.secondValue

  /**
   * validates the field
   * @returns {boolean}
   */
  validate = () => true

  render() {
    return html`
      <style>@import '${iscecoWidgetLibrary.iconCss}'</style>
        <div class="input-wrapper">
            <label>${this.label}</label>
            <isceco-number-input style="text-align: center" value="${this.firstValue}" maximum-fraction-digits="${0}" readonly></isceco-number-input>
            <isceco-number-input .value="${this.secondValue?.length ? this.secondValue : ""}"
                                 maximum-fraction-digits="${0}"
                                 @change="${e => this.updateValue(e.detail.value)}"
                                 .readonly="${this.readonly}"
                                 ?disabled="${this.disabled}">
            </isceco-number-input>
            ${this.getStatus()}
        </div>

    `
  }

  getStatus() {
    if (this.firstValue != null && this.firstValue > 0 && this.showStatus) {
      return html`
        <i slot="wrapper">Abweichung: ${this.state}%</i>
      `
    } else {
      return html``
    }
  }

  updateValue(value) {
    this.secondValue = value;
    send('change', this.secondValue, this)
    this.render();
  }
}
customElements.define('statistik-input', StatistikInput)
