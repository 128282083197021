import {LitElement, html, css} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/ModalDialog/ModalDialog.js'
import '@isceco/widget-library2/basic-elements/Button/Button.js'
import I18n from '../../i18n.js'
import ExceptionService from '../../ExceptionService.js'
import {Observable} from '../../common/Observable.js'

class BetriebAlreadyExistsDialog extends LitElement {
  static DIALOG_ID = 'betrieb-exists';

  static get styles() {
    return css`
      #dialog-container {
        display: flex;
        flex-direction: column;
      }

      .confirm-buttons {
        align-self: flex-end;
        display: flex;
        gap: 1em;
        margin-top: auto;
      }

      .icon.exclamation {
        color: var(--isceco-color-yellow-500)
      }
    `
  }

  static get properties() {
    return {
      height: {type: Number},
      betriebe: {type: Array},
      betriebUrl: {type: String}
    }
  }

  constructor() {
    super();
    this.i18n = new I18n()
    this.translationLoaded = false
    this.logging = new ExceptionService()
    this.submit = Observable(false)
    this.reloadListener = _ => {
      this.render()
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.i18n.loadConfiguration('./components/Betrieb/i18n.json')
      .then(() => {
        document.addEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this.reloadListener)
        this.translationLoaded = true;
        this.requestUpdate()
      })
      .catch(error => {
        this.logging.log(error)
      })
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    document.removeEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this.reloadListener)
  }

  render() {
    return html`
        <style>@import '${iscecoWidgetLibrary.iconCss}'</style>
        <isceco-dialog hidden id="${BetriebAlreadyExistsDialog.DIALOG_ID}" size="large" @submit="${_ => this._close()}">
            <div id="dialog-container" style="${`height: ${this.height}px`}">
                ${this._renderBetriebInfos()}
                <div class="confirm-buttons">
                    <isceco-button id="already-exits-dialog-cancel"
                                   text="${this.i18n.translate('betrieb.already.exists.dialog.cancel')}"
                                   @click="${() => this._close()}"></isceco-button>
                    <isceco-button id="already-exits-dialog-confirm"
                                   text="${this.i18n.translate('betrieb.already.exists.dialog.save.uid')}"
                                   variant="primary" @click="${() => this._submit()}"></isceco-button>
                </div>
            </div>
        </isceco-dialog>
    `
  }

  open() {
    this.submit = Observable(false)
    const dialog = this.shadowRoot.getElementById(BetriebAlreadyExistsDialog.DIALOG_ID)
    dialog.removeAttribute('hidden')
    return this.submit
  }

  _submit() {
    this._close()
    this.submit.setValue(true)
  }

  _renderBetriebInfos() {
    if (this.betriebe?.length === 1) {
      return html`
        <h2>${this.i18n.translate('betrieb.already.exists.dialog.single.betrieb.title', {uid: this.betriebe[0].unternRegisterId})}</h2>
        ${this._renderBetriebInfo(this.betriebe[0])}
      `
    }
    if (this.betriebe?.length > 1) {
      return html`
        <h2>${this.i18n.translate('betrieb.already.exists.dialog.multiple.betrieb.title', {uid: this.betriebe[0].unternRegisterId})}</h2>
        <div>
          ${this.betriebe.map(b => html`${this._renderBetriebInfo(b)}`)}
        </div>
      `
    }
    return html``
  }

  _renderLink(betrieb) {
    return html`
        <a href="${this._getLink(betrieb.gesuchId, betrieb.id)}" target="_blank">
            ${this.i18n.translate('betrieb.already.exists.dialog.link')} <i class="icon external alternate"></i></a>
    `
  }
  _renderBetriebInfo(betrieb) {
    return html`
        <p><i class="exclamation triangle icon"></i>
            ${this.i18n.translate('betrieb.already.exists.dialog.single.betriebe.description', {
                firmenbezeichnung: betrieb.firmenbezeichnung,
                kanton: betrieb.codeKanton
            })}</p>
        ${this._renderLink(betrieb)}
    `
  }

  _getLink(gesuchId, betriebId) {
    const baseUrl = location.origin + location.pathname + location.search
    return `${baseUrl}#${this.betriebUrl}/${gesuchId}/${betriebId}`
  }



  _close() {
    const dialog = this.shadowRoot.getElementById(BetriebAlreadyExistsDialog.DIALOG_ID);
    dialog.setAttribute('hidden', true)
    this.render()
  }
}

customElements.define('vzavg-betrieb-already-exists-dialog', BetriebAlreadyExistsDialog);
