export default class VzavgFormation {
  /**
   * Takes a UID and formats it to a more readable format
   * @example
   * CHE297684704 -> CHE-297.684.704
   * @param {string} input
   * @returns {string} formatted UID
   */
  static formatUID(input) {
    if (!input) {
      return ''
    }
    // Use a regular expression to split the input into parts
    const parts = input.match(/^([A-Z]{3})(\d{3})(\d{3})(\d{3})$/);

    if (parts) {
      // Format the parts into the desired format
      return `${parts[1]}-${parts[2]}.${parts[3]}.${parts[4]}`;
    } else {
      return ''
    }
  }

  /**
   * returns string 'year-month-day' form date
   * @param date {Date}
   * @return {String}
   */
  static formatDateToYYYYMMDD(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  static formatDateToHuman(date) {
    if (!date) {
      return ''
    }

    return new Date(date).toLocaleDateString('de-ch')
  }

  static formatAmount(amount) {
    if (!amount){
      return '0'
    }
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
  }

  static formatTel(tel){
    if (!tel){
      return tel
    }
    if (tel.startsWith('+') && tel.length === 12 ){
      return tel.replace(' ', '').replace(/(.{3})(.{2})(.{3})(.{2})(.{2})(.*)/g, '$1 $2 $3 $4 $5$6')
    } else if (tel.length === 10){
      return tel.replace(' ', '').replace(/(.{3})(.{3})(.{2})(.{2})/g, '$1 $2 $3 $4')
    } else {
      return tel.replace(/(.{3})(.{2})/g,"$1 $2 ")
    }
  }
}

