import BaseService from '../BaseService';
import VzavgListService from './VzavgListService.js';

export default class PersonService extends BaseService {
  constructor() {
    super('person')
  }

  async getListService() {
    return this.getBackendListUrl()
      .then(url => new VzavgListService(url))
  }

}
