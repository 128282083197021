const f = (m, e) => new Promise((r) => {
  let i = e.querySelector(m);
  if (i) {
    r(i);
    return;
  }
  new MutationObserver((s, n) => {
    Array.from(e.querySelectorAll(m)).forEach((t) => {
      r(t), n.disconnect();
    });
  }).observe(e, {
    childList: !0,
    subtree: !0
  });
}), y = {
  trailing: !0
};
function b(m, e = 25, r = {}) {
  if (r = { ...y, ...r }, !Number.isFinite(e))
    throw new TypeError("Expected `wait` to be a finite number");
  let i, s, n = [], t, o;
  const a = (c, h) => (t = v(m, c, h), t.finally(() => {
    if (t = null, r.trailing && o && !s) {
      const u = a(c, o);
      return o = null, u;
    }
  }), t);
  return function(...c) {
    return t ? (r.trailing && (o = c), t) : new Promise((h) => {
      const u = !s && r.leading;
      clearTimeout(s), s = setTimeout(() => {
        s = null;
        const d = r.leading ? i : a(this, c);
        for (const g of n)
          g(d);
        n = [];
      }, e), u ? (i = a(this, c), h(i)) : n.push(h);
    });
  };
}
async function v(m, e, r) {
  return await m.apply(e, r);
}
const l = {
  viewerPath: "/pdfjs",
  viewerEntry: "/web/viewer.html",
  src: "",
  page: "",
  search: "",
  phrase: "",
  zoom: "auto",
  pagemode: "none",
  locale: "",
  textLayer: "",
  viewerCssTheme: "AUTOMATIC",
  viewerExtraStyles: ""
}, w = {
  AUTOMATIC: 0,
  // Default value.
  LIGHT: 1,
  DARK: 2
};
class p extends HTMLElement {
  constructor() {
    super(), this.onIframeReady = b(async (i) => {
      await f("iframe", this.shadowRoot), i();
    }, 0, { leading: !0 }), this.setViewerExtraStyles = (i, s = "extra") => {
      var t, o, a, c, h;
      if (!i) {
        (o = (t = this.iframe.contentDocument) == null ? void 0 : t.head.querySelector(`style[${s}]`)) == null || o.remove();
        return;
      }
      if (((c = (a = this.iframe.contentDocument) == null ? void 0 : a.head.querySelector(`style[${s}]`)) == null ? void 0 : c.innerHTML) === i)
        return;
      const n = document.createElement("style");
      n.innerHTML = i, n.setAttribute(s, ""), (h = this.iframe.contentDocument) == null || h.head.appendChild(n);
    }, this.initialize = () => new Promise(async (i) => {
      var s;
      await f("iframe", this.shadowRoot), (s = this.iframe) == null || s.addEventListener("load", async () => {
        var n, t, o;
        await ((t = (n = this.iframe.contentWindow) == null ? void 0 : n.PDFViewerApplication) == null ? void 0 : t.initializedPromise), i((o = this.iframe.contentWindow) == null ? void 0 : o.PDFViewerApplication);
      }, { once: !0 });
    });
    const e = this.attachShadow({ mode: "open" }), r = document.createElement("template");
    r.innerHTML = `
      <style>:host{width:100%;display:block;overflow:hidden}:host iframe{height:100%}</style>
      <iframe frameborder="0" width="100%" loading="lazy"></iframe>
    `, e.appendChild(r.content.cloneNode(!0));
  }
  static get observedAttributes() {
    return ["src", "viewer-path", "locale", "page", "search", "phrase", "zoom", "pagemode", "text-layer", "viewer-css-theme", "viewer-extra-styles"];
  }
  connectedCallback() {
    this.iframe = this.shadowRoot.querySelector("iframe"), document.addEventListener("webviewerloaded", () => {
      var e, r, i, s, n, t, o, a;
      this.setCssTheme(), this.setViewerExtraStyles(this.getAttribute("viewer-extra-styles")), this.getAttribute("src") !== l.src && ((r = (e = this.iframe.contentWindow) == null ? void 0 : e.PDFViewerApplicationOptions) == null || r.set("defaultUrl", "")), (s = (i = this.iframe.contentWindow) == null ? void 0 : i.PDFViewerApplicationOptions) == null || s.set("disablePreferences", !0), (t = (n = this.iframe.contentWindow) == null ? void 0 : n.PDFViewerApplicationOptions) == null || t.set("pdfBugEnabled", !0), (a = (o = this.iframe.contentWindow) == null ? void 0 : o.PDFViewerApplicationOptions) == null || a.set("eventBusDispatchToDOM", !0);
    });
  }
  attributeChangedCallback() {
    this.onIframeReady(() => this.mountViewer(this.getIframeSrc()));
  }
  getIframeSrc() {
    const e = this.getFullPath(this.getAttribute("src") || l.src), r = this.getFullPath(this.getAttribute("viewer-path") || l.viewerPath), i = this.getAttribute("page") || l.page, s = this.getAttribute("search") || l.search, n = this.getAttribute("phrase") || l.phrase, t = this.getAttribute("zoom") || l.zoom, o = this.getAttribute("pagemode") || l.pagemode, a = this.getAttribute("locale") || l.locale, c = this.getAttribute("text-layer") || l.textLayer, h = this.getAttribute("viewer-css-theme") || l.viewerCssTheme, u = !!(this.getAttribute("viewer-extra-styles") || l.viewerExtraStyles), d = `${r}${l.viewerEntry}?file=${encodeURIComponent(e)}#page=${i}&zoom=${t}&pagemode=${o}&search=${s}&phrase=${n}&textLayer=${c}${a ? "&locale=" + a : ""}&viewerCssTheme=${h}&viewerExtraStyles=${u}`;
    return d !== this.iframe.getAttribute("src") ? d : "";
  }
  mountViewer(e) {
    !e || !this.iframe || (this.shadowRoot.replaceChild(this.iframe.cloneNode(), this.iframe), this.iframe = this.shadowRoot.querySelector("iframe"), this.iframe.src = e);
  }
  getFullPath(e) {
    return e.startsWith("/") ? `${window.location.origin}${e}` : e;
  }
  getCssThemeOption() {
    const e = this.getAttribute("viewer-css-theme");
    return Object.keys(w).includes(e) ? w[e] : w[l.viewerCssTheme];
  }
  setCssTheme() {
    var r, i, s;
    if (this.getCssThemeOption() === w.DARK) {
      const n = (r = this.iframe.contentDocument) == null ? void 0 : r.styleSheets[0], t = (n == null ? void 0 : n.cssRules) || [], o = Object.keys(t).filter((a) => {
        var c;
        return ((c = t[Number(a)]) == null ? void 0 : c.conditionText) === "(prefers-color-scheme: dark)";
      }).map((a) => t[Number(a)].cssText.split(`@media (prefers-color-scheme: dark) {
`)[1].split(`
}`)[0]);
      this.setViewerExtraStyles(o.join(""), "theme");
    } else
      (s = (i = this.iframe.contentDocument) == null ? void 0 : i.head.querySelector("style[theme]")) == null || s.remove();
  }
}
window.customElements.get("pdfjs-viewer-element") || (window.PdfjsViewerElement = p, window.customElements.define("pdfjs-viewer-element", p));
export {
  p as PdfjsViewerElement,
  w as ViewerCssTheme,
  p as default
};
