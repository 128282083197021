import {css, html} from '@isceco/widget-library2/external/lit'
import {geschaeftsVorfallNavigationLinks} from '../../Geschaeftsvorfall.js'
import TranslationElement from '../../../../common/TranslationElement.js'
import VzavgFormation from '../../../../common/VzavgFormation.js'
import AuthorizationFilter from '../../../Authorization/AuthorizationFilter.js'
import UebersichtService from '../../../../services/UebersichtService.js'

export default class BewilligungenUebersicht extends TranslationElement {

  static get properties() {
    return {
      bewilligungs: {type: Object},
    }
  }

  constructor() {
    super()

    this.uebersichtService = new UebersichtService()
  }

  connectedCallback() {
    super.connectedCallback()
  }

  render() {
    const kantonAvEingang = VzavgFormation.formatDateToHuman(this.bewilligungs.kantonAvEingang)
    const kantonAvErteilung = VzavgFormation.formatDateToHuman(this.bewilligungs.kantonAvErteilung)
    const kantonAvAenderung = VzavgFormation.formatDateToHuman(this.bewilligungs.kantonAvAenderung)
    const kantonAvAufhebung = VzavgFormation.formatDateToHuman(this.bewilligungs.kantonAvAufhebung)
    const kantonAvEntzug = VzavgFormation.formatDateToHuman(this.bewilligungs.kantonAvEntzug)

    const kantonPvEingang = VzavgFormation.formatDateToHuman(this.bewilligungs.kantonPvEingang)
    const kantonPvErteilung = VzavgFormation.formatDateToHuman(this.bewilligungs.kantonPvErteilung)
    const kantonPvAenderung = VzavgFormation.formatDateToHuman(this.bewilligungs.kantonPvAenderung)
    const kantonPvAufhebung = VzavgFormation.formatDateToHuman(this.bewilligungs.kantonPvAufhebung)
    const kantonPvEntzug = VzavgFormation.formatDateToHuman(this.bewilligungs.kantonPvEntzug)

    const secoAvEingang = VzavgFormation.formatDateToHuman(this.bewilligungs.secoAvEingang)
    const secoAvErteilung = VzavgFormation.formatDateToHuman(this.bewilligungs.secoAvErteilung)
    const secoAvAenderung = VzavgFormation.formatDateToHuman(this.bewilligungs.secoAvAenderung)
    const secoAvAufhebung = VzavgFormation.formatDateToHuman(this.bewilligungs.secoAvAufhebung)
    const secoAvEntzug = VzavgFormation.formatDateToHuman(this.bewilligungs.secoAvEntzug)

    const secoPvEingang = VzavgFormation.formatDateToHuman(this.bewilligungs.secoPvEingang)
    const secoPvErteilung = VzavgFormation.formatDateToHuman(this.bewilligungs.secoPvErteilung)
    const secoPvAenderung = VzavgFormation.formatDateToHuman(this.bewilligungs.secoPvAenderung)
    const secoPvAufhebung = VzavgFormation.formatDateToHuman(this.bewilligungs.secoPvAufhebung)
    const secoPvEntzug = VzavgFormation.formatDateToHuman(this.bewilligungs.secoPvEntzug)


    return html`
      <a href="${this.link}">
        <isceco-title size="medium" text="${this.i18n.translate('title')}"></isceco-title>
      </a>

      <isceco-title size="small" text="${this.i18n.translate('kantonal')}"></isceco-title>
      <div class="grid">
        <span></span>
        <span>AV</span>
        <span>PV</span>

        <span>${this.i18n.translate('eingang')}</span>
        <span>${kantonAvEingang}</span>
        <span>${kantonPvEingang}</span>

        <span>${this.i18n.translate('erteilung')}</span>
        <span>${kantonAvErteilung}</span>
        <span>${kantonPvErteilung}</span>

        <span>${this.i18n.translate('aenderung')}</span>
        <span>${kantonAvAenderung}</span>
        <span>${kantonPvAenderung}</span>

        <span>${this.i18n.translate('aufhebung')}</span>
        <span>${kantonAvAufhebung}</span>
        <span>${kantonPvAufhebung}</span>

        <span>${this.i18n.translate('entzug')}</span>
        <span>${kantonAvEntzug}</span>
        <span>${kantonPvEntzug}</span>
      </div>

      <isceco-title size="small" text="${this.i18n.translate('eidgenoessisch')}"></isceco-title>
      <div class="grid">
        <span></span>
        <span>AV</span>
        <span>PV</span>

        <span>${this.i18n.translate('eingang')}</span>
        <span>${secoAvEingang}</span>
        <span>${secoPvEingang}</span>

        <span>${this.i18n.translate('erteilung')}</span>
        <span>${secoAvErteilung}</span>
        <span>${secoPvErteilung}</span>

        <span>${this.i18n.translate('aenderung')}</span>
        <span>${secoAvAenderung}</span>
        <span>${secoPvAenderung}</span>

        <span>${this.i18n.translate('aufhebung')}</span>
        <span>${secoAvAufhebung}</span>
        <span>${secoPvAufhebung}</span>

        <span>${this.i18n.translate('entzug')}</span>
        <span>${secoAvEntzug}</span>
        <span>${secoPvEntzug}</span>
      </div>

      <isceco-title size="small" text="${this.i18n.translate('fl')}"></isceco-title>
      <div class="grid">
        <span></span>
        <span>AV</span>
        <span>PV</span>

        <span class="table-label">${this.i18n.translate('erteilung')}</span>
        <isceco-date-input
          id="flErteilungAV"
          name="flErteilungAV"
          .value="${this.bewilligungs?.flErteilungAV}"
          .readonly="${!AuthorizationFilter.hasSecoRole()}"
          @change="${_ => this._updateFlDates()}"
        ></isceco-date-input>
        <isceco-date-input
          id="flErteilungPV"
          name="flErteilungPV"
          .value="${this.bewilligungs?.flErteilungPV}"
          .readonly="${!AuthorizationFilter.hasSecoRole()}"
          @change="${_ => this._updateFlDates()}"
        ></isceco-date-input>

        <span class="table-label">${this.i18n.translate('aufhebung')}</span>
        <isceco-date-input
          id="flAufhebungAV"
          name="flAufhebungAV"
          .value="${this.bewilligungs?.flAufhebungAV}"
          .readonly="${!AuthorizationFilter.hasSecoRole()}"
          @change="${_ => this._updateFlDates()}"
        ></isceco-date-input>
        <isceco-date-input
          id="flAufhebungPV"
          name="flAufhebungPV"
          .value="${this.bewilligungs?.flAufhebungPV}"
          .readonly="${!AuthorizationFilter.hasSecoRole()}"
          @change="${_ => this._updateFlDates()}"
        ></isceco-date-input>
      </div>
    `
  }

  static get styles() {
    return css`
      a {
        cursor: pointer;
      }

      .date {
        display: flex;
        justify-content: space-between;
      }

      .wrapper {
        display: flex;
        flex-direction: column;
        gap: 1em;
      }

      .group {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
      }

      .grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 0.2em;
      }

      .table-label {
        line-height: 2.8em;
      }
    `
  }

  _updateFlDates() {
    const bewilligungenShadowRoot = document.querySelector('bewilligungen-uebersicht').shadowRoot
    const erteilungAV = bewilligungenShadowRoot.querySelector('#flErteilungAV')
    const erteilungPV = bewilligungenShadowRoot.querySelector('#flErteilungPV')
    const aufhebungAV = bewilligungenShadowRoot.querySelector('#flAufhebungAV')
    const aufhebungPV = bewilligungenShadowRoot.querySelector('#flAufhebungPV')

    const data = {
      flErteilungAV: erteilungAV.value,
      flErteilungPV: erteilungPV.value,
      flAufhebungAV: aufhebungAV.value,
      flAufhebungPV: aufhebungPV.value,
    }

    this.gesuchId = getNavigationId()

    this.uebersichtService.updateFlDate(this.gesuchId, data)
      .then(uebersicht => {
        this.uebersicht = uebersicht
        this.render()
      })
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Uebersicht/BewilligungenUebersicht/i18n.json'
  }

  get link() {
    const baseUrl = location.origin + location.pathname + location.search
    return `${baseUrl}#${geschaeftsVorfallNavigationLinks.bewilligung.hash}/${getNavigationId()}`
  }
}
customElements.define('bewilligungen-uebersicht', BewilligungenUebersicht)
