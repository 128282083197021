import {css, html, LitElement} from '@isceco/widget-library2/external/lit';
import '@isceco/widget-library2/basic-elements/FileInput/FileInput.js'
import DokumentService from '../../services/DokumentService.js'
import VzavgFileInputService from '../../services/VzavgFileInputService.js'

export default class DocumentUpload extends LitElement {

  static get EVENT_KEYS() {
    return {
      UPLOAD_COMPLETE: 'document-upload-complete'
    }
  }

  static get properties() {
    return {
      gesuchId: { type: String },
      kategorie: { type: String },
      subKategorie: { type: String },
      objektId: { type: String },
      i18n: { type: Object },
      readonly: { type: Boolean }
    }
  }

  static get styles() {
    return css`
          .flexRowView {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
          }
          .dokumente-upload-button {
            margin-top: 0.5em;
          }
          .upload-notice {
            padding: 0.3em;
            background-color: var(--isceco-color-blue-300);
          }
        `
  }

  constructor() {
    super()
    this.dokumente = []
    this.validationConfig = {};
    this.readonly = false
  }

  connectedCallback() {
    super.connectedCallback()

    this.dokumentService = new DokumentService(this.gesuchId, `${this.kategorie}/file`)
    this.dokumentService.validationConfig().then(validationConfig => {
      this.validationConfig = validationConfig;
      this.url = this.dokumentService.backendUrl;
      return this.fetchData();
    })
  }

  fetchData() {
    return new DokumentService(this.gesuchId, `${this.kategorie}/${this.subKategorie}/${this.objektId}`).list().then(dokumente => {
      this.dokumente = dokumente;
      this.requestUpdate();
    })
  }

  render() {
    return html`
            <style>@import '${iscecoWidgetLibrary.iconCss}';</style>
            ${this._renderDokumente()}
            ${this._renderUploadButton()}
        `
  }

  _renderDokumente() {
    if (this.dokumente.length === 0) {
      return html``;
    }

    const dokumente = this.dokumente.map((dokument, index) => html`
            <div class="flexRowView" style="margin: 20px 5px 5px 5px;">
                <div class="flexRowView" style="flex-grow: 2; gap: 5px;">
                    <div>
                      <isceco-button variant="tertiary"
                                     size="small"
                                     text="${this._getShortName(dokument.fileName)}"
                                     @click="${_ => this.dokumentService.download(dokument.downloadPath, dokument.fileName)}"
                      ></isceco-button>

                    </div>
                </div>
                <div class="flexRowView" style="gap: 20px;">
                    <div>
                        ${formatDate(dokument.angelegtAm)}
                    </div>
                    <div>
                        <isceco-button id="${this._getDeleteBtnId(dokument.fileName)}"
                                       title=""
                                       variant="primary"
                                       size="small"
                                       icon="trash"
                                       @click="${_ => this._onDeleteClick(index)}"
                        ></isceco-button>
                    </div>
                </div>
            </div>
        `)

    return html`
            ${dokumente}
        `;
  }

  _renderUploadButton() {
    let errorMessage
    if (this.dokumente.length >= this._getMaxSizePerContext()) {
      errorMessage = html`
                <p class="upload-notice">
                    <i class="icon info circle"></i>
                    ${this.i18n.translate(`geschaeftsvorfall.${this.subKategorie}.detail.dokumente.maximum.erreicht`)}
                </p>
            `
    }
    return html`
            ${errorMessage}
            <div ?hidden="${!!errorMessage}">
                <isceco-file-input id="dokumente-upload-button"
                                   class="dokumente-upload-button"
                                   .fileInputService="${new VzavgFileInputService(`${this.url.split('file')[0]+this.subKategorie}/${this.objektId}/file`)}"
                                   label="${this.i18n.translate(`geschaeftsvorfall.${this.subKategorie}.detail.dokumente.hochladen`)}"
                                   accept="${this.validationConfig.allowedExtensions}"
                                   maxsize="${this.validationConfig.maxSize}"
                                   clear-event="${DocumentUpload.EVENT_KEYS.UPLOAD_COMPLETE}"
                                   @change="${e => this._onDokumentUploaded(e)}"
                ></isceco-file-input>
            </div>
        `
  }

  _getShortName(name) {
    const maxLength = 35;
    return name.length > maxLength ? name.substring(0, maxLength).concat('...') : name;
  }

  _getDeleteBtnId(name) {
    return 'btn-delete-'.concat(this._getShortName(name).split(' ').join('').replaceAll('.', ''))
  }

  _onDokumentUploaded(e) {
    if (e.detail.valid) {
      send(DocumentUpload.EVENT_KEYS.UPLOAD_COMPLETE)
      e.detail.size = this.dokumente.length +1
      send('changes',e, this)
      this.fetchData()
    }
  }

  _onDeleteClick(index) {
    const fileName = this.dokumente[index].fileName;
    this.dokumentService.setRelativeBase(this.dokumentService.relativeBase.split('/file')[0])
    this.dokumentService.delete(`${this.subKategorie}/${this.objektId}/file`, fileName).then(_ => {
      this.fetchData()
    });
  }

  _getMaxSizePerContext() {
    if(this.subKategorie === 'pendenz') {
      return this.validationConfig.maxSizePendenz
    } else if (this.subKategorie === 'notiz') {
      return this.validationConfig.maxSizeNotiz
    } else {
      return this.validationConfig.maxSize
    }
  }

}
customElements.define('vzavg-document-upload', DocumentUpload)
