import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/DateInput/DateInput.js'
import VzavgFormation from '../../common/VzavgFormation.js'

export default class GesuchAdresseDateCell {

  constructor(configuration) {
    this.adresseObject = configuration.adresseObject;
    this.hasWriteAccess = configuration.hasWriteAccess
  }

  _getDateOneYearAgo() {
    const currentDate = new Date()
    const oneYearAgo = new Date(currentDate)
    // Subtract one year from the current date
    oneYearAgo.setFullYear(currentDate.getFullYear() - 1);
    return oneYearAgo
  }

  _getDateInOneYear() {
    const currentDate = new Date()
    const oneYearAgo = new Date(currentDate)
    // Subtract one year from the current date
    oneYearAgo.setFullYear(currentDate.getFullYear() + 1);
    return oneYearAgo
  }

  render(i18n, cell, row) {
    return html`
      <vzavg-date-input-with-warning
        id="bis"
        name="bis"
        value="${row.bis}"
        .warningMinDate="${this._getDateOneYearAgo()}"
        .warningMinDescription="${i18n.translate('geschaeftsvorfall.adresse.dialog.bis.datum.check.older.description')}"
        .warningMaxDate="${this._getDateInOneYear()}"
        .warningMaxDescription="${i18n.translate('geschaeftsvorfall.adresse.dialog.bis.datum.check.younger.description')}"
        .min="${this._updateBisMinDate(row.von)}"
        @change="${e => {this.adresseObject._updateDateBis(row, e)}}"
        ?readonly="${!this.hasWriteAccess}"
      ></vzavg-date-input-with-warning>
    `
  }

  _updateBisMinDate(vonDatum) {
    const bisDatum = new Date(vonDatum)
    bisDatum.setDate(bisDatum.getDate() + 1);
    return VzavgFormation.formatDateToYYYYMMDD(bisDatum)
  }


}
