import {css, html} from '@isceco/widget-library2/external/lit'
import TranslationElement from '../../../../common/TranslationElement'
import {geschaeftsVorfallNavigationLinks} from '../../Geschaeftsvorfall'

export default class QualifikationenUebersicht extends TranslationElement {
  static get properties() {
    return {
      qualifikationen: {type: Array},
      gesuchId: {type: String}
    }
  }
  render() {
    if(!this.qualifikationen?.length) {
      return html`
        ${this._renderTitel()}
        <span>${this.i18n.translate('geschaeftsvorfall.uebersicht.qualifikationen.no.qualifikationen')}</span>
      `
    }
    return html`
      ${this._renderTitel()}
      <ul>
        ${this.qualifikationen?.map(qualifikation => this._renderQualifikation(qualifikation))}
      </ul>
    `
  }

  _renderQualifikation(qualifikation) {
    return html`<li>${qualifikation.bezeichnung}<li>`
  }


  _renderTitel() {
    return html`<a href="${this.link}">
      <isceco-title size="medium"
                    text="${this.i18n.translate('geschaeftsvorfall.uebersicht.qualifikationen.title')}"></isceco-title>
    </a>`
  }

  static get styles() {
    return css`
      a {
        cursor: pointer;
      }
      ul {
        list-style: none;
        padding-left: 0
      }
    `
  }

  get link() {
    const baseUrl = location.origin + location.pathname + location.search
    return `${baseUrl}#${geschaeftsVorfallNavigationLinks.gesuch.hash}/${this.gesuchId}`
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Uebersicht/QualifikationenUebersicht/i18n.json'
  }
}
customElements.define('qualifikationen-uebersicht', QualifikationenUebersicht)
