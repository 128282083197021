import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/HorizontalNavigation/HorizontalNavigation.js'
import WebComponent from '../../WebComponent.js'
import Navigation from './Navigation.js'
import AuthorizationFilter from '../Authorization/AuthorizationFilter.js'
import {geschaeftsVorfallNavigationLinks} from '../Geschaeftsvorfall/Geschaeftsvorfall'
import {statistikdatenNavigationLinks} from '../Statistikdaten/Statistikdaten'

export default class HorizontalNavigation extends WebComponent {

  get translationFile() {
    return './views/Navigation/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    this.navigationListener = _ => this.reload()
    document.addEventListener(Navigation.NAVIGATION_HAPPEND, this.navigationListener)
    this.render()
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    document.removeEventListener(Navigation.NAVIGATION_HAPPEND, this.navigationListener)
  }

  getTemplate() {
    const items = [
      {text: this.i18n.translate('navigation.news'), url: 'News'},
      {text: this.i18n.translate('navigation.cockpit'), url: 'Cockpit', active: true},
      {text: this.i18n.translate('navigation.suchen'), url: 'Suchen_Betrieb',
        suburls: [
          'Suchen',
          'Suchen_Betrieb',
          'Suchen_Pendenz',
          'Suchen_VerantwortlicheP',
          'Suchen_Notiz',
          'Suchen_Kaution',
          'Suchen_Gesuch',
          'Suchen_Bewilligung'
        ]
      },
      {
        text: this.i18n.translate('navigation.geschaeftsvorfall'),
        url: 'Geschaeftsvorfall',
        suburls: Object.values(geschaeftsVorfallNavigationLinks).map(value => value.hash)
      },
      {
        text: this.i18n.translate('navigation.administration'),
        url: 'Administration',
        suburls: [
          'Administration_Hilfe',
          'Administration_Branchen',
          'Administration_Dokumentation',
          'Administration_Stammdatenpflege',
          'Administration_Checklisten',
          'Administration_ChecklistenEdit',
          'Administration_Rechte'
        ]
      },
      {text: this.i18n.translate('navigation.auswertung'), url: 'Auswertung'},
      {
        text: this.i18n.translate('navigation.statistikdaten'),
        url: statistikdatenNavigationLinks.freigeben.hash,
        suburls: [
          'Statistikdaten_Freigeben',
          'Statistikdaten_Konsolidieren',
          'Statistikdaten_Erhebung_Freigeben',
          'Statistikdaten_Erhebung_Abschliessen'
        ]

      },
      {text: this.i18n.translate('navigation.logsettings'), url: 'LogSettings'}
    ]
      .filter(i => AuthorizationFilter.viewAllowed(i.url))

    return html`
      <isceco-horizontal-navigation .items="${items}">
      </isceco-horizontal-navigation>
    `
  }
}
customElements.define('vzavg-frontend-horizontal-navigation', HorizontalNavigation)
