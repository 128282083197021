import {css, html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/ActionMenu/ActionMenu.js'
import '@isceco/widget-library2/basic-elements/Header/Header.js'
import '@isceco/widget-library2/basic-elements/LanguageSwitcher/LanguageSwitcher.js'
import '@isceco/widget-library2/basic-elements/Link/Link.js'
import WebComponent from '../../WebComponent.js'
import ConfigUtil from '../../services/ConfigUtil.js'


export default class Header extends WebComponent {

  get translationFile() {
    return './views/Header/i18n.json'
  }

  constructor() {
    super()
    this.configUtil = new ConfigUtil()
  }

  connectedCallback() {
    super.connectedCallback()

    this.configUtil.retrieveConfiguration()
      .then(config => {
        this.config = config
        this.render()
      })
  }

  get css() {
    return css`
      .user-language {
        display: flex;
        align-items: center;
      }

      a {
        all: unset;
      }

      i.icon {
        margin-right: var(--isceco-space-100);
        line-height: 1.5rem;
        font-weight: bold;
        text-decoration: none;
        color: var(--isceco-color-blue-500);
        cursor: pointer;
      }

      .user-language i.icon {
        padding-bottom: 0.5em;
      }
    `
  }

  getTemplate() {
    return html`
        <isceco-header text="${this.i18n.translate('header.text')}">
            <div class="user-language">
                <a href="#"><i class="icon home"></i></a>
                <a href="http://www.arbeit.swiss/secoalv/de/home/menue/arbeitsvermittler/private-arbeitsvermittlung-und-personalverleih.html"><i
                        class="icon book"></i></a>
                <div class="user-menu">
                    <isceco-action-menu
                            id="user-action-menu"
                            .items="${[
                                {
                                    icon: 'sign out',
                                    text: this.i18n.translate('header.logout'),
                                    callback: _ => {
                                        sessionStorage.clear()
                                        window.keycloak.logout({
                                            redirectUri: this.config.logoutPage
                                        })
                                    }
                                }
                            ]}">
                        <isceco-link
                                slot="wrapper"
                                icon="user"
                                text="${keycloak.tokenParsed.email}"
                        </isceco-link>
                    </isceco-action-menu>
                </div>
                <isceco-language></isceco-language>
            </div>
        </isceco-header>
    `
  }
  displayName = () => {
    if (!isEmpty(window.keycloak)) {
      return `${window.keycloak.tokenParsed.given_name} ${window.keycloak.tokenParsed.family_name}`
    } else {
      return ''
    }
  }
}

customElements.define('vzavg-frontend-header', Header)
