import GenericColumn from '@isceco/widget-library2/basic-elements/List/column/GenericColumn.js'
import StringColumn from '@isceco/widget-library2/basic-elements/List/column/StringColumn.js'
import DateCell from '@isceco/widget-library2/basic-elements/List/cell/DateCell.js'
import CheckboxCell from '@isceco/widget-library2/basic-elements/List/cell/CheckboxCell.js'
import DokumentDownloadCell from './DokumentDownloadCell.js'
import DokumentIOCell from './DokumentIOCell.js'


export default class DokumenteColumns {

  static FILENAME_COLUMN() {
    return new StringColumn({
      key: 'fileName',
      text: 'geschaeftsvorfall.dokumente.list.filename',
      sortable: true,
      weight: 2
    })
  }

  static VERSION_COLUMN() {
    return new StringColumn({
      key: 'version',
      text: 'geschaeftsvorfall.dokumente.list.version',
      sortable: true,
      weight: 1
    })
  }

  static DOK_TYP_COLUMN() {
    return new StringColumn({
      key: 'dokIdTranslation',
      text: 'geschaeftsvorfall.dokumente.list.doktyp',
      sortable: true,
      weight: 3
    })
  }

  static VARANTW_PERSON_COLUMN() {
    return new StringColumn({
      key: 'verantwortlichePerson',
      text: 'geschaeftsvorfall.dokumente.list.verantwperson',
      sortable: true,
      weight: 3
    })
  }

  static ABLAGEORT_COLUMN(dokumentService, i18n) {
    return new GenericColumn({
      key: 'downloadPath',
      text: 'geschaeftsvorfall.dokumente.list.ablageort',
      cell: new DokumentDownloadCell({
        dokumentService: dokumentService,
        i18n: i18n
      }),
      sortable: false,
      weight: 2
    })
  }

  static IO_COLUMN(i18n) {
    return new GenericColumn({
      key: 'isOutputFile',
      text: 'geschaeftsvorfall.dokumente.list.io',
      cell: new DokumentIOCell({
        i18n: i18n
      }),
      sortable: true,
      weight: 1.5
    })
  }

  static PRUEFSTATUS_COLUMN() {
    return new GenericColumn({
      key: 'geprueft',
      text: 'geschaeftsvorfall.dokumente.list.pruefstatus',
      sortable: true,
      weight: 1.5,
      cell: new CheckboxCell({
        disabled: _ => true
      }),
    })
  }

  static NOTIZ_COLUMN() {
    return new StringColumn({
      key: 'notiz',
      text: 'geschaeftsvorfall.dokumente.list.notiz',
      sortable: true,
      weight: 2
    })
  }

  static CREATED_AT_COLUMN() {
    return new GenericColumn({
      key: 'angelegtAm',
      text: 'geschaeftsvorfall.dokumente.list.createdat',
      cell: new DateCell(),
      sortable: true,
      weight: 2
    })
  }

  static UPDATED_AT_COLUMN() {
    return new GenericColumn({
      key: 'geandertAm',
      text: 'geschaeftsvorfall.dokumente.list.updatedat',
      cell: new DateCell(),
      sortable: true,
      weight: 2
    })
  }

  static USER_COLUMN() {
    return new StringColumn({
      key: 'angelegtVon',
      text: 'geschaeftsvorfall.dokumente.list.user',
      sortable: true,
      weight: 2.5
    })
  }

}
