import StringColumn from '@isceco/widget-library2/basic-elements/List/column/StringColumn.js'
import DateColumn from '@isceco/widget-library2/basic-elements/List/column/DateColumn.js'
import GenericColumn from '@isceco/widget-library2/basic-elements/List/column/GenericColumn.js'
import StatistikMaxDeviationInPercentCell from './StatistikMaxDeviationInPercentCell.js'
import DefaultActionCell from '../../../common/list/DefaultActionCell.js'
import '@isceco/widget-library2/basic-elements/Checkbox/Checkbox.js'
import {html} from '@isceco/widget-library2/external/lit'
import StatistikCheckboxColumn from './StatistikCheckboxColumn.js'


export default class StatistikOverviewColumns {

  static SELECT_COLUMN({rowCallback, headerCallback}) {
    return new StatistikCheckboxColumn({
      key: 'checked',
      renderHeader: (i18n, key) => html`
        <isceco-checkbox value=${key} @change="${e => headerCallback(e)}" ?value="${false}"></isceco-checkbox>
      `,
      callback: (value, row) => {
        rowCallback(value, row)
      },
      disabled: row => isEmpty(row.eingangsdatum)
    })
  }

  static KANTON_COLUMN() {
    return new StringColumn({
      key: 'codeKanton',
      text: 'statistikdaten.freigeben.column.kanton',
      weight: 2,
      sortable: true
    })
  }

  static BETRIEB_COLUMN() {
    return new StringColumn({
      key: 'firmenbezeichnung',
      text: 'statistikdaten.freigeben.column.betrieb',
      weight: 2,
      sortable: true
    })
  }

  static STRASSE_COLUMN() {
    return new StringColumn({
      key: 'strasse',
      text: 'statistikdaten.freigeben.column.strasse',
      weight: 2,
      sortable: true
    })
  }

  static PLZ_COLUMN() {
    return new StringColumn({
      key: 'plz',
      text: 'statistikdaten.freigeben.column.plz',
      weight: 1,
      sortable: true
    })
  }

  static ORT_COLUMN() {
    return new StringColumn({
      key: 'ort',
      text: 'statistikdaten.freigeben.column.ort',
      weight: 2,
      sortable: true
    })
  }

  static EINGANGSDATUM_COLUMN() {
    return new DateColumn({
      key: 'eingangsdatum',
      text: 'statistikdaten.freigeben.column.eingangsdatum',
      weight: 2,
      sortable: true
    })
  }

  static MAX_DEVIATION_IN_PERCENT_COLUMN() {
    return new GenericColumn({
      key: 'maxDeviationInPercent',
      text: 'statistikdaten.freigeben.column.maxDeviationInPercent',
      weight: 1,
      sortable: true,
      align: 'right',
      cell: new StatistikMaxDeviationInPercentCell()
    })
  }

  static AKTIONEN_COLUMN(configuration) {
    const {callback} = configuration
    return new GenericColumn({
      key: 'aktionen',
      text: '',
      weight: 0.5,
      align: 'right',
      cell: new DefaultActionCell({
        callback: callback,
        buttons: i18n => {
          const buttons = []

          buttons.push({
            action: 'edit',
            icon: 'clipboard',
            variant: 'primary',
            title: i18n.translate('statistikdaten.freigeben.column.aktion.edit')
          })
          return buttons
        }
      })
    })
  }
}
