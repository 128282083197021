import GenericColumn from '@isceco/widget-library2/basic-elements/List/column/GenericColumn'
import StringCell from '@isceco/widget-library2/basic-elements/List/cell/StringCell'
import DefaultActionCell from '../../../../common/list/DefaultActionCell.js'
import UidCell from '../../../../components/Betrieb/UidCell.js'
import {geschaeftsVorfallNavigationLinks} from '../../../Geschaeftsvorfall/Geschaeftsvorfall.js'

export default class BetriebSearchColumns {

  static getColumns() {
    return [
      {
        column: new GenericColumn({
          key: 'unternRegisterId',
          text: 'suchen.result.betrieb.column.uid',
          sortable: true,
          cell: new UidCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },
      {
        column: new GenericColumn({
          key: 'firmenbezeichnung',
          text: 'suchen.result.column.firmenbezeichnung',
          sortable: true,
          cell: new StringCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },
      {
        column: new GenericColumn({
          key: 'strasse',
          text: 'suchen.result.column.strasse',
          sortable: true,
          cell: new StringCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },
      {
        column: new GenericColumn({
          key: 'plz',
          text: 'suchen.result.betrieb.column.plz',
          sortable: true,
          cell: new StringCell(),
          weight: 0.5
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },
      {
        column: new GenericColumn({
          key: 'ort',
          text: 'suchen.result.column.ort',
          sortable: true,
          cell: new StringCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },
      {
        column: new GenericColumn({
          key: 'codeKanton',
          text: 'suchen.result.column.kanton',
          sortable: true,
          cell: new StringCell(),
          weight: 0.5
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },
      {
        column: new GenericColumn({
          key: 'telefon',
          text: 'suchen.result.betrieb.column.telefon',
          sortable: true,
          cell: new StringCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: false
      },
      {
        column: new GenericColumn({
          key: 'email',
          text: 'suchen.result.betrieb.column.email',
          sortable: true,
          cell: new StringCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: false
      },
      {
        column: new GenericColumn({
          key: 'aktionen',
          text: '',
          weight: 1,
          align: 'right',
          cell: new DefaultActionCell({
            callback: (row, action) => {
              if (action === 'edit') {
                navigate({to: geschaeftsVorfallNavigationLinks.uebersicht.hash, id: `${row.gesuchId}/${row.id}`})
              }
              if(action === 'add_aenderungsgesuch') {
                const params = new URLSearchParams()
                params.set('betriebId', row.id)
                navigate({to: geschaeftsVorfallNavigationLinks.gesuch.hash, id: '0', params})
              }
            },
            buttons: (i18n, row) => {
              const buttons = []

              if(row.canCreateAenderungsgesuch) {
                buttons.push(
                  {
                    action: 'add_aenderungsgesuch',
                    variant: 'secondary',
                    title: i18n.translate('suchen.result.betrieb.add.aenderung'),
                    text: i18n.translate('suchen.result.betrieb.add.aenderung')
                  }
                )
              }

              buttons.push(
                {
                  action: 'edit',
                  icon: 'clipboard',
                  variant: 'primary',
                  title: i18n.translate('suchen.result.betrieb.column.edit')
                }
              )

              return buttons
            }
          })
        }),
        alwaysDisplayed: true,
        defaultDisplayed: true
      }
    ]
  }
}

