export default class DataHelper {

  /**
   * Compares data object with another to find changed fields.
   *
   * The method expects that both data objects contains the same number of properties. If there is a requirement
   * to recognize removed or deleted properties, the implementation must be extended.
   *
   * @param d1 A data object
   * @param d2 A data object to compare
   * @returns {(boolean|Set<any>)[]|boolean} A boolean if some fields are changed and a Set with the field names
   */
  static compareData(d1, d2) {
    const objKeysB1 = Object.keys(d1)
    const changedFields = new Set()

    let hasChangedFields = false

    for (const key of objKeysB1) {
      if (key !== 'id') {
        const val1 = ['boolean', 'number'].includes(typeof (d1[key])) ? d1[key].toString() : d1[key]
        const val2 = ['boolean', 'number'].includes(typeof (d2[key])) ? d2[key].toString() : d2[key]

        if (val1 !== val2) {
          hasChangedFields = true
          changedFields.add(key)
        }
      }
    }

    return [hasChangedFields, changedFields]
  }
}
