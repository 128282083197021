import {css, html, LitElement} from '@isceco/widget-library2/external/lit'
import {geschaeftsVorfallNavigationLinks} from '../../views/Geschaeftsvorfall/Geschaeftsvorfall'
import VzavgFormation from '../../common/VzavgFormation'

export default class AdresseElement extends LitElement {

  static get properties() {
    return {
      adresse: {type: Object},
      withTitle: {type: Boolean},
      gesuchId: {type: String}
    }
  }

  render() {
    if (!this.adresse) {
      return html``
    }
    return html`
      ${this.withTitle ? this._renderTitle() : html``}
      <div class="adress-container">
        ${this.withTitle ? html`<span>${this.adresse.firmenbezeichnung}</span>` : html`<a
          href="${this._getAdressLink(this.adresse.id)}">${this.adresse.firmenbezeichnung}</a>`}
        <span>${this.adresse.strasse} ${this.adresse.strasseNr}</span>
        <span>${this.adresse.plz} ${this.adresse.ort}</span>
        <a href="${'tel:' + this.adresse.telefon}">${VzavgFormation.formatTel(this.adresse.telefon)}</a>
        <a href="${`mailto:${this.adresse.email}`}">${this.adresse.email}</a>
        <a href="${this.adresse.url}">${this.adresse.url}</a>
      </div>

    `
  }

  static get styles() {
    return css`
      a {
        cursor: pointer;
      }

      .adress-container {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
      }
    `
  }

  _renderTitle() {
    return html`
      <a href="${this._getAdressLink(this.adresse.id)}">
        <isceco-title size="medium" text="${this.adresse.adresseTyp}"></isceco-title>
      </a>
    `
  }

  _getAdressLink(adresseId) {
    const baseUrl = location.origin + location.pathname + location.search
    return `${baseUrl}#${geschaeftsVorfallNavigationLinks.adresseEdit.hash}/${this.gesuchId}/${adresseId}`
  }
}
customElements.define('adresse-element', AdresseElement)
