import {html} from '@isceco/widget-library2/external/lit';
import WebComponent from '../../../WebComponent.js';

export default class History extends WebComponent {

  get css() {
    return `
        `
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/History/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    this.render()
  }

  getTemplate() {
    return html`
      <h1>${this.i18n.translate('geschaeftsvorfall.history.header')}</h1>
    `
  }

}
customElements.define('vzavg-frontend-geschaeftsvorfall-history', History)
