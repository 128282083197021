import BaseService from '../BaseService.js'


export default class CockpitStatistikdatenService extends BaseService {

  constructor() {
    super('cockpitStatistikdaten')
  }

  async readHeader() {
    await this.retrieveBackendUrl()
    return this.checkError(
      fetch(`${this.backendUrl}/header`, {
        method: 'GET',
        cache: 'reload',
        headers: this.getJsonHeader()
      })
    )
  }
}
