import StringColumn from '@isceco/widget-library2/basic-elements/List/column/StringColumn.js'
import GenericColumn from '@isceco/widget-library2/basic-elements/List/column/GenericColumn.js'
import DefaultActionCell from '../../../common/list/DefaultActionCell.js'
import GesuchAdresseDateCell from '../../../components/GesuchAdresse/GesuchAdresseDateCell.js'
import StringCell from '@isceco/widget-library2/basic-elements/List/cell/StringCell.js'
import DefaultStatusCell from '../../../common/list/DefaultStatusCell.js';
import UidCell from '../../../components/Betrieb/UidCell'

export default class AdresseColumns {

  static STATUS_COLUMN() {
    return new GenericColumn({
      key: 'status',
      text: 'geschaeftsvorfall.adresse.list.status',
      sortable: false,
      cell: new DefaultStatusCell(),
      align: 'center',
      weight: 0.6
    })
  }

  static TYP_COLUMN(i18n) {
    return new StringColumn({
      key: 'adresseTyp',
      text: 'geschaeftsvorfall.adresse.list.typ',
      sortable: false,
      cell: new StringCell({i18n}),
      weight: 0.6
    })
  }

  static UID_COLUMN() {
    return new GenericColumn({
      key: 'unternRegisterId',
      text: 'geschaeftsvorfall.adresse.list.uid',
      cell: new UidCell(),
      weight: 0.6
    })
  }

  static FIRMENBEZ_COLUMN() {
    return new StringColumn({
      key: 'firmenbezeichnung',
      text: 'geschaeftsvorfall.adresse.list.firmenbez',
      sortable: false,
      weight: 1
    })
  }

  static STREET_COLUMN() {
    return new StringColumn({
      key: 'strasse',
      text: 'geschaeftsvorfall.adresse.strasse',
      sortable: false,
      weight: 1
    })
  }

  static PLZ_COLUMN() {
    return new StringColumn({
      key: 'plz',
      text: 'geschaeftsvorfall.adresse.list.plz',
      sortable: false,
      weight: 0.5
    })
  }

  static ORT_COLUMN() {
    return new StringColumn({
      key: 'ort',
      text: 'geschaeftsvorfall.adresse.list.ort',
      sortable: false,
      weight: 0.5
    })
  }

  static DATUM_BIS_COLUMN(configuration) {
    const {hasWriteAccess, adresseObject} = configuration
    return new GenericColumn({
      key: 'bis',
      text: 'geschaeftsvorfall.adresse.bis',
      sortable: false,
      weight: 1,
      cell: new GesuchAdresseDateCell({
        hasWriteAccess,
        adresseObject
      })
    })
  }

  static VALIDITAET_COLUMN() {
    return new StringColumn({
      key: 'validitaetText',
      text: 'geschaeftsvorfall.adresse.list.validitaet',
      sortable: false,
      weight: 0.5
    })
  }

  static AKTIONEN_COLUMN(configuration) {
    const {callback} = configuration
    return new GenericColumn({
      key: 'aktionen',
      text: '',
      weight: 0.5,
      align: 'right',
      cell: new DefaultActionCell({
        callback: callback,
        buttons: i18n => {
          const buttons = []

          buttons.push(
            {
              action: 'edit',
              icon: 'clipboard',
              variant: 'primary',
              title: i18n.translate('geschaeftsvorfall.adresse.button.edit')
            },
          )

          return buttons
        }
      })
    })
  }
}
