import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Button/Button.js'


export default class DokumentDownloadCell {

  constructor(configuration) {
    this.dokumentService = configuration.dokumentService
    this.i18n = configuration.i18n
  }

  render(i18n, cell, row) {
    return html`
      <isceco-button variant="tertiary"
                     size="small"
                     text="${ this.i18n.translate('geschaeftsvorfall.dokumente.list.ablageort.text')}"
                     @click="${_ => this.dokumentService.download(row.downloadPath, row.fileName)}">
      </isceco-button>
      `
  }
}
