import BaseService from '../BaseService.js'

export default class ExternalBetriebSearchService extends BaseService {

  static UID = 'uid'
  static BURWEB_OFFSET = 'burweb-offset'

  constructor() {
    super('betrieb/search')
  }

  async search(commandSuffix = '') {
    await this.retrieveBackendUrl()
    return this.checkError(
      fetch(this.backendUrl + commandSuffix, {
        method: 'POST',
        cache: 'reload',
        headers: this.getJsonHeader()
      })
    )
  }

  async searchByUid(uid) {
    await this.retrieveBackendUrl()
    return this.checkError(
      fetch(`${this.backendUrl}/uidregister/${uid}`, {
        method: 'GET',
        cache: 'reload',
        headers: this.getJsonHeader()
      })
    )
  }

  async deleteSilently(searchId) {
    await this.retrieveBackendUrl()
    await this.configUtil.updateToken()
    return fetch(`${this.backendUrl}/${searchId}`, {
      method: 'DELETE',
      cache: 'reload',
      headers: this.getJsonHeader()
    })
  }

  async searchLocalByUid(uid) {
    await this.retrieveBackendUrl()
    return this.checkError(
      fetch(`${this.backendUrl}/local/${uid}`, {
        method: 'GET',
        cache: 'reload',
        headers: this.getJsonHeader()
      })
    )
  }

}
