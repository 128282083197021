import DateColumn from '@isceco/widget-library2/basic-elements/List/column/DateColumn.js'
import GenericColumn from '@isceco/widget-library2/basic-elements/List/column/GenericColumn.js'
import StringColumn from '@isceco/widget-library2/basic-elements/List/column/StringColumn.js'
import DefaultActionCell from '../../../common/list/DefaultActionCell.js'
import DefaultStatusCell from '../../../common/list/DefaultStatusCell.js'
import KautionCurrencyCell from './KautionCurrencyCell.js'

export default class KautionColumns {
  static STATUS_COLUMN() {
    return new GenericColumn({
      key: 'status',
      text: 'geschaeftsvorfall.kaution.status',
      sortable: false,
      cell: new DefaultStatusCell(),
      align: 'center',
      weight: 0.6
    })
  }

  static KAUTIONSDATUM_BIS_COLUMN() {
    return new DateColumn({
      key: 'kautionsDatum',
      text: 'geschaeftsvorfall.kaution.kautionsdatum',
      sortable: false,
      weight: 1
    })
  }

  static KAUTIONSART_COLUMN() {
    return new StringColumn({
      key: 'kautionsArt',
      text: 'geschaeftsvorfall.kaution.kautionsart',
      sortable: false,
      weight: 1
    })
  }

  static KAUTIONSGEBER_COLUMN() {
    return new StringColumn({
      key: 'name',
      text: 'geschaeftsvorfall.kaution.kautionsgeber',
      sortable: false,
      weight: 1
    })
  }

  static KAUTIONSBETRAG_COLUMN() {
    return new GenericColumn({
      key: 'kautionsBetrag',
      text: 'geschaeftsvorfall.kaution.kautionsbetrag',
      sortable: false,
      weight: 1,
      cell: new KautionCurrencyCell()
    })
  }

  static BEFRIST_BIS_COLUMN() {
    return new DateColumn({
      key: 'befristBis',
      text: 'geschaeftsvorfall.kaution.befrist.bis',
      sortable: false,
      weight: 1
    })
  }

  static BLOCKIERT_BIS_COLUMN() {
    return new DateColumn({
      key: 'blockiertBis',
      text: 'geschaeftsvorfall.kaution.blockiert.bis',
      sortable: false,
      weight: 1
    })
  }

  static SPERRFRIST_COLUMN() {
    return new DateColumn({
      key: 'sperrfrist',
      text: 'geschaeftsvorfall.kaution.sperrfrist',
      sortable: false,
      weight: 1
    })
  }

  static KAUTIONSSTATUS_COLUMN() {
    return new StringColumn({
      key: 'kautionsStatus',
      text: 'geschaeftsvorfall.kaution.kautionsstatus',
      sortable: false,
      weight: 1
    })
  }

  static AKTIONEN_COLUMN(configuration) {
    const {callback} = configuration
    return new GenericColumn({
      key: 'aktionen',
      text: '',
      weight: 0.5,
      align: 'right',
      cell: new DefaultActionCell({
        callback: callback,
        buttons: i18n => {
          const buttons = []

          buttons.push(
            {
              action: 'edit',
              icon: 'clipboard',
              variant: 'primary',
              title: i18n.translate('geschaeftsvorfall.adresse.button.edit')
            },
          )

          return buttons
        }
      })
    })
  }
}
