import {css, html, LitElement} from '@isceco/widget-library2/external/lit'
import './pdfjs-viewer-element'

export default class DocumentViewer extends LitElement {

  static get EVENT_KEYS() {
    return {
      UPDATE_DOCUMENT: 'update-document'
    }
  }

  static get properties() {
    return {
      fileBlob: { type: Object },
      callbackSave: { type: Function },
    }
  }

  static get styles() {
    return css`
      #viewer {
        height: 100%;
        min-height: 800px;
      }
    `
  }

  connectedCallback() {
    super.connectedCallback()
    this.viewerApp = null

    this._updateDocument = () => {
      this.viewerApp.eventBus.dispatch('download', {source: this})
    }

    document.addEventListener(DocumentViewer.EVENT_KEYS.UPDATE_DOCUMENT, this._updateDocument)
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    document.removeEventListener(DocumentViewer.EVENT_KEYS.UPDATE_DOCUMENT, this._updateDocument)
  }

  updated(changedProperties) {
    const viewer = this.shadowRoot.querySelector('pdfjs-viewer-element')
    if(this.viewerApp) {
      if(this.fileBlob) {
        this.fileBlob.arrayBuffer().then(buffer => {
          this.viewerApp.open({data: new Uint8Array(buffer)})
        })
      }
    } else {
      // Wait for the viewer initialization, receive PDFViewerApplication
      viewer.initialize().then(viewerApp => {
        // Open PDF file data using Uint8Array instead of URL
        this.viewerApp = viewerApp
        if(this.fileBlob) {
          this.fileBlob.arrayBuffer().then(buffer => {
            this.viewerApp.open({data: new Uint8Array(buffer)})
          })
        }
        // Use event bus to handle viewer application events
        this.viewerApp.eventBus.on('download', _ => {
          // retrieve pdf and store it in backend
          this.viewerApp.pdfDocument.saveDocument().then(pdfData => {
            // the modified saved file can be uploaded to our backend with : pdfData
            this.callbackSave(pdfData)
          })
        })
      })
    }
  }

  render() {
    return html`
        <pdfjs-viewer-element id="viewer" viewer-path="/components/DocumentViewer/pdfjs-4.0.189-dist"></pdfjs-viewer-element>
    `
  }

}

customElements.define('vzavg-document-viewer', DocumentViewer)
