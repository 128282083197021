import {html} from '@isceco/widget-library2/external/lit'


export default class DefaultStatusCell {
  render(i18n, cell, row) {
    if (cell === 'green'){
      return html`
        <p style="background: linear-gradient(to right,#98f99859, #F6F6F6); height: 2em;padding: 0; margin: 0">
          <i class="check icon" style="padding-top:0.5em"></i>
        </p>
      `
    }
    if (cell === 'orange'){
      return html`
          <p style="background: linear-gradient(to right,#f5e4bdd6, #F6F6F6); height: 2em;padding: 0; margin: 0">
              <i class="exclamation triangle icon" style="padding-top:0.5em"></i>
          </p>
      `
    }
    if (cell === 'red') {
      return html`
        <p style="background: linear-gradient(to right,#e779794d, #F6F6F6); height: 2em;padding: 0; margin: 0">
          <i class="close icon" style="padding-top:0.5em"></i>
        </p>
      `
    }
    return html``
  }
}
