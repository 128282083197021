export default class Options {
  static get yesNo() {
    return [
      { value: 'yes', name:  translateText(Options._translations(), 'yes')},
      { value: 'no', name:  translateText(Options._translations(), 'no')}
    ]
  }

  static get gender() {
    return [
      {value: 'male', name: translateText(Options._translations(), 'male')},
      {value: 'female', name: translateText(Options._translations(), 'female')},
    ]
  }

  static _translations() {
    return {
      de: {
        yes: 'Ja',
        no: 'Nein',
        male: 'Männlich',
        female: 'Weiblich'
      },
      fr: {
        yes: 'Oui',
        no: 'Non',
        male: 'Männlich FR',
        female: 'Weiblich FR'
      },
      it: {
        yes: 'Si',
        no: 'No',
        male: 'Männlich IT',
        female: 'Weiblich IT'
      },
      en: {
        yes: 'Ja',
        no: 'No',
        male: 'Männlich EN',
        female: 'Weiblich EN'
      }
    }
  }

}
