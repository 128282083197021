import {css} from '@isceco/widget-library2/external/lit'

export default css`
  .flex {
    display: flex;
    flex-wrap: wrap;
    gap: 2em;
    min-width: 10em;
  }

  .flex > * {
    flex: 1;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  .buttons {
    margin-top: 3em;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0.5em;
  }

  .buttons-column {
    display: flex;
    gap: 0.5em;
    flex-direction: column;
    align-items: start;
  }

  .title-content {
    display: flex;
    gap: 1em;
    margin-bottom: 0.5em;
    align-items: center;
  }

  .title-content .status {
    font-size: 1.5em;
    margin-top: 0.5em;
  }

  small {
    padding-top: 0.5em;
  }

  .uid_label-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .icon.exclamation {
    color: var(--isceco-color-yellow-500)
  }
`
