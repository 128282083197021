import {css, html, LitElement} from '@isceco/widget-library2/external/lit'

export class Title extends LitElement {

  static styles = css`
    :host {
      font-family: var(--isceco-font);
      color: var(--isceco-color-black-500);
    }

    slot {
      background-color: var(--isceco-color-white-500);
    }

    .wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: solid 1px var(--isceco-color-grey-700);
      margin: 20px 0;
      flex-wrap: wrap;
    }

    @media (max-width: 991.98px) {
      .wrapper {
        display: flex;
        flex-direction: column;
        border-bottom: solid 1px var(--isceco-color-grey-700);
        align-items: flex-start;
        margin: 20px 0;
        flex-wrap: wrap;
      }
    }

    .wrapper > * {
      flex:1;
    }

    .title-wrapper {
      display: flex;
      align-items: center;
    }

    .title-wrapper > isceco-popup {
      margin-left: 0.2rem;
    }

    .center {
      display: flex;
      justify-content: center
    }
    @media (max-width: 991.98px) {
      .center {
        display: flex;
        justify-content: left
      }
    }

    h4, h3, h2 {
      margin: 10px 0;
    }

    @media (max-width: 991.98px) {
      h4, h3, h2 {
        max-width: 70%;
      }
    }
  `

  static get properties() {
    return {
      text: {type: String},
      helpText: {attribute: 'help-text', type: String | Function},
      helpTextDirection: {attribute: 'help-text-direction', type: String},
      size: {type: String}
    }
  }

  constructor() {
    super()
    this.helpTextDirection = 'right'
  }

  render() {
    let title
    if (this.size === 'small') {
      title = html`<h4>${this.text}</h4>`
    } else if (this.size === 'medium') {
      title = html`<h3>${this.text}</h3>`
    } else {
      title = html`<h2>${this.text}</h2>`
    }
    return html`
      <style>@import '${iscecoWidgetLibrary.iconCss}'</style>
      <div class="wrapper">
        <div class="title-wrapper">
          ${title}
          ${this.renderInfo()}
        </div>
        <div class="center">
          <slot name="center"></slot>
        </div>
        <div style="display: flex; justify-content: flex-end">
          <slot name="right"></slot>
        </div>
      </div>
    `
  }

  renderInfo() {
    if (isEmpty(this.helpText)) {
      return html``
    } else {
      const helpText = this.helpText instanceof Function ? this.helpText() : this.helpText
      return html`
        <isceco-popup direction="${this.helpTextDirection}">
          <i slot="wrapper" class="info circle icon"></i>
          <div slot="content">${helpText}</div>
        </isceco-popup>
      `
    }
  }
}

customElements.define('vzavg-title', Title)
