import {css, html} from '@isceco/widget-library2/external/lit'
import {geschaeftsVorfallNavigationLinks} from '../../Geschaeftsvorfall'
import TranslationElement from '../../../../common/TranslationElement'

export default class ZusammenarbeitsverbotUebersicht extends TranslationElement {

  static get properties() {
    return {
      zusammenarbeitsverbot: {type: Boolean},
      gesuchId: {type: String}
    }
  }

  render() {
    const value = this.zusammenarbeitsverbot ? this.i18n.translate('yes') : this.i18n.translate('no')
    return html`
      <a href="${this.link}">
        <isceco-title size="medium" text="${this.i18n.translate('title')}"></isceco-title>
      </a>
      <p>${value}</p>
    `
  }

  static get styles() {
    return css`
      a {
        cursor: pointer;
      }
    `
  }

  get link() {
    const baseUrl = location.origin + location.pathname + location.search
    return `${baseUrl}#${geschaeftsVorfallNavigationLinks.notiz.hash}/${this.gesuchId}`
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Uebersicht/ZusammenarbeitsverbotUebersicht/i18n.json'
  }
}
customElements.define('zusammenarbeitsverbot-uebersicht', ZusammenarbeitsverbotUebersicht)
