import {html, LitElement} from '@isceco/widget-library2/external/lit'
import ErrorMsgCss from './ErrorMsgCss.js'


export class ErrorMsg extends LitElement {
  static get styles() {
    return [ErrorMsgCss]
  }

  static get properties() {
    return {
      msg: {type: String}
    }
  }

  constructor() {
    super()

    this.msg = ''
  }

  render() {
    if (isEmpty(this.msg)) {
      return html``
    } else {
      return html`
        <style>@import '${iscecoWidgetLibrary.iconCss}';</style>
        <p>
          <i class="exclamation triangle icon"></i>
          ${this.msg}
        </p>
      `
    }
  }
}
customElements.define('vzavg-error-msg', ErrorMsg)
