import WebComponent from '../../../../WebComponent'
import StammdatenService from '../../../../services/StammdatenService'
import {html} from '@isceco/widget-library2/external/lit'
import I18n from '../../../../i18n'

export default class KautionFilter extends WebComponent {

  constructor() {
    super()
    const stammdatenService = new StammdatenService();
    Promise.all([stammdatenService.getOrganisation(), stammdatenService.getKautionsart()])
      .then(([kantone, kautionsarten]) => {
      this.kantone = kantone.map(kanton => ({value: kanton.code, name: kanton.bezeichnung}))
      this.kautionsarten = kautionsarten.map(kaution => ({value: kaution.code, name: kaution.bezeichnung}))
    })
      .then(_ => this.render())
  }

  get translationFile() {
    return './views/Suchen/Form/Filter/i18n.json'
  }

  get values() {
    return this._values
  }

  get betriebStatus() {
    return [
      {name: this.i18n.translate('suchen.aktiv'), value: true},
      {name: this.i18n.translate('suchen.inaktiv'), value: false},
    ]
  }

  set values(val) {
    this._values = val
  }

  connectedCallback() {
    super.connectedCallback()
    this._refresh = () => {
      this.render()
    }


    document.addEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)
    this.render()
  }

  disconnectedCallback() {
    document.removeEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)
  }


  getTemplate() {
    return html`
      <div class="filter-wrapper">
        <isceco-dropdown label="${this.i18n.translate('suchen.kanton')}" id="kanton" name="kanton"
                         .items="${this.kantone}"
                         .value="${this.values?.kanton ?? sessionStorage.getItem('userRechtCodeKanton')}"
        ></isceco-dropdown>
        <isceco-dropdown label="${this.i18n.translate('suchen.betriebStatus')}" id="betriebIsActive" name="betriebIsActive"
                         .items="${this.betriebStatus}"
                         .value="${this.values?.betriebIsActive}"
        ></isceco-dropdown>
        <isceco-dropdown label="${this.i18n.translate('suchen.kaution.kautionsArt')}" id="kautionsArt" name="kautionsArt"
                         .items="${this.kautionsarten}"
                         .value="${this.values?.kautionsArt}"
        ></isceco-dropdown>
        <isceco-text-input type="number" label="${this.i18n.translate('suchen.kaution.kautionsBetragVon')}"
                           id="kautionsBetragVon" name="kautionsBetragVon"
                           .value="${this.values?.kautionsBetragVon}"
        ></isceco-text-input>
        <isceco-text-input type="number" label="${this.i18n.translate('suchen.kaution.kautionsBetragBis')}"
                           id="kautionsBetragBis" name="kautionsBetragBis"
                           .value="${this.values?.kautionsBetragBis}"
        ></isceco-text-input>
        <isceco-date-input label="${this.i18n.translate('suchen.kaution.kautionsDatumVon')}" id="kautionsDatumVon" name="kautionsDatumVon"
                           .value="${this.values?.kautionsDatumVon}"
                           max="${this.values?.kautionsDatumBis}"
                           @change="${event => this._updateBisDate(event.detail.value, 'kautionsDatumBis', 'kautionsDatumVon')}"
        >
        </isceco-date-input>
        <isceco-date-input label="${this.i18n.translate('suchen.kaution.kautionsDatumBis')}" id="kautionsDatumBis" name="kautionsDatumBis"
                           .value="${this.values?.kautionsDatumBis}"
                           min="${this.values?.kautionsDatumVon}"
                           @change="${event => this._updateBisDate(event.detail.value, 'kautionsDatumBis')}"
        >
        </isceco-date-input>
        <isceco-date-input label="${this.i18n.translate('suchen.kaution.befristetBis')}" id="befristetBis" name="befristetBis"
                           .value="${this.values?.befristetBis}"
        >
        </isceco-date-input>
        <isceco-date-input label="${this.i18n.translate('suchen.kaution.blockiertBis')}" id="blockiertBis" name="blockiertBis"
                           .value="${this.values?.blockiertBis}"
        >
        </isceco-date-input>
      </div>
    `
  }

  _updateBisDate(newDate, key1, key2 = undefined) {
    this.values[key1] = newDate
    if(key2) {
      this.values[key2] = newDate
    }
    this.render()
  }
}
customElements.define('vzavg-frontend-suche-kaution-filter', KautionFilter)
