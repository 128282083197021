import GenericColumn from '@isceco/widget-library2/basic-elements/List/column/GenericColumn'
import DateColumn from '@isceco/widget-library2/basic-elements/List/column/DateColumn'
import StringColumn from '@isceco/widget-library2/basic-elements/List/column/StringColumn'
import DefaultActionCell from '../../../common/list/DefaultActionCell'

export default class BewilligungColumns {

  static BEREICH_COLUMN() {
    return new StringColumn({
      key: 'bereichUndKanton',
      text: 'geschaeftsvorfall.bewilligung.bereich',
      weight: 2,
      sortable: true
    })
  }

  static TYP_COLUMN() {
    return new StringColumn({
      key: 'typ',
      text: 'geschaeftsvorfall.bewilligung.typ',
      weight: 2,
      sortable: true
    })
  }

  static AUSSTELLUNGSGRUND() {
    return new StringColumn({
      key: 'ausstellungsgrund',
      text: 'geschaeftsvorfall.bewilligung.ausstellungsgrund',
      weight: 2
    })
  }

  static ERSTAUSSTELLUNGSDATUM_COLUMN() {
    return new DateColumn({
      key: 'erstausstellungsdatum',
      text: 'geschaeftsvorfall.bewilligung.erstausstellungsdatum',
      weight: 2
    })
  }

  static AENDERUNGSDATUM_COLUMN() {
    return new DateColumn({
      key: 'aenderungsdatum',
      text: 'geschaeftsvorfall.bewilligung.aenderungsdatum',
      weight: 2
    })
  }

  static AUFHEBUNGSDATUM_COLUMN() {
    return new DateColumn({
      key: 'aufhebungsdatum',
      text: 'geschaeftsvorfall.bewilligung.aufhebungsdatum',
      weight: 2
    })
  }

  static STATUS_COLUMN() {
    return new StringColumn({
      key: 'status',
      text: 'geschaeftsvorfall.bewilligung.status',
      weight: 2,
      sortable: true
    })
  }

  static AKTIONEN_COLUMN(configuration) {
    const {callback} = configuration
    return new GenericColumn({
        key: 'aktionen',
        text: '',
        weight: 0.5,
        align: 'right',
        cell: new DefaultActionCell({
          callback: callback,
          buttons: (i18n, row) => {
            const buttons = []

            if (row.isEditable) {
              buttons.push({
                action: 'edit',
                icon: 'clipboard',
                variant: 'primary',
                title: i18n.translate('geschaeftsvorfall.bewilligung.actions.edit')
              })
            }

            return buttons
          }
        })
      }
    )
  }

}
