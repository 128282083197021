import {css, html, LitElement} from '@isceco/widget-library2/external/lit'


export default class BetriebInfo extends LitElement {

  static get properties() {
    return {
      text: { type: String, attribute: false }
    }
  }

  static get styles() {
    return css`
        div {
          margin-left: 5px;
          background-color: white;
        }
    `
  }

  constructor() {
    super()
    this.text = ''
  }

  connectedCallback() {
    super.connectedCallback()
    window.betrieb$.subscribe(b => {
      if(b) {
        this.text = `${b.firmenbezeichnung}, ${b.strasse} ${b.strasseNr}, ${b.plz} ${b.ort}, ${b.codeKanton}`
      } else {
        this.text = ''
      }
    })

  }

  render() {
    return html`
      <style>@import '${iscecoWidgetLibrary.iconCss}';</style>
      <div>
        ${this.text}
      </div>
    `
  }

}

customElements.define('vzavg-betrieb-info', BetriebInfo)
