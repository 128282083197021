import {css, html} from '@isceco/widget-library2/external/lit'
import TranslationElement from '../../../../common/TranslationElement'
import {geschaeftsVorfallNavigationLinks} from '../../Geschaeftsvorfall'

export default class BetriebsartUebersicht extends TranslationElement {

  static get properties() {
    return {
      betriebsart: {type: String},
      gesuchId: {type: String}
    }
  }

  render() {
    if (!this.betriebsart?.length) {
      return html`
        ${this._renderTitel()}
        <span>${this.i18n.translate('geschaeftsvorfall.uebersicht.betriebsart.no.betriebsart')}</span>
      `
    }
    return html`
      ${this._renderTitel()}
      <span>${this.betriebsart}</span>
    `
  }

  static get styles() {
    return css`
      a {
        cursor: pointer;
      }
    `
  }

  _renderTitel() {
    return html`<a href="${this.link}">
      <isceco-title size="medium"
                    text="${this.i18n.translate('geschaeftsvorfall.uebersicht.betriebsart.title')}"></isceco-title>
    </a>`
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Uebersicht/BetriebsartUebersicht/i18n.json'
  }

  get link() {
    const baseUrl = location.origin + location.pathname + location.search
    return `${baseUrl}#${geschaeftsVorfallNavigationLinks.gesuch.hash}/${this.gesuchId}`
  }
}
customElements.define('betriebsart-uebersicht', BetriebsartUebersicht)
