import BaseService from '../BaseService'


export default class PendenzService extends BaseService {

  constructor() {
    super('pendenz')
  }

  async findOldestOpen() {
    await this.retrieveBackendUrl()
    return this.checkError(
      fetch(`${this.backendUrl}/oldestOpen`, {
        method: 'GET',
        cache: 'reload',
        headers: this.getJsonHeader()
      })
    )
  }

  async findOldestOpenUser() {
    await this.retrieveBackendUrl()
    return this.checkError(
      fetch(`${this.backendUrl}/oldestOpenUser`, {
        method: 'GET',
        cache: 'reload',
        headers: this.getJsonHeader()
      })
    )
  }

}
