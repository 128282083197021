import Authorization from './Authorization.js'
import {geschaeftsVorfallNavigationLinks} from '../Geschaeftsvorfall/Geschaeftsvorfall'
import {suchenNavigationLinkMap, suchenScopes} from '../Suchen/Suchen.js'
import {statistikdatenNavigationLinks} from '../Statistikdaten/Statistikdaten'

export default class AuthorizationFilter {

  static _initRoles() {
    this.roles = {
      betrieb: window.hasRole(Authorization.ROLES().betrieb),
      user: window.hasRole(Authorization.ROLES().user),
      kanton: window.hasRole(Authorization.ROLES().kanton),
      seco: window.hasRole(Authorization.ROLES().seco),
      super: window.hasRole(Authorization.ROLES().super)
    }
  }

  static viewsAllowed(names) {
    return names.filter(name => this.viewAllowed(name))
  }

  static viewAllowed(name) {
    this._initRoles()
    switch (name) {
      case 'Geschaeftsvorfall':
      case geschaeftsVorfallNavigationLinks.uebersicht.hash:
      case geschaeftsVorfallNavigationLinks.betrieb.hash:
      case geschaeftsVorfallNavigationLinks.gesuch.hash:
      case geschaeftsVorfallNavigationLinks.adresse.hash:
      case geschaeftsVorfallNavigationLinks.adresseEdit.hash:
      case geschaeftsVorfallNavigationLinks.verantwortlichePerson.hash:
      case geschaeftsVorfallNavigationLinks.verantwortlichePersonEdit.hash:
      case geschaeftsVorfallNavigationLinks.kaution.hash:
      case geschaeftsVorfallNavigationLinks.kautionEdit.hash:
      case geschaeftsVorfallNavigationLinks.dokumente.hash:
      case geschaeftsVorfallNavigationLinks.dokumenteEdit.hash:
      case geschaeftsVorfallNavigationLinks.entscheid.hash:
      case geschaeftsVorfallNavigationLinks.separation.hash:
      case geschaeftsVorfallNavigationLinks.bewilligung.hash:
      case geschaeftsVorfallNavigationLinks.bewilligungEdit.hash:
      case geschaeftsVorfallNavigationLinks.statistikDaten.hash:
      case geschaeftsVorfallNavigationLinks.notiz.hash:
      case geschaeftsVorfallNavigationLinks.notizEdit.hash:
      case geschaeftsVorfallNavigationLinks.pendenz.hash:
      case geschaeftsVorfallNavigationLinks.pendenzEdit.hash:
      case geschaeftsVorfallNavigationLinks.history.hash:
      case geschaeftsVorfallNavigationLinks.techInfos.hash:
      case geschaeftsVorfallNavigationLinks.documentViewer.hash:
        return this._hasAnyRole() && !this.roles.betrieb && getNavigationId() !== undefined
      case 'Administration':
      case 'Administration_Hilfe':
      case 'Administration_Branchen':
      case 'Administration_Dokumentation':
      case 'Administration_Stammdatenpflege':
      case 'Administration_Checklisten':
      case 'Administration_ChecklistenEdit':
      case 'Administration_Rechte':
        return this.roles.super
      case 'LogSettings':
        return this.roles.betrieb
      case 'News':
      case 'Cockpit':
      case suchenNavigationLinkMap.get(suchenScopes.global):
      case suchenNavigationLinkMap.get(suchenScopes.betrieb):
      case suchenNavigationLinkMap.get(suchenScopes.pendenz):
      case suchenNavigationLinkMap.get(suchenScopes.notiz):
      case suchenNavigationLinkMap.get(suchenScopes.verantwortlichep):
      case suchenNavigationLinkMap.get(suchenScopes.kaution):
      case suchenNavigationLinkMap.get(suchenScopes.gesuch):
      case suchenNavigationLinkMap.get(suchenScopes.bewilligung):
      case statistikdatenNavigationLinks.freigeben.hash:
      case statistikdatenNavigationLinks.konsolidieren.hash:
      case statistikdatenNavigationLinks.erhebung_freigeben.hash:
      case statistikdatenNavigationLinks.erhebung_abschliessen.hash:
      case 'Auswertung':
      case 'Statistikdaten':
      case 'Statistikdaten_Freigeben':
      case 'Statistikdaten_Konsolidieren':
      case 'Statistikdaten_Erhebung_Freigeben':
      case 'Statistikdaten_Erhebung_Abschliessen':
        return this._hasAnyRole() && !this.roles.betrieb
      case '':
        return true
      default:
        return false
    }
  }

  static _hasAnyRole() {
    return Object.values(this.roles).find(value => value === true) || false
  }

  static hasSecoRole() {
    this._initRoles()
    return this.roles.seco || this.roles.super
  }

  static hasKantonRole() {
    this._initRoles()
    return this.roles.kanton
  }

  static hasSuperRole() {
    this._initRoles()
    return this.roles.super
  }

  static isUserFromSameKanton() {
    return sessionStorage.getItem('userRechtCodeKanton') === window.betrieb$.getValue()?.codeKanton
  }

  static hasSelectedKanton() {
    return !isEmpty(window.betrieb$.getValue()?.codeKanton)
  }

  static hasUserWriteAccess() {
    return isEmpty(window.betrieb$.getValue()) && !isEmpty(sessionStorage.getItem('userRechtCodeKanton')) ||
      (AuthorizationFilter.hasKantonRole() ? AuthorizationFilter.hasSelectedKanton() && AuthorizationFilter.isUserFromSameKanton() : true)
  }

  static notHasWriteAccessOrIsClosed(isClosedForKanton, isClosedForSeco) {
    return !AuthorizationFilter.hasUserWriteAccess() ||
      (AuthorizationFilter.hasKantonRole() && isClosedForKanton) ||
      (AuthorizationFilter.hasSecoRole() && isClosedForSeco)
  }

}


