import {html} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../../WebComponent.js'
import ChecklistenColumns from './ChecklistenColumns.js'
import ChecklistenService from './ChecklistenService.js'
import VzavgListService from '../../../services/VzavgListService.js'
import DefaultMobileItem from '../../../components/DefaultMobileItem/DefaultMobileItem.js'

export default class Checklisten extends WebComponent {

  get translationFile() {
    return './views/Administration/Checklisten/i18n.json'
  }

  constructor() {
    super()
    this.checklistenService = new ChecklistenService()
  }

  connectedCallback() {
    super.connectedCallback()

    this.columns = [
      ChecklistenColumns.TYP_COLUMN(this.i18n),
      ChecklistenColumns.BEREICH_COLUMN(this.i18n),
      ChecklistenColumns.ERSTELLEN_VON_COLUMN(),
      ChecklistenColumns.ERSTELLEN_AM_COLUMN(),
      ChecklistenColumns.GUELTIG_VON_COLUMN(),
      ChecklistenColumns.GUELTIG_BIS_COLUMN(),
      ChecklistenColumns.AKTIONEN_COLUMN({callback: this._rowActionMenu})
    ]

    this.checklistenService.getBackendListUrl()
      .then(url => this.url = url)
      .then(_ => this.render())
  }

  getTemplate() {
    return html`
        <isceco-title text="${this.i18n.translate('checklisten.header')}">
            <isceco-button
                    id="checklisten-add-checkliste-btn"
                    title="${this.i18n.translate('checklisten.create.button')}"
                    icon="plus"
                    variant="primary"
                    size="small"
                    @click="${_ => navigate({to: 'Administration_ChecklistenEdit'})}"
            ></isceco-button>
        </isceco-title>

        ${this._getTemplateList()}
    `
  }

  _getTemplateList() {
    return html`
      <isceco-list
        id="checklist_list"
        .listService="${new VzavgListService(this.url)}"
        .columns="${this.columns}"
        .i18n="${this.i18n}"
        .mobileView="${DefaultMobileItem.getDefaultRenderer(this.i18n)}"
      ></isceco-list>
    `
  }

  _rowActionMenu = (row, action) => {
    switch (action) {
      case 'edit':
        navigate({to: 'Administration_ChecklistenEdit', id: row.id})
        break
      case 'copy':
        navigate({to: 'Administration_ChecklistenEdit', id: row.id, params: {copy: true}})
        break
      default:
        break
    }
  }

}
customElements.define('vzavg-frontend-checklisten', Checklisten)
