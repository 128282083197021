export default class I18n {

  static get EVENT_KEYS() {
    return {
      CHANGE_LANGUAGE: 'change-language'
    }
  }

  constructor() {
    this.translations = {}
  }

  async loadConfiguration(path) {
    return fetch(path, {
      credentials: 'same-origin',
      cache: 'reload',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(i18n => {
        this.translations = i18n
      })
  }

  translate(key, values) {
    const currentLanguage = this.translations[getLanguage()]
    let translatedText = currentLanguage ? currentLanguage[key] : null
    if (translatedText && values) {
      Object.keys(values).forEach(k => {
        translatedText = translatedText.replace(`~~${k}~~`, values[k])
      })
    }
    return translatedText || key
  }
}
