import {css, html, LitElement} from '@isceco/widget-library2/external/lit'
import I18n from '../../i18n'


export default class CheckListDialog extends LitElement {

  static get EVENT_KEYS() {
    return {
      RELOAD_TRIGGERED: 'checklist-reload-triggered'
    }
  }

  static get properties() {
    return {
      type: {type: String},
      items: {type: Array},
      value: {type: Array},
      event: {type: String},
      callback: {type: Function},
      confirmCallback: {type: Function},
      readonly: {type: Boolean}
    }
  }

  get processedItems() {
    return this.items?.filter(i => i.locale === getLanguage())
      .sort((a, b) => a.priority - b.priority) ?? []
  }

  static get styles() {
    return css`
    `
  }

  constructor() {
    super()
    this.items = []
    this.checks = []
  }

  connectedCallback() {
    super.connectedCallback()

    this._refresh = () => {
      this.requestUpdate()
    }

    this.titleKey = this.type === 'kanton' ? 'checklist.dialog.title.kanton' : 'checklist.dialog.title.seco'


    document.addEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)
    document.addEventListener(CheckListDialog.EVENT_KEYS.RELOAD_TRIGGERED, this._refresh)
  }

  disconnectedCallback() {
    super.disconnectedCallback()

    document.removeEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)
    document.removeEventListener(CheckListDialog.EVENT_KEYS.RELOAD_TRIGGERED, this._refresh)
  }

  render() {
    return this.readonly ? this._renderReadOnly() : this._renderEditable()
  }

  _renderEditable() {
    return html`
      <isceco-dialog
        hidden
        header="${this.i18n.translate(this.titleKey)}"
        id="vzavg-checklist-dialog"
        confirm-button="${this.i18n.translate('checklist.dialog.buttons.confirm')}"
        Cancel-button="${this.i18n.translate('checklist.dialog.buttons.cancel')}"
        @submit="${confirmed => this._close(confirmed)}"
      >
        ${this.renderChecks()}
      </isceco-dialog>
    `
  }

  _renderReadOnly() {
    return html`
      <isceco-dialog
        hidden
        header="${this.i18n.translate(this.titleKey)}"
        id="vzavg-checklist-dialog"
        Cancel-button="${this.i18n.translate('checklist.dialog.buttons.cancel')}"
        @submit="${submit => this._close(submit)}"
      >
        ${this.renderChecks()}
      </isceco-dialog>
    `
  }

  renderChecks() {
    return html`
      <div class="checks">
        ${this.processedItems.map(i => html`
          <isceco-checkbox
            label="${i.label}"
            ?value="${i.isChecked}"
            ?disabled="${this.readonly}"
          ></isceco-checkbox>
        `)}
      </div>`
  }

  _close(submit) {
    const dialog = this.shadowRoot.querySelector('#vzavg-checklist-dialog')
    dialog.setAttribute('hidden', '')
    if (this.confirmCallback && submit.detail.confirmed) {
      this.confirmCallback(this.getValues())
    } else {
      this._resetValues();
    }

    this._refresh()
  }

  _resetValues() {
    const checkboxes = this.shadowRoot.querySelectorAll('isceco-checkbox');
    for (let i = 0; i < checkboxes.length; i++) {
      for (const origItem of this.originalValues) {
        if (origItem.priority - 1 === i) {
          checkboxes[i].value = origItem.isChecked
        }
      }
    }
  }

  getValues() {
    const dialog = this._dialogElement()
    const checkboxes = dialog.querySelectorAll('isceco-checkbox')
    const checks = []
    for (const [i, checkbox] of checkboxes.entries()) {
      checks.push({priority: i + 1, value: checkbox.getValue()})
    }
    return checks;
  }

  show() {
    this.originalValues = []
    for (const item of this.items) {
      if (item.locale === 'de') {
        this.originalValues.push({priority: item.priority, isChecked: item.isChecked})
      }
    }

    this._refresh()
    this._dialogElement().removeAttribute('hidden')
  }

  _dialogElement() {
    return this.shadowRoot.querySelector('#vzavg-checklist-dialog');
  }
}
customElements.define('vzavg-checklist-dialog', CheckListDialog)
