import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Checkbox/Checkbox.js'

export default class StatistikCheckboxCell {
  get name() {
    return 'CheckboxCell'
  }

  constructor(configuration) {
    this.callback = configuration.callback ? configuration.callback : () => false
    this.disabled = configuration.disabled ? configuration.disabled : () => false
  }

  render(i18n, cell, row) {
    return isEmpty(row.eingangsdatum) ?  html`` :
      html`
      <isceco-checkbox @click="${e => e.stopPropagation()}"
                       @change="${e => this.callback(e.detail.value, row)}"
                       ?value="${cell}"
                       ?disabled="${this.disabled(row)}"
      ></isceco-checkbox>
    `
  }
}
