import {css, html} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../../../WebComponent.js'
import I18n from '../../../../i18n.js'
import StammdatenService from '../../../../services/StammdatenService.js'

export default class GesuchFilter extends WebComponent {

  constructor() {
    super()
    const stammdatenService = new StammdatenService();
    Promise.all([stammdatenService.getOrganisation(), stammdatenService.getEntscheidGesuch()])
      .then(([kantone, entscheidGesuch]) => {
        this.kantone = kantone.map(kanton => ({value: kanton.code, name: kanton.bezeichnung}))
        this.entscheidGesuch = entscheidGesuch.map(eg => ({value: eg.code, name: eg.bezeichnung}))
      })
      .then(_ => this.render())
  }

  get translationFile() {
    return './views/Suchen/Form/Filter/i18n.json'
  }

  get values() {
    return this._values
  }

  set values(val) {
    this._values = val
  }

  get css() {
    return css`
    `
  }

  get booleanItems() {
    return [
      {name: this.i18n.translate('yes'), value: true},
      {name: this.i18n.translate('no'), value: false}
    ]
  }

  connectedCallback() {
    super.connectedCallback()
    this._refresh = () => {
      this.render()
    }


    document.addEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)
    this.render()
  }

  disconnectedCallback() {
    document.removeEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)
  }

  getTemplate() {
    return html`
      <div class="filter-wrapper">
        <isceco-dropdown label="${this.i18n.translate('suchen.betrieb.kanton')}" id="kanton" name="kanton"
                         .items="${this.kantone}"
                         .value="${this.values?.kanton ?? sessionStorage.getItem('userRechtCodeKanton')}"
        ></isceco-dropdown>
        <isceco-date-input label="${this.i18n.translate('suchen.gesuch.eingangsdatumVon')}"
                           id="eingangsdatumVon"
                           name="eingangsdatumVon"
                           .value="${this.values?.eingangsdatumVon}"
                           .max="${this.values?.eingangsdatumBis}"
                           @change="${event => this._updateBisDate(event.detail.value, "eingangsdatumVon", "eingangsdatumBis")}"
        >
        </isceco-date-input>
        <isceco-date-input label="${this.i18n.translate('suchen.gesuch.eingangsdatumBis')}"
                           id="eingangsdatumBis"
                           name="eingangsdatumBis"
                           .min="${this.values?.eingangsdatumVon}"
                           .value="${this.values?.eingangsdatumBis}"
                           @change="${event => this._updateBisDate(event.detail.value, "eingangsdatumBis")}"
        >
        </isceco-date-input>
        <isceco-date-input label="${this.i18n.translate('suchen.gesuch.ausstellungsdatumVon')}"
                           id="ausstellungsdatumVon"
                           name="ausstellungsdatumVon"
                           .value="${this.values?.ausstellungsdatumVon}"
                           .max="${this.values?.ausstellungsdatumBis}"
                           @change="${event => this._updateBisDate(event.detail.value, "ausstellungsdatumVon", "ausstellungsdatumBis")}"
        >
        </isceco-date-input>
        <isceco-date-input label="${this.i18n.translate('suchen.gesuch.ausstellungsdatumBis')}"
                           id="ausstellungsdatumBis"
                           name="ausstellungsdatumBis"
                           .min="${this.values?.eingangsdatumVon}"
                           .value="${this.values?.ausstellungsdatumVon}"
                           @change="${event => this._updateBisDate(event.detail.value, "ausstellungsdatumBis")}"
        >
        </isceco-date-input>
        <isceco-dropdown
          label="${this.i18n.translate('suchen.gesuch.gesuchsart')}"
          id="gesuchsart" name="gesuchsart"
          .value="${this.values?.gesuchsart}"
          .items="${[
            {name: this.i18n.translate('suchen.gesuch.erstgesuch'), value: 'erstgesuch'},
            {
              name: this.i18n.translate('suchen.gesuch.aenderungsgesuch'),
              value: 'aenderungsgesuch'
            }
          ]}"
        ></isceco-dropdown>
        <isceco-dropdown
          label="${this.i18n.translate('suchen.gesuch.entscheid')}"
          id="entscheid" name="entscheid"
          .value="${this.values?.entscheid}"
          .items="${this.entscheidGesuch}"
        ></isceco-dropdown>
        <isceco-dropdown
          label="${this.i18n.translate('suchen.gesuch.bereich')}"
          id="bereich" name="bereich"
          .value="${this.values?.bereich}"
          .items="${[
            {name: this.i18n.translate('suchen.gesuch.kanton'), value: 'kanton'},
            {name: this.i18n.translate('suchen.gesuch.seco'), value: 'seco'}
          ]}"
        ></isceco-dropdown>
        <isceco-date-input
          label="${this.i18n.translate('suchen.gesuch.bestaetigungsdatumVon')}"
          id="bestaetigungsdatumVon"
          name="bestaetigungsdatumVon"
          .value="${this.values?.bestaetigungsdatumVon}"
          .max="${this.values?.bestaetigungsdatumBis}"
          @change="${event => this._updateBisDate(event.detail.value, "bestaetigungsdatumVon", "bestaetigungsdatumBis")}"
        >
        </isceco-date-input>
        <isceco-date-input
          label="${this.i18n.translate('suchen.gesuch.bestaetigungsdatumBis')}"
          id="bestaetigungsdatumBis"
          name="bestaetigungsdatumBis"
          .min="${this.values?.bestaetigungsdatumBis}"
          .value="${this.values?.bestaetigungsdatumVon}"
          @change="${event => this._updateBisDate(event.detail.value, "bestaetigungsdatumBis")}"
        >
        </isceco-date-input>
      </div>
    `
  }

  _updateBisDate(newDate, key1, key2 = undefined) {
    this.values[key1] = newDate
    this.values[key2] = newDate
    this.render()
  }


}
customElements.define('vzavg-frontend-suche-gesuch-filter', GesuchFilter)
