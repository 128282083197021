import BaseService from '../BaseService'
import VzavgListService from './VzavgListService'

export default class GesuchPendenzService extends BaseService {
  constructor() {
    super('gesuch')
  }

  async getListService(gesuchId) {
    return this.getBackendListUrl(`${gesuchId}/pendenz`)
      .then(url => new VzavgListService(url))
  }

  async getPendenz(gesuchId, pendenzId) {
    return super.read(gesuchId, `pendenz/${pendenzId}`)
  }

  async updatePendenz(formDatas, gesuchId) {
    return super.update(formDatas, `${gesuchId}/pendenz`)
  }

  async createPendenz(formDatas, gesuchId) {
    return super.create(formDatas, `${gesuchId}/pendenz`)
  }

  async getParticipants(gesuchId) {
    return super.read(gesuchId, `pendenz/participants`)
  }

}
