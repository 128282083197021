import {css, html} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../../WebComponent.js'
import BewilligungService from '../../../services/BewilligungService.js'
import DefaultMobileItem from '../../../components/DefaultMobileItem/DefaultMobileItem.js'
import {geschaeftsVorfallNavigationLinks} from '../Geschaeftsvorfall.js'
import BewilligungColumns from './BewilligungColumns.js'
import BewilligungRowVariant from './BewilligungRowVariant.js'

export const bewilligungTypen = i18n => {
  return [
    {
      value: 'AV',
      name: i18n.translate('suchen.betrieb.arbeitsVermittlung')
    },
    {
      value: 'PV',
      name: i18n.translate('suchen.betrieb.personalVerleih')
    }
  ]
}

export default class Bewilligung extends WebComponent {

  static get EVENT_KEYS() {
    return {
      TRIGGER_BEWILLIGUNG_TABLE_RELOAD: 'geschaeftvorfall.bewilligung.table.reload.event'
    }
  }

  constructor() {
    super()
    this.bewilligungService = new BewilligungService();
  }

  get css() {
    return css``
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Bewilligung/i18n.json'
  }

  async connectedCallback() {
    super.connectedCallback()

    this.gesuchId = getNavigationId()
    this.bewilligungListService = await this.bewilligungService.getListService(this.gesuchId)

    this.columns = [
      BewilligungColumns.BEREICH_COLUMN(),
      BewilligungColumns.TYP_COLUMN(),
      BewilligungColumns.AUSSTELLUNGSGRUND(),
      BewilligungColumns.ERSTAUSSTELLUNGSDATUM_COLUMN(),
      BewilligungColumns.AENDERUNGSDATUM_COLUMN(),
      BewilligungColumns.AUFHEBUNGSDATUM_COLUMN(),
      BewilligungColumns.STATUS_COLUMN(),
      BewilligungColumns.AKTIONEN_COLUMN({
        callback: this._rowAction
      })
    ]

    this.render()
  }

  getTemplate() {
    return html`
      <isceco-title text="${this.i18n.translate('geschaeftsvorfall.bewilligung.header')}"></isceco-title>

      ${this._getTemplateList()}
    `
  }

  _getTemplateList() {
    return html`
      <isceco-list
        .listService="${this.bewilligungListService}"
        .columns="${this.columns}"
        .rowVariant="${new BewilligungRowVariant()}"
        .i18n="${this.i18n}"
        .mobileView="${DefaultMobileItem.getDefaultRenderer(this.i18n)}"
        update-event="${Bewilligung.EVENT_KEYS.TRIGGER_BEWILLIGUNG_TABLE_RELOAD}"
      ></isceco-list>
    `
  }

  _rowAction = (row, action) => {
    if (action === 'edit') {
      navigate({to: geschaeftsVorfallNavigationLinks.bewilligungEdit.hash, id: `${this.gesuchId}/${row.id}`})
    }
  }
}

customElements.define('vzavg-frontend-geschaeftsvorfall-bewilligung', Bewilligung)
