import {css, html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/TextInput/TextInput.js'
import {geschaeftsVorfallNavigationLinks} from '../../Geschaeftsvorfall'
import TranslationElement from '../../../../common/TranslationElement'

export default class BestaetigungsdatumUebersicht extends TranslationElement {

  static get properties() {
    return {
      bestaetigungsDatum: {type: String},
      gesuchId: {type: String}
    }
  }

  render() {
    if(!this.bestaetigungsDatum?.length) {
      return html`
      ${this._renderTitel()}
      <span>${this.i18n.translate('geschaeftsvorfall.uebersicht.bestaetigungsdatum.no.date')}</span>
    `
    }
    return html`
      ${this._renderTitel()}
      <span>${this.date}</span>
    `
  }

  static get styles() {
    return css`
      a {
        cursor: pointer;
      }
    `
  }

  get link() {
    const baseUrl = location.origin + location.pathname + location.search
    return `${baseUrl}#${geschaeftsVorfallNavigationLinks.gesuch.hash}/${this.gesuchId}`
  }

  get date() {
    const bestaetigungsDatum = new Date(this.bestaetigungsDatum);
    const day = bestaetigungsDatum.getDate().toString().padStart(2, '0');
    const month = (bestaetigungsDatum.getMonth() + 1).toString().padStart(2, '0');
    const year = bestaetigungsDatum.getFullYear();
    return `${day}.${month}.${year}`;
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Uebersicht/BestaetigungsdatumUebersicht/i18n.json'
  }

  _renderTitel() {
    return html`<a href="${this.link}">
      <isceco-title size="medium"
                    text="${this.i18n.translate('geschaeftsvorfall.uebersicht.bestaetigungsdatum.title')}"></isceco-title>
    </a>`
  }
}
customElements.define('bestaetigungsdatum-uebersicht', BestaetigungsdatumUebersicht)
