import {css} from '@isceco/widget-library2/external/lit'

export default css`
  .mobile-overview-item > *:not(:first-child) {
    margin-top: var(--isceco-space-200);
  }

  .mobile-overview-item > * {
    margin: 0;
  }

  span {
    padding: 3px;
    width: 100%;
  }

  .title {
    background-color: var(--isceco-color-grey-100);
  }

  .mobile-action-item {
    float: right;
  }

  .item {
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    background-color: white;
  }

  .label {
    margin-right: 5px;
  }
`
