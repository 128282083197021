import BaseService from '../BaseService.js'

export default class BetriebService extends BaseService {

  constructor() {
    super('betrieb')
  }


  getByUID(uid) {
    return {
      uid: 'CHE-123.456.789',
      name: 'Obsidian AG',
      addressLine1: 'Adresszusatz /Co',
      addressLine2: 'Obsidianstreet 11',
      addressLine3: '3000 Bern',
      addressLine4: '',
      phone: '+41 31 123 45 67',
      email: 'info@obsidian.swiss',
      url: 'https://www.obsidian.swiss',
      verantwortlichePersonnen: ['person 1', 'person 2', 'person 3'],
      betriebsart: 'III = Personalverleih (PV)',
      branchen: ['Informatik / Telekommunikation', 'Bank- und Versicherungen'],
      revisionstelle: {
        addressLine1: 'Obsidian Revision AG',
        addressLine2: 'Obsidianstreet 12',
        addressLine3: '3000 Bern',
        phone: '+41 31 123 45 67'
      },
      revisionstelleHs: {
        addressLine1: 'Obsidian Revision AG',
        addressLine2: 'Addresszusatz / Co',
        addressLine3: 'Obsidianstreet 12',
        addressLine4: '3000 Bern',
        phone: '+41 31 123 45 68'
      },
      geschaeftsstellen: {
        addressLine1: 'Obsidian Filiale 42',
        addressLine2: 'Obsidianstreet 42',
        addressLine3: '3000 Bern',
        addressLine4: 'Obsidianstreet 42',
        phone: '+41 31 123 45 69',
        email: 'filiale42@obsidian.swiss',
        url: 'https://www.obsidian.swiss'
      },
      kaution: [
        {
          name1: 'Kautionversicherung',
          name2: 'Obsitian versicherungen AG',
          amount: 100_000,
          duration: '31.12.2028'
        },
        {
          name1: 'Bareinalge bei Kanton',
          name2: 'AWA Bern',
          amount: 100_000,
          duration: '31.12.2028'
        }
      ],
      qualifikationen: [
        'Kader',
        'Angelstellte',
        'Hilfsarbeiter'
      ],
      bewilligungen: {
        kantonal: {
          eingang:'01.02.2024'
        },
        eidgenoessisch: {

        },
        fl: {
          entzug:'31.03.2024'
        }
      },
      hauptsitz : {
        addressLine1: 'Obsidian Group',
        addressLine2: 'Adresszusatz / Co',
        addressLine3: 'Obsidianstreet 14',
        addressLine4: '3000 Bern',
        phone: '+41 31 123 45 66'
      },
      pendenzen: {
        anzahl: 3
      },
      notizen: {
        anzahl: 4
      }
    }
  }
}
