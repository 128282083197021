import WebComponent from '../../../../WebComponent'
import StammdatenService from '../../../../services/StammdatenService'
import {html} from '@isceco/widget-library2/external/lit'
import I18n from '../../../../i18n'

export default class PendenzFilter extends WebComponent {

  constructor() {
    super()
    const stammdatenService = new StammdatenService();
    stammdatenService.getOrganisation()
      .then(kantone => {
        this.kantone = kantone.map(kanton => ({value: kanton.code, name: kanton.bezeichnung}))
      })
      .then(_ => this.render())
  }

  get translationFile() {
    return './views/Suchen/Form/Filter/i18n.json'
  }

  get values() {
    return this._values
  }

  get status() {
    return [
      {name: this.i18n.translate('suchen.pendenz.erledigt'), value: true},
      {name: this.i18n.translate('suchen.pendenz.unerledigt'), value: false}
    ]
  }

  get betriebStatus() {
    return [
      {name: this.i18n.translate('suchen.aktiv'), value: true},
      {name: this.i18n.translate('suchen.inaktiv'), value: false}
    ]
  }

  set values(val) {
    this._values = val
  }

  connectedCallback() {
    super.connectedCallback()
    this._refresh = () => {
      this.render()
    }


    document.addEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)
    this.render()
  }

  disconnectedCallback() {
    document.removeEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)
  }


  getTemplate() {
    return html`
      <div class="filter-wrapper">
        <isceco-dropdown label="${this.i18n.translate('suchen.kanton')}" id="kanton" name="kanton"
                         .items="${this.kantone}"
                         .value="${this.values?.kanton ?? sessionStorage.getItem('userRechtCodeKanton')}"
        ></isceco-dropdown>
        <isceco-dropdown label="${this.i18n.translate('suchen.betriebStatus')}" id="betriebIsActive"
                         name="betriebIsActive"
                         .items="${this.betriebStatus}"
                         .value="${this.values?.betriebIsActive}"
        ></isceco-dropdown>
        <isceco-date-input label="${this.i18n.translate('suchen.eingangsdatumVon')}" id="eingangsdatumVon"
                           name="eingangsdatumVon"
                           .value="${this.values?.eingangsdatumVon}"
        >
        </isceco-date-input>
        <isceco-date-input label="${this.i18n.translate('suchen.eingangsdatumBis')}" id="eingangsdatumBis"
                           name="eingangsdatumBis"
                           .value="${this.values?.eingangsdatumBis}"
        >
        </isceco-date-input>
        <isceco-dropdown label="${this.i18n.translate('suchen.status')}" id="isDone" name="isDone"
                         .items="${this.status}"
                         .value="${this.values?.isDone}"
        ></isceco-dropdown>
      </div>
    `
  }
}
customElements.define('vzavg-frontend-suche-pendenz-filter', PendenzFilter)
