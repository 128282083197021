import {css, html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Button/Button.js'
import '@isceco/widget-library2/basic-elements/Dropdown/Dropdown.js'
import '@isceco/widget-library2/basic-elements/DropdownMultiselect/DropdownMultiselect.js'
import '@isceco/widget-library2/basic-elements/Title/Title.js'
import I18n from '../../../i18n.js'
import WebComponent from '../../../WebComponent.js'
import AuthorizationFilter from '../../Authorization/AuthorizationFilter.js'
import Options from '../../../common/Options.js'
import BewilligungService from '../../../services/BewilligungService.js'
import {geschaeftsVorfallNavigationLinks} from '../Geschaeftsvorfall.js'

const BITTE_AUSWAEHLEN_KEY = 'bitte.auswaehlen'
export default class BewilligungEdit extends WebComponent {

  constructor() {
    super()

    this.service = new BewilligungService()
  }

  get css() {
    return css`
      .grid-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 1em;
      }

      @media (max-width: 991.98px) {
        .grid-wrapper {
          grid-template-columns: 1fr;
        }
      }

      .column {
        display: flex;
        flex-direction: column;
      }
    `
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Bewilligung/i18n.json'
  }

  async connectedCallback() {
    super.connectedCallback();

    this._refresh = () => this._getDataAndRender()

    document.addEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)
    await this._refresh()
  }

  disconnectedCallback() {
    document.removeEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)
  }

  getTemplate() {
    return html`
      ${this._renderBackButton()}
      ${this._renderTitle()}
      <isceco-form id="bewilligung-form">
        <div slot="form-elements">
          <div class="grid-wrapper">
            <div class="column">
              ${this._renderBereich()}
              ${this._renderTyp()}
              ${this._renderAction()}
              ${this._renderGrund()}
            </div>
            <div class="column">
              ${this._renderAufschWirkung()}
              ${this._renderAufhebungsdatum()}
              ${this._renderReaktivierung()}
              ${this._renderBeschwerde()}
              ${this._renderWeiterarbeit()}
              ${this._renderGerichtsurteil()}
            </div>
          </div>
        </div>
      </isceco-form>
    `
  }

  _renderBackButton() {
    return html`
      <isceco-button
        id="bewilligung-back"
        variant="secondary"
        icon="arrow left"
        text="${this.i18n.translate('back.to.list')}"
        @click="${_ => this._backToList()}"
      ></isceco-button>
    `
  }

  _renderTitle() {
    return html`
      <isceco-title
        id="page-title"
        size="large"
        text="${this.i18n.translate('geschaeftsvorfall.bewilligung.edit.title')}">
        <nav class="title-navigation">
          ${this.hasWriteAccess ?
          html`
          <isceco-button
            id="speichern"
            variant="primary"
            icon="save"
            @click="${_ => this.save()}"
            ?disabled="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
          ></isceco-button>
          `: ``}
        </nav>
      </isceco-title>
    `
  }

  _renderBereich() {
    return html`
      <isceco-dropdown
        id="bereich"
        name="bereich"
        required
        value="${this.backendData.bereich}"
        label="${this.i18n.translate('geschaeftsvorfall.bewilligung.bereich')} *"
        .items="${[
          {value: 'kanton', name: this.i18n.translate('geschaeftsvorfall.bewilligung.bereich.kanton')},
          {value: 'seco', name: this.i18n.translate('geschaeftsvorfall.bewilligung.bereich.seco')}
        ]}"
        placeholder="${this.i18n.translate(BITTE_AUSWAEHLEN_KEY)}"
        disabled
      ></isceco-dropdown>
    `
  }

  _renderTyp() {
    return html`
      <isceco-dropdown-multiselect
        id="typ"
        name="typ"
        .value="${this.backendData.typ}"
        label="${this.i18n.translate('geschaeftsvorfall.bewilligung.typ')} *"
        .items="${[
          {value: 'AV', name: this.i18n.translate('geschaeftsvorfall.bewilligung.typ.vermittlung')},
          {value: 'PV', name: this.i18n.translate('geschaeftsvorfall.bewilligung.typ.verleih')}
        ]}"
        placeholder="${this.i18n.translate(BITTE_AUSWAEHLEN_KEY)}"
        disabled
      ></isceco-dropdown-multiselect>
    `
  }

  _renderAction() {
    return html`
      <isceco-dropdown
        id="aktion"
        name="aktion"
        required
        value="${this.backendData.aktion}"
        label="${this.i18n.translate('geschaeftsvorfall.bewilligung.action')} *"
        .items="${[
          {value: 'aufheben', name: this.i18n.translate('geschaeftsvorfall.bewilligung.action.aufheben')},
          {value: 'provEntziehen', name: this.i18n.translate('geschaeftsvorfall.bewilligung.action.provEntziehen')},
          {value: 'reaktivieren', name: this.i18n.translate('geschaeftsvorfall.bewilligung.action.reaktivieren')},
          {value: 'korrigieren', name: this.i18n.translate('geschaeftsvorfall.bewilligung.action.korrigieren')}
        ]}"
        placeholder="${this.i18n.translate(BITTE_AUSWAEHLEN_KEY)}"
        @change="${e => this._updateValue(e)}"
        .disabled="${!this.hasWriteAccess}"
      ></isceco-dropdown>
    `
  }

  _renderGrund() {
    return html`
      <isceco-dropdown
        id="grund"
        name="grund"
        required
        value="${this.backendData.grund}"
        label="${this.i18n.translate('geschaeftsvorfall.bewilligung.grund')} *"
        placeholder="${this.i18n.translate(BITTE_AUSWAEHLEN_KEY)}"
        @change="${e => this._updateValue(e)}"
        .disabled="${!this.hasWriteAccess}"
        .items="${[
          {
            value: 'betriebEingestellt',
            name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.betriebEingestellt')
          },
          {value: 'konkurs', name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.konkurs')},
          {
            value: 'bewillNichtErfuellt',
            name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.bewillNichtErfuellt')
          },
          {
            value: 'sitzverlegung',
            name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.sitzverlegung')
          },
          {
            value: 'bewilRetourniert',
            name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.bewilRetourniert')
          },
          {
            value: 'neuFirmaGegruendet',
            name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.neuFirmaGegruendet')
          },
          {
            value: 'keinVerleihMehr',
            name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.keinVerleihMehr')
          },
          {
            value: 'eintragsfehler',
            name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.eintragsfehler')
          },
          {
            value: 'gesuchUmAufhebung',
            name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.gesuchUmAufhebung')
          },
          {
            value: 'bewilEntzogen',
            name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.bewilEntzogen')
          },
          {
            value: 'firmaReaktiviert',
            name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.firmaReaktiviert')
          },
          {value: 'weggezogen', name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.weggezogen')},
          {
            value: 'aufenthaltUnbekannt',
            name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.aufenthaltUnbekannt')
          },
          {
            value: 'irrefuerhendeAngabe',
            name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.irrefuerhendeAngabe')
          },
          {
            value: 'gesetzesverstoesse',
            name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.gesetzesverstoesse')
          },
          {
            value: 'erfassungsfehler',
            name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.erfassungsfehler')
          }
        ]}"
      ></isceco-dropdown>
    `
  }

  _renderAufschWirkung() {
    return html`
      <isceco-dropdown
        id="aufschWirkung"
        name="aufschWirkung"
        value="${this.backendData.aufschWirkung}"
        label="${this.i18n.translate('geschaeftsvorfall.bewilligung.aufschWirkung')}"
        .items="${Options.yesNo}"
        .disabled="${!this.hasWriteAccess}"
      ></isceco-dropdown>
    `
  }

  _renderAufhebungsdatum() {
    return html`
      <isceco-date-input
        id="aufhebungsdatum"
        name="aufhebungsdatum"
        value="${this.backendData.aufhebungsdatum}"
        label="${this.i18n.translate('geschaeftsvorfall.bewilligung.datum')}"
        @change="${e => this._updateValue(e, true)}"
        min="${this.backendData.erstausstellungsdatum}"
        .disabled="${!this.hasWriteAccess}"
      ></isceco-date-input>
    `
  }

  _renderReaktivierung() {
    return html`
      <isceco-date-input
        id="reaktivierung"
        name="reaktivierung"
        value="${this.backendData.reaktivierung}"
        label="${this.i18n.translate('geschaeftsvorfall.bewilligung.reaktivierung')}"
        @change="${e => this._updateValue(e, true)}"
        .disabled="${!this.hasWriteAccess}"
      ></isceco-date-input>
    `
  }

  _renderBeschwerde() {
    return html`
      <isceco-date-input
        id="beschwerde"
        name="beschwerde"
        value="${this.backendData.beschwerde}"
        label="${this.i18n.translate('geschaeftsvorfall.bewilligung.beschwerde')}"
        @change="${e => this._updateValue(e, true)}"
        .disabled="${!this.hasWriteAccess}"
      ></isceco-date-input>
    `
  }

  _renderWeiterarbeit() {
    return html`
      <isceco-date-input
        id="weiterarbeit"
        name="weiterarbeit"
        value="${this.backendData.weiterarbeit}"
        label="${this.i18n.translate('geschaeftsvorfall.bewilligung.weiterarbeit')}"
        @change="${e => this._updateValue(e, true)}"
        .disabled="${!this.hasWriteAccess}"
      ></isceco-date-input>
    `
  }

  _renderGerichtsurteil() {
    return html`
      <isceco-date-input
        id="gerichtsurteil"
        name="gerichtsurteil"
        value="${this.backendData.gerichtsurteil}"
        label="${this.i18n.translate('geschaeftsvorfall.bewilligung.gerichtsurteil')}"
        @change="${e => this._updateValue(e, true)}"
        .disabled="${!this.hasWriteAccess}"
      ></isceco-date-input>
    `
  }

  async _getDataAndRender() {
    this.gesuchId = getNavigationId()
    this.bewilligungId = this._getBewilligungId()

    if (!this.bewilligungId) {
      this._initBackendData()
      this.render()
      return
    }

    try {
      const bewilligungResponse = await this.service.getBewilligung(this.gesuchId, this.bewilligungId)
      if (isNoDataFound(bewilligungResponse)) {
        this._initBackendData()
        this.render()
        return
      }

      this.backendData = bewilligungResponse
      this.backendData.kantonCheckList = []
      this.backendData.secoCheckList = []
      this.backendData.typ = this.backendData.typ ? this.backendData.typ : []

      const hasAccessAsKanton = this.backendData.bereich === 'kanton' && AuthorizationFilter.hasKantonRole() && AuthorizationFilter.isUserFromSameKanton()
      const hasAccessAsSeco = this.backendData.bereich === 'seco' && AuthorizationFilter.hasSecoRole()
      this.hasWriteAccess = hasAccessAsKanton || hasAccessAsSeco

    } catch (_) {
      showAlert('', this.i18n.translate('geschaeftsvorfall.bewilligung.edit.error'))
      this._initBackendData()
    }
    this.render()
  }

  async save() {
    const form = document.getElementById('bewilligung-form')
    if (!form.validate()) {
      return Promise.resolve(false)
    }
    const formDatas = this._prepareFormDataForSaving(form)
    return this._updateAndRefresh(formDatas)
  }

  _prepareFormDataForSaving(form) {
    const formDatas = form.getValues()
    formDatas.id = this.backendData.id
    formDatas.bewilligungId = this.backendData.bewilligungId
    formDatas.gesuchId = this.gesuchId
    return formDatas
  }

  _updateAndRefresh(formDatas) {
    return this.service.updateBewilligung(formDatas, this.gesuchId, this.bewilligungId)
      .then(_ => this._refresh)
  }

  _updateValue(event, reload) {
    const key = event.target.id
    const value = event.detail.value
    if (this.backendData[key] !== value) {
      window.hasChanges = true;
      this.backendData[key] = value
    }
    if (reload) {
      this.reload();
    }
  }

  _initBackendData() {
    this.backendData = {}
    this.backendData.typ = []
  }

  _getBewilligungId() {
    return window.location.hash.split('?')[0].split('/')[2]
  }

  _backToList() {
    navigate({to: geschaeftsVorfallNavigationLinks.bewilligung.hash, id: this.gesuchId})
  }

  get grund() {
    return [
      {
        value: 'betriebEingestellt',
        name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.betriebEingestellt')
      },
      {value: 'konkurs', name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.konkurs')},
      {
        value: 'bewillNichtErfuellt',
        name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.bewillNichtErfuellt')
      },
      {
        value: 'sitzverlegung',
        name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.sitzverlegung')
      },
      {
        value: 'bewilRetourniert',
        name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.bewilRetourniert')
      },
      {
        value: 'neuFirmaGegruendet',
        name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.neuFirmaGegruendet')
      },
      {
        value: 'keinVerleihMehr',
        name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.keinVerleihMehr')
      },
      {
        value: 'eintragsfehler',
        name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.eintragsfehler')
      },
      {
        value: 'gesuchUmAufhebung',
        name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.gesuchUmAufhebung')
      },
      {
        value: 'bewilEntzogen',
        name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.bewilEntzogen')
      },
      {
        value: 'firmaReaktiviert',
        name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.firmaReaktiviert')
      },
      {value: 'weggezogen', name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.weggezogen')},
      {
        value: 'aufenthaltUnbekannt',
        name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.aufenthaltUnbekannt')
      },
      {
        value: 'irrefuerhendeAngabe',
        name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.irrefuerhendeAngabe')
      },
      {
        value: 'gesetzesverstoesse',
        name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.gesetzesverstoesse')
      },
      {
        value: 'erfassungsfehler',
        name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.erfassungsfehler')
      }
    ]
  }
}
customElements.define('vzavg-bewilligung-edit', BewilligungEdit)
