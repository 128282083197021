export {Observable}

/**
 * @typedef { <T> (newValue:T, oldValue: ?T) => void } ValueChangeCallbackFunction<T>
 * The "oldValue" contains the value before the change.
 */

/**
 * @typedef Unsubscription
 * @property {() => void} unsubscribe - removes the callback function from the listeners
 *
 */

/**
 * @typedef Observable<T>
 * @template T
 * @property {() => T} getValue - function which returns the current value
 * @property {(T) => void} setValue - function which sets the value to provided value
 * @property {(cb: ValueChangeCallbackFunction) => Unsubscription} subscribe - callback function which gets called with newValue and oldValue when value changed
 */

/**
 * Observable<T>.
 * @pure
 * @template T
 * @param    {T} value      inital value, is mandatory
 * @returns  { Observable<T> }
 * @constructor
 * @example
 * const obs = Observable("");
 * obs.subscribe(val => console.log(val));
 * obs.setValue("some other value"); // will be logged
 */
const Observable = value => {
  const listeners = [];
  const unsubscribe = cb => {
    const idx = listeners.findIndex(listener => listener.toString() === cb.toString());
    listeners.splice(idx, 1);
  }
  return {
    subscribe: callback => {
      listeners.push(callback);
      callback(value, value);
      return {
        unsubscribe: () => unsubscribe(callback)
      };
    },
    getValue: () => value,
    setValue: newValue => {
      if (value === newValue) {
        return
      }
      const oldValue = value;
      value = newValue;
      listeners.forEach(callback => {
        if (value === newValue) {
          callback(value, oldValue);
        }
      });
    }
  }
};
