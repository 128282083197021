import {css, html} from '@isceco/widget-library2/external/lit'
import TranslationElement from '../../../../common/TranslationElement'
import {geschaeftsVorfallNavigationLinks} from '../../Geschaeftsvorfall'

export default class MarkenUebersicht extends TranslationElement {

  static get properties() {
    return {
      marken: {type: String},
      gesuchId: {type: String}
    }
  }

  render() {
    if(!this.marken?.length) {
      return html`
      ${this._renderTitel()}
      <span>${this.i18n.translate('geschaeftsvorfall.uebersicht.marken.no.marken')}</span>
    `
    }
    return html`
      ${this._renderTitel()}
      <p>${this.marken}</p>
    `
  }

  static get styles() {
    return css`
      a {
        cursor: pointer;
      }
    `
  }

  _renderTitel() {
    return html`<a href="${this.link}">
      <isceco-title size="medium"
                    text="${this.i18n.translate('geschaeftsvorfall.uebersicht.marken.title')}"></isceco-title>
    </a>`
  }

  get link() {
    const baseUrl = location.origin + location.pathname + location.search
    return `${baseUrl}#${geschaeftsVorfallNavigationLinks.betrieb.hash}/${this.gesuchId}`
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Uebersicht/MarkenUebersicht/i18n.json'
  }
}
customElements.define('marken-uebersicht', MarkenUebersicht)
