import GenericColumn from '@isceco/widget-library2/basic-elements/List/column/GenericColumn'
import DateColumn from '@isceco/widget-library2/basic-elements/List/column/DateColumn'
import StringColumn from '@isceco/widget-library2/basic-elements/List/column/StringColumn'
import DefaultActionCell from '../../../common/list/DefaultActionCell'
import CheckboxCell from "@isceco/widget-library2/basic-elements/List/cell/CheckboxCell";

export default class PendenzColumns {

  static EINGANGSDATUM_COLUMN() {
    return new DateColumn({
      key: 'eingangsdatum',
      text: 'geschaeftsvorfall.pendenzen.eingangsdatum',
      weight: 2
    })
  }

  static BETREFF_COLUMN() {
    return new StringColumn({
      key: 'betreff',
      text: 'geschaeftsvorfall.pendenzen.betreff',
      weight: 2
    })
  }

  static SENDER_COLUMN() {
    return new StringColumn({
      key: 'sender',
      text: 'geschaeftsvorfall.pendenzen.sender',
      weight: 2
    })
  }

  static EMPFAENGER_COLUMN() {
    return new StringColumn({
      key: 'empfaenger',
      text: 'geschaeftsvorfall.pendenzen.empfaenger',
      weight: 2
    })
  }

  static ERLEDIGT_AM_COLUMN() {
    return new DateColumn({
      key: 'erledigtAm',
      text: 'geschaeftsvorfall.pendenzen.erledigt_am',
      weight: 2
    })
  }

  static CONTAIN_DOCS_COLUMN() {
    return new GenericColumn({
      key: 'containDocs',
      text: 'geschaeftsvorfall.pendenzen.contain.docs',
      weight: 1,
      cell: new CheckboxCell({
        disabled: _ => true
      }),
    })
  }

  static AKTIONEN_COLUMN(configuration) {
    const {callback} = configuration
    return new GenericColumn({
      key: 'aktionen',
      text: '',
      weight: 0.5,
      align: 'right',
      cell: new DefaultActionCell({
        callback: callback,
        buttons: i18n => {
          const buttons = []

          buttons.push({
            action: 'edit',
            icon: 'clipboard',
            variant: 'primary',
            title: i18n.translate('geschaeftsvorfall.pendenzen.actions.edit')
          })

          return buttons
        }
      })
    })
  }

}
