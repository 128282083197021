import {css} from '@isceco/widget-library2/external/lit';

export default css`
  nav {
    font-family: var(--isceco-font);
  }

  a.workflow {
    border-radius:20px;
    background-color: var(--isceco-color-white-500);
    display: block;
    padding: 14px;
    cursor: pointer;
    border-left: 3px solid transparent;
  }

  a.betrieb {
    border-radius: 20px 0px 0px 0px;
    background-color: var(--isceco-color-white-500);
    display: block;
    padding: 14px;
    cursor: pointer;
    border-left: 3px solid transparent;
  }

  a.red {
    background: linear-gradient(to right,#e779794d, #F6F6F6);
  }

  a.orange {
    background: linear-gradient(to right,#f5e4bdd6, #F6F6F6);
  }

  a.green {
    background: linear-gradient(to right,#98f99859, #F6F6F6);
  }

  .desktop a, .mobile a {
   filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.12));
  }

  .mobile, .mobile > div {
    display: none;
  }

  .hamburger-menu {
    display: none !important;
  }

  .desktop a:hover:not(.active):not(.disabled) {
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0));
    border-left: 3px solid var(--isceco-color-red-500);
  }

  .desktop a.active {
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0));
    background-color: var(--isceco-color-background);
    border-left: 3px solid var(--isceco-color-red-500);
    font-weight: bold;
  }

  .mobile a:hover:not(.active) {
    border-left: 3px solid var(--isceco-color-red-500);
  }

  .mobile a.active {
    border-left: 3px solid var(--isceco-color-red-500);
    background-color: var(--isceco-color-background);
    font-weight: bold;
  }

  a.disabled {
    background-color: var(--isceco-color-grey-100);
    background-image: none;
    cursor: not-allowed;
  }

  .hamburger-menu {
    border: none;
    cursor: pointer;
    background: transparent;
    padding: var(--isceco-space-200);
    color: var(--isceco-color-grey-900);
  }

  .hamburger-menu i {
    font-size: 1.25rem;
  }

  @media (max-width: 991.98px) {
    .desktop {
      display: none;
    }

    .hamburger-menu {
      display: block !important;
    }

    .mobile {
      display: block;
    }

    .show, .show a {
      display: block !important;
    }

    a {
      border-bottom: 2px solid var(--isceco-color-background);
      padding: var(--isceco-space-200);
    }
  }
`
