import GenericColumn from '@isceco/widget-library2/basic-elements/List/column/GenericColumn.js'
import StringColumn from '@isceco/widget-library2/basic-elements/List/column/StringColumn.js'
import DefaultActionCell from '../../../common/list/DefaultActionCell.js'
import PhoneCell from '../../../common/PhoneCell.js'
import EmailCell from '../../../common/EmailCell.js'

export default class CockpitStatistikdatenColumns {

  static BETRIEB_COLUMN() {
    return new StringColumn({
      key: 'firmenbezeichnung',
      text: 'cockpit.statistikdaten.betrieb',
      sortable: false
    })
  }

  static STRASSE_COLUMN() {
    return new StringColumn({
      key: 'strasse',
      text: 'cockpit.statistikdaten.strasse',
      sortable: false
    })
  }

  static PLZ_COLUMN() {
    return new StringColumn({
      key: 'plz',
      text: 'cockpit.statistikdaten.plz',
      sortable: false,
      weight: 0.5
    })
  }

  static ORT_COLUMN() {
    return new StringColumn({
      key: 'ort',
      text: 'cockpit.statistikdaten.ort',
      sortable: false
    })
  }

  static TEL_COLUMN() {
    return new GenericColumn({
      key: 'telefon',
      text: 'cockpit.statistikdaten.tel',
      sortable: false,
      cell: new PhoneCell()
    })
  }

  static EMAIL_COLUMN() {
    return new GenericColumn({
      key: 'email',
      text: 'cockpit.statistikdaten.email',
      sortable: false,
      cell: new EmailCell()
    })
  }

  static AKTIONEN_COLUMN(configuration) {
    return new GenericColumn({
      key: 'aktionen',
      text: '',
      sortable: false,
      filter: null,
      weight: 0.5,
      cell: new DefaultActionCell({
        callback: configuration.callback,
        buttons: i18n => [
          {
            action: 'detail',
            icon: 'clipboard',
            variant: 'primary',
            title: i18n.translate('cockpit.statistikdaten.list.actions.detail'),
            size: 'small'
          }
        ]
      })
    })
  }
}
