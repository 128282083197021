import WebComponent from '../../../WebComponent'
import {html} from '@isceco/widget-library2/external/lit'

export default class ErhebungFreigeben extends WebComponent {
  get translationFile() {
    return './views/Statistikdaten/ErhebungFreigeben/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  getTemplate() {
    return html`
      <h1>${this.i18n.translate("statistikdaten.erhebung.freigeben.title")}</h1>
    `
  }
}

customElements.define('vzavg-statistikdaten-erhebung-freigeben', ErhebungFreigeben)
