import RechtService from './services/RechtService.js'
import AdressverzeichnisService from './services/AdressverzeichnisService.js'

fetch('./config/config.json', {cache: 'reload'})
  .then(res => res.json())
  .then(config => {
    import(config.ssoKeycloakAdapter/* @vite-ignore */)
      .then(() => {
        window.keycloak = new Keycloak({
          url: config.ssoUrl,
          realm: config.ssoRealm,
          clientId: config.ssoClient
        })
        window.keycloak.init({
          onLoad: 'login-required',
          promiseType: 'native',
          checkLoginIframe: false,
          pkceMethod: 'S256'
        }).then(_ => {
          new RechtService().getByEmail(keycloak.tokenParsed.email)
            .then(response => {
              if (response !== undefined && response['id']) {
                sessionStorage.setItem('userRechtId', response.id)
                sessionStorage.setItem('userRechtCodeKanton', response.codeKanton)
              }
              document.querySelector('body').innerHTML = `
                <main>
                  <vzavg-frontend-header></vzavg-frontend-header>
                  <vzavg-frontend-horizontal-navigation id="navigation"></vzavg-frontend-horizontal-navigation>
                  <vzavg-frontend-message id="messagecontent"></vzavg-frontend-message>
                  <vzavg-frontend-main-view id="maincontent" style="overflow-y: auto"></vzavg-frontend-main-view>
                  <vzavg-frontend-footer></vzavg-frontend-footer>
                </main>
            `
          })
        }).then(_ =>{
          // cache data from backend
          new AdressverzeichnisService() // NOSONAR
        })
        // used for Global Logout context
        window.keycloak.onTokenExpired = () => {
          keycloak.updateToken(-1)
            .catch(function () {
              console.error('Failed to refresh the token, or the session has expired'); // NOSONAR
            })
        };
      })
  })
