import {html} from '@isceco/widget-library2/external/lit'
import VzavgFormation from '../../common/VzavgFormation'

export default class UidCell {
  render(i18n, cell) {
    return html`
      <span>${VzavgFormation.formatUID(cell)}</span>
    `
  }
}
