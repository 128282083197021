import BaseService from '../BaseService.js'

export default class DokumentService extends BaseService {

  constructor(gesuchId, kategorie) {
    super('gesuch/'+gesuchId+'/dokument/'+kategorie)
  }

  setRelativeBase(url) {
    this.relativeBase = url
  }

  setUrl(url) {
    this.url = url
  }

  async validationConfig() {
    await this.getBackendListUrl().then(url => this.serviceurl = url)
    return this.checkError(
      fetch(`${this.serviceurl}/validation-config`, {
        method: 'GET',
        cache: 'reload',
        headers: this.getJsonHeader()
      })
    )
  }

  async download(downloadUrl, filename) {
    return fetch(downloadUrl, {
      method: 'GET',
      cache: 'reload',
      headers: this.getJsonHeader()
    })
      .then(response => response.blob())
      .then(blob => download(filename, blob))
  }

  async getFile(downloadUrl, filename) {
    return fetch(downloadUrl, {
      method: 'GET',
      cache: 'reload',
      headers: this.getJsonHeader()
    }).then(response => response.blob())
  }

}
