import BaseService from '../BaseService.js'

export default class RechtService extends BaseService {

  constructor() {
    super('recht')
  }

  async getByEmail(email) {
    await this.retrieveBackendUrl();
    return this.checkError(
      fetch(`${this.backendUrl}/email/${email}`, {
        method: 'GET',
        cache: 'reload',
        headers: this.getJsonHeader()
      })
    )
  }

}
