export default class ApplicationException {
  constructor(state) {
    this._state = null
    this.state = state
  }

  get state() {
    return this._state
  }

  set state(state) {
    this._state = state
  }
}
