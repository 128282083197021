import {css, html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Title/Title.js'
import TranslationElement from '../../../../common/TranslationElement.js'
import {geschaeftsVorfallNavigationLinks} from '../../Geschaeftsvorfall.js'

export default class SonderpreisUebersicht extends TranslationElement {
  static get properties() {
    return {
      gesuchId: {type: String},
      value: {type: String}
    }
  }

  constructor() {
    super()
    this.value = 'no'
  }

  render() {
    return html`
      ${this._renderTitle()}
      <p>${this.i18n.translate(this.value ? this.value : 'no')}</p>
    `
  }

  static get styles() {
    return css`
      a {
        cursor: pointer;
      }
    `
  }

  _renderTitle() {
    return html`
      <a href="${this.link}">
        <isceco-title
          size="medium"
          text="${this.i18n.translate('geschaeftsvorfall.uebersicht.sonderpreis.title')}"
        ></isceco-title>
      </a>
    `
  }

  get link() {
    const baseUrl = location.origin + location.pathname + location.search
    return `${baseUrl}#${geschaeftsVorfallNavigationLinks.entscheid.hash}/${this.gesuchId}`
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Uebersicht/SonderpreisUebersicht/i18n.json'
  }
}
customElements.define('sonderpeis-uebersicht', SonderpreisUebersicht)
