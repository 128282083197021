import {css, html} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../../WebComponent.js'
import './StatistikdatenAktuell'
import './StatistikdatenVorjahre'


export default class Statistikdaten extends WebComponent {

  static TABS = {
    AKTUELL: 'aktuell',
    VORJAHRE: 'vorjahre'
  }

  constructor() {
    super()
    this.activeTab = Statistikdaten.TABS.AKTUELL
  }

  get css() {
    return css`
      .tab-button-wrapper {
        display: flex;
      }
    `
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Statistikdaten/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    this.gesuchId = getNavigationId()
    this.render()
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  getTemplate() {
    switch (this.activeTab) {
      case Statistikdaten.TABS.AKTUELL:
        return this._renderStatistikdatenAktuell()
      case Statistikdaten.TABS.VORJAHRE:
        return this._renderStatistikdatenVorjahre()
      default:
        return this._renderStatistikdatenAktuell
    }
  }

  _renderStatistikdatenAktuell() {
    return html`
      ${this._renderTabSwitcher()}
      <vzavg-frontend-geschaeftsvorfall-statistikdaten-aktuell></vzavg-frontend-geschaeftsvorfall-statistikdaten-aktuell>
    `
  }

  _renderStatistikdatenVorjahre() {
    return html`
      ${this._renderTabSwitcher()}
      <vzavg-frontend-geschaeftsvorfall-statistikdaten-vorjahre></vzavg-frontend-geschaeftsvorfall-statistikdaten-vorjahre>
    `
  }

  _renderTabSwitcher() {
    return html`
      <div class="tab-button-wrapper">
        <isceco-button text="${this.i18n.translate('geschaeftsvorfall.statistikdaten.tab.aktuell')}"
                       variant=${Statistikdaten.TABS.AKTUELL === this.activeTab ? "primary" : "secondary"} size="small"
                       @click="${_ => this._switchTab(Statistikdaten.TABS.AKTUELL)}">
        </isceco-button>
        <isceco-button text="${this.i18n.translate('geschaeftsvorfall.statistikdaten.tab.vorjahre')}"
                       variant=${Statistikdaten.TABS.VORJAHRE === this.activeTab ? "primary" : "secondary"} size="small"
                       @click="${_ => this._switchTab(Statistikdaten.TABS.VORJAHRE)}">
        </isceco-button>
      </div>
    `
  }

  _switchTab = name => {
    this.activeTab = name
    this.render()
  }

}
customElements.define('vzavg-frontend-geschaeftsvorfall-statistikdaten', Statistikdaten)
