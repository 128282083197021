import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Popup/Popup.js'


export default class StatistikMaxDeviationInPercentCell {

  render(i18n, cell, row) {
    if (cell != null && row.maxDeviationInPercent != null) {
      if (row.maxDeviationInPercent === 0) {
        return html`
            <span>${i18n.translate('statistikdaten.freigeben.column.maxDeviationInPercent.noDeviation')}</span>
        `
      }
      if (row.maxDeviationInNumbers > 0) {
        return html`
            <span>${cell} %</span>
        `
      } else {
        return html`
            <span>- ${cell} %</span>
        `
      }

    }
    return html``
  }

}
