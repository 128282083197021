import {LitElement, html, css} from '@isceco/widget-library2/external/lit';

class PersonBetriebInfo extends LitElement {
  static get styles() {
    return css`
      .icon.exclamation {
        color: var(--isceco-color-yellow-500)
      }
    `
  }

  /**
   * @typedef Company
   * @property {String} uid
   * @property {String} firmenbezeichnung
   * @property {Number} gesuchId
   * @property {Number} verantwPersonId
   */

  static get properties() {
    return {
      i18n: {type: Object},
      /**@type {Company[]}**/
      companies: {type: Array},
      verantwortlichePersonId: {type: Number},
      verantwortlichePersonUrl: {type: String}
    }
  }

  constructor() {
    super()
  }

  connectedCallback() {
    super.connectedCallback()
  }

  updated(changedProperties) {
    if (changedProperties.has('companies')) {
      this.render();
    }
  }

  render() {
    return html`
      <style>@import '${iscecoWidgetLibrary.iconCss}'</style>
      <p><i class="exclamation triangle icon"></i> ${this.i18n?.translate('geschaeftsvorfall.verantwortliche.person.other.betrieb')} ${this._renderLinks()}</p>
    `
  }

  _renderLinks() {
    return html`
      ${this.companies?.map(c => {
        return html`<a
                href="${this._getLink(c.gesuchId, c.verantwPersonId)}"
                target="_blank">${c.firmenbezeichnung} (${c.uid}) <i
                className="icon external alternate"></i></a> `

      })}`
  }

  _getLink(gesuchId, verantwPersonId) {
    const baseUrl = location.origin + location.pathname + location.search
    return `${baseUrl}#${this.verantwortlichePersonUrl}/${gesuchId}/${verantwPersonId}`
  }
}

customElements.define('vzavg-person-betrieb-info', PersonBetriebInfo);
