import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/DateInput/DateInput.js'
import VzavgFormation from '../../../common/VzavgFormation.js'

export default class VerantwortlichePersonTaetigBisCell {

  constructor(configuration) {
    this.personObject = configuration.personObject;
    this.hasWriteAccess = configuration.hasWriteAccess
  }

  render(i18n, cell, row) {
    // the isceco-date-input issues the change event for invalid values
    // to have the same behavior as for Adresse but without warnings
    // we use the warning components without warning limits
    return html`
      <vzavg-date-input-with-warning
        id="taetigBis"
        name="taetigBis"
        value="${row.taetigBis}"
        .min="${this._updateBisMinDate(row.taetigVon)}"
        @change="${e => {this.personObject.updateTaetigBisDate(row, e.target.value === '' ? null : e.target.value)}}"
        ?readonly="${!this.hasWriteAccess}"
      ></vzavg-date-input-with-warning>
    `
  }

  _updateBisMinDate(vonDatum) {
    const bisDatum = new Date(vonDatum)
    bisDatum.setDate(bisDatum.getDate() + 1);
    return VzavgFormation.formatDateToYYYYMMDD(bisDatum)
  }

}
