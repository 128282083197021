import {html} from '@isceco/widget-library2/external/lit'
import DateInput from '@isceco/widget-library2/basic-elements/DateInput/DateInput.js'
import I18n from '../../i18n.js'
import ExceptionService from '../../ExceptionService.js'

/**
 * Komponente extended den normalen Datepicker.
 * Man kann ein Warnungs Min und Max Datum mitgeben, wenn das Datum kleiner oder grösser ist als das Min/Max Datum
 * Der Text für die Warnung kann der Anwender mitgeben
 */
export default class DateInputWithWarning extends DateInput {

  constructor() {
    super();
    this.i18n = new I18n()
    this.logging = new ExceptionService()
    this.translationLoaded = false;
    this.reloadListener = _ => {
      this.render()
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.warningDescription = ''
    this.confirmedValue = this.value
    this.translationLoaded = this.i18n.loadConfiguration('./components/DateInputWithWarning/i18n.json')
      .then(i18n => {
        document.addEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this.reloadListener)
        this.requestUpdate()
        return i18n
      })
      .catch(error => {
        this.logging.log(error)
      })
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    document.removeEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this.reloadListener)
  }

  get properties() {
    return {
      ...DateInput.properties,
      value: {type: String, hasChanged: (oldValue, newValue) => {
          this.confirmedValue = newValue
          return true;
        }},
      warningMinDate: {type: Date},
      warningMaxDate: {type: Date},
      warningMaxDescription: {type: String},
      warningMinDescription: {type: String}
    }
  }

  render() {
      return html`
      ${super.render()}
      <isceco-dialog
        id="confirmDateDialog"
        hidden
        header="${this.warningTitle}"
        description="${this.warningDescription}"
        confirm-button="${this.i18n.translate('date.input.with.warning.confirm')}"
        cancel-button="${this.i18n.translate('date.input.with.warning.cancel')}"
        @submit="${e => this._confirmDateDialog(e)}"
      ></isceco-dialog>
    `
  }

  _change = (eventName, event) => {
    if (eventName === 'change') {
      this.value = event.target.value;
      const date = new Date(this.value)
      const valid = this.value === '' || this.validate();
      //Returns when date is not fully entered
      if(date.getFullYear() < 1000 || !valid) {
        return;
      }
      event.preventDefault();
      event.stopPropagation();
      this._checkDate(date)
    }
  }

  _confirmDateDialog(e) {
    if (e.detail.confirmed) {
      send('change', this.value, this)
      this.valueChanged = true
      this.confirmedValue = this.value
    } else {
      this.value = this.confirmedValue;
      this.render();
    }
    e.target.setAttribute('hidden', '')
  }

  _checkDate(date) {
    if (this.warningMaxDate?.getTime() < date.getTime()) {
      this._showWarningDialog(this.warningMaxDescription)
      return
    } else if(this.warningMinDate?.getTime() > date.getTime()) {
      this._showWarningDialog(this.warningMinDescription)
      return
    }
    send('change', this.value, this)
    this.confirmedValue = this.value
    this.valueChanged = true
  }

  _showWarningDialog(description) {
    const showDialog = () => {
      this.requestUpdate()
      const dialog = this.shadowRoot.querySelector('#confirmDateDialog')
      dialog.removeAttribute('hidden')
    }
    this.warningDescription = description ;
    showDialog();
  }


}

customElements.define('vzavg-date-input-with-warning', DateInputWithWarning);
