import {html, LitElement} from '@isceco/widget-library2/external/lit'
import StatusIconCss from './StatusIconCss.js'

export default class StatusIcon extends LitElement {

  static get styles() {
    return [StatusIconCss]
  }

  static get properties() {
    return {
      isValid: {type: Boolean}
    }
  }

  constructor() {
    super()
  }

  render() {
    return html`
      <style>@import '${iscecoWidgetLibrary.iconCss}';</style>
      <i class="status icon ${this.isValid ? 'check' : 'x'}"></i>
    `
  }

}
customElements.define('vzavg-status', StatusIcon)
