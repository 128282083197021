
export default class VerantwortlichePersonHelper {

  static getComboboxItems(verantwortlichePersonen) {
    const items = []
    if(verantwortlichePersonen.totalCount > 0) {
      verantwortlichePersonen.json.map((person, index) =>
        items[index] = {value: person.id, name: `${person.name} ${person.vorname}`}
      )
    }
    return items
  }

}

