import ExceptionService from '../ExceptionService.js'

export default class ConfigUtil {
  constructor() {
    this.logging = new ExceptionService()
  }

  updateToken() {
    return this.retrieveConfiguration()
      .then(config => keycloak.updateToken(config.tokenMinValidity))
      .catch(error => this.logging.log(error))
  }

  retrieveConfiguration() {
    return fetch('./config/config.json', {cache: 'reload'})
      .then(res => res.json())
  }
}
