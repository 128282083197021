import GenericColumn from '@isceco/widget-library2/basic-elements/List/column/GenericColumn'
import StringCell from '@isceco/widget-library2/basic-elements/List/cell/StringCell'
import DateCell from '@isceco/widget-library2/basic-elements/List/cell/DateCell'
import DefaultActionCell from '../../../../common/list/DefaultActionCell'
import {geschaeftsVorfallNavigationLinks} from '../../../Geschaeftsvorfall/Geschaeftsvorfall'
import SelectCell from '@isceco/widget-library2/basic-elements/List/cell/SelectCell'

export default class VerantwortlichePSearchColumns {
  static getColumns() { //NOSONAR
    return [
      {
        column: new GenericColumn({
          key: 'codeKanton',
          text: 'suchen.result.column.kanton',
          sortable: true,
          cell: new StringCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },

      {
        column: new GenericColumn({
          key: 'firmenbezeichnung',
          text: 'suchen.result.column.firmenbezeichnung',
          sortable: true,
          cell: new StringCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },

      {
        column: new GenericColumn({
          key: 'strasse',
          text: 'suchen.result.column.strasse',
          sortable: true,
          cell: new StringCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },

      {
        column: new GenericColumn({
          key: 'ort',
          text: 'suchen.result.column.ort',
          sortable: true,
          cell: new StringCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },

      {
        column: new GenericColumn({
          key: 'status',
          text: 'suchen.result.verantwortlicheP.column.status',
          sortable: true,
          cell: new StringCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },

      {
        column: new GenericColumn({
          key: 'gender',
          text: 'suchen.result.verantwortlicheP.column.gender',
          sortable: true,
          cell: new StringCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: false
      },

      {
        column: new GenericColumn({
          key: 'name',
          text: 'suchen.result.verantwortlicheP.column.nachname',
          sortable: true,
          cell: new StringCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },

      {
        column: new GenericColumn({
          key: 'vorname',
          text: 'suchen.result.verantwortlicheP.column.vorname',
          sortable: true,
          cell: new StringCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },

      {
        column: new GenericColumn({
          key: 'gebDatum',
          text: 'suchen.result.verantwortlicheP.column.geburtsdatum',
          sortable: true,
          cell: new DateCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },

      {
        column: new GenericColumn({
          key: 'sozVersNr',
          text: 'suchen.result.verantwortlicheP.column.ahv',
          sortable: true,
          cell: new StringCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },

      {
        column: new GenericColumn({
          key: 'nationalitaet',
          text: 'suchen.result.verantwortlicheP.column.nationalitaet',
          sortable: true,
          cell: new StringCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },

      {
        column: new GenericColumn({
          key: 'hasAufenthaltsbewilligung',
          text: 'suchen.result.verantwortlicheP.column.aufenthaltsbewilligung',
          sortable: true,
          cell: new SelectCell({items: [{value: true, name: 'Ja'}, {value: false, name: 'Nein'}]})
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },

      {
        column: new GenericColumn({
          key: 'taetigVon',
          text: 'suchen.result.verantwortlicheP.column.taetigVon',
          sortable: true,
          cell: new DateCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },

      {
        column: new GenericColumn({
          key: 'taetigBis',
          text: 'suchen.result.verantwortlicheP.column.taetigBis',
          sortable: true,
          cell: new DateCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },

      {
        column: new GenericColumn({
          key: 'sperrdatumVon',
          text: 'suchen.result.verantwortlicheP.column.sperrdatumVon',
          sortable: true,
          cell: new DateCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },

      {
        column: new GenericColumn({
          key: 'sperrdatumBis',
          text: 'suchen.result.verantwortlicheP.column.sperrdatumBis',
          sortable: true,
          cell: new DateCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },

      {
        column: new GenericColumn({
          key: 'email',
          text: 'suchen.result.verantwortlicheP.column.email',
          sortable: true,
          cell: new StringCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },

      {
        column: new GenericColumn({
          key: 'aktionen',
          text: '',
          weight: 0.5,
          align: 'right',
          cell: new DefaultActionCell({
            callback: (row, action) => {
              if (action === 'edit') {
                navigate({to: geschaeftsVorfallNavigationLinks.verantwortlichePersonEdit.hash, id: `${row.gesuchId}/${row.id}`})
              }
            },
            buttons: (i18n, row) => {
              const buttons = []

              buttons.push(
                {
                  action: 'edit',
                  icon: 'clipboard',
                  variant: 'primary',
                  title: i18n.translate('suchen.result.betrieb.column.edit')
                }
              )

              return buttons
            }
          })
        }),
        alwaysDisplayed: true,
        defaultDisplayed: true
      }
    ]
  }
}
