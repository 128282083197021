import {css, html} from '@isceco/widget-library2/external/lit'
import TranslationElement from '../../../../common/TranslationElement'
import {geschaeftsVorfallNavigationLinks} from '../../Geschaeftsvorfall'

export default class BranchenUebersicht extends TranslationElement {

  static get properties() {
    return {
      branchen: {type: Array},
      gesuchId: {type: String}
    }
  }
  render() {
    if(!this.branchen?.length) {
      return html`
      ${this._renderTitel()}
        <span>${this.i18n.translate('geschaeftsvorfall.uebersicht.branchen.no.branchen')}</span>
    `
    }

    return html`
      ${this._renderTitel()}
      <ul>
        ${this.branchen?.map(branche => this._renderBranche(branche))}
      </ul>
    `
  }

  _renderBranche(branche) {
    return html`<li>${branche.bezeichnung}<li>`
  }

  static get styles() {
    return css`
      a {
        cursor: pointer;
      }
      ul {
        list-style: none;
        padding-left: 0
      }
    `
  }


  _renderTitel() {
    return html`<a href="${this.link}">
      <isceco-title size="medium"
                    text="${this.i18n.translate('geschaeftsvorfall.uebersicht.branchen.title')}"></isceco-title>
    </a>`
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Uebersicht/BranchenUebersicht/i18n.json'
  }

  get link() {
    const baseUrl = location.origin + location.pathname + location.search
    return `${baseUrl}#${geschaeftsVorfallNavigationLinks.gesuch.hash}/${this.gesuchId}`
  }
}
customElements.define('branchen-uebersicht', BranchenUebersicht)
