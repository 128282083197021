import BaseService from '../BaseService.js'

export default class CockpitService extends BaseService {

  constructor() {
    super('cockpit')
  }

  getNewsSummary() {
    return {
      nbNews: 1
    }
  }

  getPendenzenSummary() {
    return {
      'totalPendenzen': {
        'nb': 2,
        'oldest': '01.02.2021'
      },
      'myPendenzen': {
        'nb': 2,
        'oldest': '01.06.2021'
      },
      'anzahlPendenzen': {
        'nb': 0
      }
    }
  }

  getGesucheSummary() {
    return {
      'inBearbeitung': 18,
      'pendent': 3
    }
  }

  getKautionenSummary() {
    return {
      'depositExpirationLockoutIn5Days': 1,
      'depositExpirationIn60Days': 1
    }
  }
}

