import WebComponent from '../../../WebComponent.js'
import {css, html} from '@isceco/widget-library2/external/lit/index.js'
import I18n from '../../../i18n.js'

export default class SuchenNavigation extends WebComponent {

  constructor() {
    super()
    this.tiles = []
  }

  static get EVENT_KEYS() {
    return {
      SCOPE_CHANGED: 'search_scope_changed'
    }
  }

  get css() {
    return css`
      .tile-wrapper {
        display: flex;
        gap: 1em;
        align-items: center;
        margin-bottom: 1em;
        flex-wrap: wrap;
      }
    `
  }

  get translationFile() {
    return './views/Suchen/Navigation/i18n.json'
  }

  async connectedCallback() {
    super.connectedCallback()
    this.render()
    this._scopeChanged = () => {
      this.render()
    }
    window.addEventListener(SuchenNavigation.EVENT_KEYS.SCOPE_CHANGED, this._scopeChanged)
    this._refresh = () => {
      this.render()
    }
    document.addEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)
  }

  disconnectedCallback() {
    window.removeEventListener(SuchenNavigation.EVENT_KEYS.SCOPE_CHANGED, this._scopeChanged)
    window.removeEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)
  }

  getTemplate() {
    return html`
      <div class="tile-wrapper">
        ${this.tiles?.map(t => this._renderTile(t))}
      </div>
    `
  }

  /**
   * @param item {Tile}
   * @private
   */
  _renderTile(item) {
    const {titleKey, icon, disabled} = item
    return html`
      <isceco-button
        text="${this.i18n.translate(titleKey)}"
        icon="${icon}"
        ?disabled="${disabled}"
        variant="${getNavigationHash() === item.url ? 'primary' : 'secondary'}"
        @click="${() => this._navigate(item)}"></isceco-button>
    `
  }

  _navigate(item) {
    window.location.hash = item.url
    window.send(SuchenNavigation.EVENT_KEYS.SCOPE_CHANGED, item.scope)
  }


}
customElements.define('vzavg-frontend-suchen-navigation', SuchenNavigation)
