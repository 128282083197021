import {css, html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/List/List.js'
import DefaultMobileItem from '../../../components/DefaultMobileItem/DefaultMobileItem.js'
import NotizService from '../../../services/NotizService.js'
import WebComponent from '../../../WebComponent.js'
import AuthorizationFilter from '../../Authorization/AuthorizationFilter.js'
import {geschaeftsVorfallNavigationLinks} from '../Geschaeftsvorfall.js'
import NotizColumns from './NotizColumns.js'
import GeschaeftsvorfallNavigation from '../Navigation/GeschaeftsvorfallNavigation'

export default class Notiz extends WebComponent {

  static get EVENT_KEYS() {
    return {
      TRIGGER_NOTIZ_TABLE_RELOAD: 'geschaeftvorfall.notiz.table.reload.event'
    }
  }

  get css() {
    return css``
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Notiz/i18n.json'
  }

  async connectedCallback() {
    super.connectedCallback()
    this._initColumns()
    this.gesuchId = getNavigationId()
    this.notizService = new NotizService()
    this.notizListService = await this.notizService.getListService(this.gesuchId)
    this.render()
  }

  getTemplate() {
    return html`
      <isceco-title text="${this.i18n.translate('geschaeftsvorfall.notiz.header')}">
        <isceco-button
          id="notiz-add-btn"
          title="${this.i18n.translate('geschaeftsvorfall.notiz.button.create')}"
          icon="plus"
          variant="primary"
          size="small"
          @click="${_ => navigate({to: geschaeftsVorfallNavigationLinks.notizEdit.hash, id: this.gesuchId})}"
          ?disabled="${!AuthorizationFilter.hasUserWriteAccess()}"
        ></isceco-button>
      </isceco-title>

      ${this._getTemplateList()}
    `
  }

  _getTemplateList() {
    return html`
      <isceco-list
        .listService="${this.notizListService}"
        .columns="${this.columns}"
        .rowVariant="${this.rowVariant}"
        .i18n="${this.i18n}"
        .mobileView="${DefaultMobileItem.getDefaultRenderer(this.i18n)}"
        sort="lastModificationTime"
        order="desc"
        update-event="${Notiz.EVENT_KEYS.TRIGGER_NOTIZ_TABLE_RELOAD}"
      ></isceco-list>
    `
  }

  _initColumns() {
    this.columns = [
      NotizColumns.DATUM_COLUMN(),
      NotizColumns.TYP_COLUMN(),
      NotizColumns.KATEGORIE_COLUMN(),
      NotizColumns.TITEL_COLUMN(),
      NotizColumns.CONTAIN_DOCS_COLUMN(),
      NotizColumns.BENUTZER_COLUMN(),
      NotizColumns.AKTIONEN_COLUMN({callback: this._rowActionMenu})
    ]
  }

  _rowActionMenu = (row, action) => {
    if (action === 'edit') {
      navigate({to: geschaeftsVorfallNavigationLinks.notizEdit.hash, id: `${this.gesuchId}/${row.id}`})
    }
    if (action === 'delete') {
      this._deleteNotiz(row.id)
    }
  }

  _deleteNotiz(notizId) {
    this.notizService.deleteNotiz(this.gesuchId, notizId)
      .then(_ => {
        showAlert(
          this.i18n.translate('geschaeftsvorfall.notiz.delete.success.title'),
          this.i18n.translate('geschaeftsvorfall.notiz.delete.success.message'),
          'success'
        )
        send(Notiz.EVENT_KEYS.TRIGGER_NOTIZ_TABLE_RELOAD)
        send(GeschaeftsvorfallNavigation.EVENT_KEYS.RELOAD_STATUS_TRIGGERED)
      })
  }

}
customElements.define('vzavg-geschaeftsvorfall-notiz', Notiz)
