import {css, html} from '@isceco/widget-library2/external/lit'
import TranslationElement from '../../../../common/TranslationElement'
import {geschaeftsVorfallNavigationLinks} from '../../Geschaeftsvorfall'

export default class NotizenUebersicht extends TranslationElement {

  static get properties() {
    return {
      totalNotiz: {type: Number},
      gesuchId: {type: String}
    }
  }

  render() {
    return html`
      ${this._renderTitle()}
      <p>
        ${this.i18n.translate('notizen.anzahl')} :
        <a href="${this.link}">${this.totalNotiz}</a>
      </p>
    `
  }

  _renderTitle() {
    return html`
      <a href="${this.link}">
        <isceco-title
          size="medium"
          text="${this.i18n.translate('title')}"
        ></isceco-title>
      </a>
    `
  }

  static get styles() {
    return css`
      a {
        cursor: pointer;
      }
    `
  }

  get link() {
    const baseUrl = location.origin + location.pathname + location.search
    return `${baseUrl}#${geschaeftsVorfallNavigationLinks.notiz.hash}/${this.gesuchId}`
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Uebersicht/NotizenUebersicht/i18n.json'
  }
}
customElements.define('notizen-uebersicht', NotizenUebersicht)
