import {html, LitElement} from '@isceco/widget-library2/external/lit'
import DefaultMobileItemCss from './DefaultMobileItemCss.js'

export default class DefaultMobileItem extends LitElement {

  static KEY_AKTIONEN = 'aktionen'

  static get styles() {
    return [DefaultMobileItemCss]
  }

  static get properties() {
    return {
      columns: { type: Array },
      row: { type: Object },
      i18n: { type: Object }
    }
  }

  constructor() {
    super()
  }

  render() {
    const items = this._prepareItems()
    return html`
      <style>@import '${iscecoWidgetLibrary.iconCss}';</style>
      <div class="mobile-overview-item">
        <p>
          ${this._renderMobileItems(items)}
          ${this._renderMobileItemActions(items)}
        </p>
      </div>
    `
  }

  _prepareItems() {
    const items = []
    for (const column of this.columns) {
      const key = column.key
      const label = this.i18n.translate(column.text)
      const subtext = this._renderSubtext(column)
      const value = this.row[key]
      const content = column.renderCell(this.i18n, value, this.row)
      items.push({ key, label, subtext, value, content })
    }
    return items
  }

  _renderSubtext(column) {
    if (isEmpty(column.subtext)) {
      return ``;
    } else {
      const subtexts = column.subtext.map(item => $`${this.i18n.translate(item)}<br/>`);
      return `<i style='font-size: small;'>${subtexts}</i>`;
    }
  }

  _renderMobileItems(items) {
    return items
      .filter(item => item.key !== DefaultMobileItem.KEY_AKTIONEN)
      .filter(item => !isEmpty(item.content))
      .map(item => this._renderMobileItem(item))
  }

  _renderMobileItem(item) {
    return html`
      <div class="item">
        <slot name="${item.key}">
          <span class="title">
            <b class="label">${item.label}</b><br/>
            ${item.subtext}
          </span>
          <span>
            ${item.content}
          </span>
        </slot>
      </div>
    `
  }

  _renderMobileItemActions(items) {
    const actionItem = items.find(item => item.key === DefaultMobileItem.KEY_AKTIONEN)
    if (!actionItem) {
      return html``
    }
    return html`
      <hr>
      <div class="mobile-action-item">
        <slot name="${actionItem.key}">${actionItem.content}</slot>
      </div>
    `
  }

  static getDefaultRenderer(i18n) {
    return (instance, row) => {
      return html`
        <vzavg-default-mobile-item .columns="${instance.columns}"
                                    .row="${row}"
                                    .i18n="${i18n}"
        ></vzavg-default-mobile-item>
      `
    }
  }
}

customElements.define('vzavg-default-mobile-item', DefaultMobileItem)
