import GenericColumn from '@isceco/widget-library2/basic-elements/List/column/GenericColumn.js'
import StringCell from '@isceco/widget-library2/basic-elements/List/cell/StringCell.js'
import DefaultActionCell from '../../common/list/DefaultActionCell.js'
import StringColumn from '@isceco/widget-library2/basic-elements/List/column/StringColumn.js'
import UidCell from './UidCell'


export default class BetriebSearchItemColumns {

  static UID_COLUMN() {
    return new GenericColumn({
      key: 'uid',
      text: 'geschaeftsvorfall.dialog.betrieb.search.column.uid',
      sortable: false,
      weight: 2,
      cell:  new UidCell()
    })
  }

  static NAME_COLUMN() {
    return new GenericColumn({
      key: 'name',
      text: 'geschaeftsvorfall.dialog.betrieb.search.column.name',
      sortable: false,
      cell: new StringCell(),
      weight: 2
    })
  }

  static STREET_COLUMN() {
    return new GenericColumn({
      key: 'street',
      text: 'geschaeftsvorfall.dialog.betrieb.search.column.street',
      sortable: false,
      cell: new StringCell(),
      weight: 2
    })
  }

  static HOUSENUMBER_COLUMN() {
    return new GenericColumn({
      key: 'houseNumber',
      text: 'geschaeftsvorfall.dialog.betrieb.search.column.housenumber',
      sortable: false,
      cell: new StringCell(),
      weight: 1
    })
  }

  static ZIP_COLUMN() {
    return new GenericColumn({
      key: 'swissZipCode',
      text: 'geschaeftsvorfall.dialog.betrieb.search.column.zip',
      sortable: false,
      cell: new StringCell(),
      weight: 1
    })
  }

  static TOWN_COLUMN() {
    return new GenericColumn({
      key: 'town',
      text: 'geschaeftsvorfall.dialog.betrieb.search.column.town',
      sortable: false,
      cell: new StringCell(),
      weight: 2
    })
  }

  static KANTON_COLUMN() {
    return new StringColumn({
      key: 'kanton',
      text: 'geschaeftsvorfall.dialog.betrieb.search.column.kanton',
      sortable: false,
      weight: 1
    })
  }

  static AKTIONEN_COLUMN(configuration) {

    return new GenericColumn({
      key: 'aktionen',
      text: '',
      sortable: false,
      filter: undefined,
      weight: 1,
      align: 'right',
      cell: new DefaultActionCell({
        callback: configuration.callback,
        buttons: (i18n, row) => [
          {
            action: 'add',
            icon: 'plus',
            variant: 'primary',
            title: i18n.translate('burweb.search.actions.aufnehmen')
          }
        ]
      })
    })
  }
}
