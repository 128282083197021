import ListService from '@isceco/widget-library2/basic-elements/List/ListService'

export default class VzavgListService extends ListService{
  constructor(url) {
    super(url)
  }

  setUrl(url) {
    this.url = url
  }
}
