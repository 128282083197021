import WebComponent from '../../../WebComponent'
import {html} from '@isceco/widget-library2/external/lit'

export default class Konsolidierung extends WebComponent {
  get translationFile() {
    return './views/Statistikdaten/Konsolidierung/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  getTemplate() {
    return html`
      <h1>${this.i18n.translate("statistikdaten.konsolidierung.title")}</h1>
    `
  }
}

customElements.define('vzavg-statistikdaten-konsolidierung', Konsolidierung)
