import GenericColumn from '@isceco/widget-library2/basic-elements/List/column/GenericColumn'
import StringCell from '@isceco/widget-library2/basic-elements/List/cell/StringCell'
import GlobalSearchLinkCell from './GlobalSearchLinkCell'

export default class GlobalSearchColumns {

  static getColumns() {
    return [
      {
        column: new GenericColumn({
          key: 'typ',
          text: 'suchen.result.global.column.typ',
          sortable: false,
          cell: new StringCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },
      {
        column: new GenericColumn({
          key: 'treffer',
          text: 'suchen.result.column.treffer',
          sortable: false,
          cell: new StringCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },
      {
        column: new GenericColumn({
          key: 'firmenbezeichnung',
          text: 'suchen.result.column.betrieb',
          sortable: false,
          cell: new StringCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },
      {
        column: new GenericColumn({
          key: 'aktionen',
          text: '',
          weight: 0.5,
          align: 'right',
          cell: new GlobalSearchLinkCell(),
        }),
        alwaysDisplayed: true,
        defaultDisplayed: true
      }
    ]
  }

}

