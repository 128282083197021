import {css, html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Card/Card.js'
import '@isceco/widget-library2/basic-elements/FileInput/FileInput.js'
import '@isceco/widget-library2/basic-elements/Dropdown/Dropdown.js'
import '@isceco/widget-library2/basic-elements/Button/Button.js'
import I18n from '../../../i18n.js'
import WebComponent from '../../../WebComponent.js'
import {geschaeftsVorfallNavigationLinks} from '../Geschaeftsvorfall.js'
import GesuchService from '../../../services/GesuchService.js'
import DokumentService from '../../../services/DokumentService.js'
import VzavgFileInputService from '../../../services/VzavgFileInputService.js'
import DocumentDokIdUpload from '../../../components/DocumentUpload/DocumentDokIdUpload.js'
import VerantwortlichePersonService from '../../../services/VerantwortlichePersonService.js'
import VerantwortlichePersonHelper from '../VerantwortlichePerson/VerantwortlichePersonHelper.js'
import StammdatenService from '../../../services/StammdatenService.js'
import GeschaeftsvorfallNavigation from '../Navigation/GeschaeftsvorfallNavigation.js'
import AuthorizationFilter from "../../Authorization/AuthorizationFilter";


export default class DokumenteEdit extends WebComponent {

  static DOKUMENTE_EDIT_FORM_ID = 'dokumente-edit-form';

  static get EVENT_KEYS() {
    return {
      UPLOAD_COMPLETE: 'dokumente-dokument-upload-complete'
    }
  }

  constructor(isClosedForKanton, isClosedForSeco) {
    super()
    this.gesuchService = new GesuchService()
    this.verantwortlichePersonService = new VerantwortlichePersonService()
    this.isClosedForKanton = isClosedForKanton
    this.isClosedForSeco = isClosedForSeco
    this.backendData = {}
    this.currentVersion = 1
  }

  get css() {
    return css`
      .flex-container {
        display: flex;
        flex-wrap: wrap;
        column-gap: 20px;
        row-gap: 20px;
        min-width: 10em;
      }
      .flex-container-item {
        flex-grow: 1;
        flex-shrink: 0;
      }
      .file-content {
        margin: 10px 0px 0px 0px;
        min-height: 60px;
        border-bottom: lightgray solid 1px;
      }
      .file-content-mobile {
        margin: 10px 0px 0px 0px;
      }
    `
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Dokumente/i18n.json'
  }

  async connectedCallback() {
    super.connectedCallback()
    this.gesuchId = getNavigationId()
    this.dokumentId = this._getDokumentId()
    if (!this.dokumentId) {
      showAlert('Navigation url error', 'dokumentId in url not found', 'error')
      return
    }

    this._refresh = () => this._getDokumentAndRender()
    this._reload = () => this.reload()
    document.addEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)
    document.addEventListener(DocumentDokIdUpload.EVENT_KEYS.UPDATED, this._refresh)
    window.addEventListener('resize', this._reload)

    this.verantwortlichePersonService = await this.verantwortlichePersonService.getListService(this.gesuchId)

    // get Stammdaten and filter per dokId
    const dokumentIdStammdaten = await new StammdatenService().getDokumentId()
    this.dokumentIdsGenerell = dokumentIdStammdaten.filter(d => d.code.includes('GE')).map(d => ({name: d.bezeichnung, value: d.code}))
    this.dokumentIdsVermittlung = dokumentIdStammdaten.filter(d => d.code.includes('AV')).map(d => ({name: d.bezeichnung, value: d.code}))
    this.dokumentIdsVerleih = dokumentIdStammdaten.filter(d => d.code.includes('PV')).map(d => ({name: d.bezeichnung, value: d.code}))
    this.dokumentIdsVerantwortlicheP = dokumentIdStammdaten.filter(d => d.code.includes('VP')).map(d => ({name: d.bezeichnung, value: d.code}))

    this.dokIdsKanton = ['BE01', 'BE03', 'VE01', 'VE03', 'VE05']
    this.dokIdsSeco = ['BE02', 'BE04', 'VE02', 'VE04', 'VE06']
    this.dokumentIdsEntscheidKanton = dokumentIdStammdaten
      .filter(d => this.dokIdsKanton.includes(d.code)).map(d => ({name: d.bezeichnung, value: d.code}))
    this.dokumentIdsEntscheidSeco = dokumentIdStammdaten
      .filter(d => this.dokIdsSeco.includes(d.code)).map(d => ({name: d.bezeichnung, value: d.code}))

    this.dokumentService = new DokumentService(this.gesuchId, 'dokumente/file')
    Promise.all([
      this.verantwortlichePersonService.list(),
      this.dokumentService.validationConfig()
    ]).then(([verantwortlichePersonen, dokumentValidationConfig]) => {
      this.verantwortlichePersonen = verantwortlichePersonen
      this.vzavgFileInputServiceVerantwPerson = new VzavgFileInputService()
      this.validationConfig = dokumentValidationConfig;
      this.url = this.dokumentService.backendUrl;
      this._refresh()
    }).catch(_2 => this.reload());
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    document.removeEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)
    document.removeEventListener(DocumentDokIdUpload.EVENT_KEYS.UPDATED, this._refresh)
    window.removeEventListener('resize', this._reload)
  }

  _initBackendData() {
    this.backendData = {}
    this.backendData.items = []
  }

  getTemplate() {
    return html`
      <isceco-button
        id="dokument-back"
        variant="secondary"
        icon="arrow left"
        text="${this.i18n.translate('geschaeftsvorfall.dokument.back.to.list')}"
        @click="${_ => this._backToDokumenteList()}">
      </isceco-button>
      <isceco-title
        id="page-title"
        size="large"
        text="${this.i18n.translate('geschaeftsvorfall.dokument.create.title')}">
      </isceco-title>
      <div class="flex-container">
        <isceco-card class="flex-container-item" text="${this.i18n.translate('geschaeftsvorfall.dokument.box.generell')}">
          <vzavg-document-dokid-upload fileInputId="generell-dokument-upload"
            gesuchId="${this.gesuchId}"
            dokumentId="${this.dokumentId}"
            kategorie="dokumente"
            .fileInputService="${new VzavgFileInputService()}"
            .dokIdStammdaten="${this.dokumentIdsGenerell}"
            .dokumentItems="${this._getDokumentItems('GE', this.dokumentIdsGenerell)}"
            .i18n="${this.i18n}"
            .backendData="${this.backendData}"
            .readonly="${false}"
            .currentVersion="${this.currentVersion}"
          >
          </vzavg-document-dokid-upload>
        </isceco-card>
        <isceco-card class="flex-container-item" text="${this.i18n.translate('geschaeftsvorfall.dokument.box.vermittlung')}">
          <vzavg-document-dokid-upload fileInputId="vermittlung-dokument-upload"
            gesuchId="${this.gesuchId}"
            dokumentId="${this.dokumentId}"
            kategorie="dokumente"
            .fileInputService="${new VzavgFileInputService()}"
            .dokIdStammdaten="${this.dokumentIdsVermittlung}"
            .dokumentItems="${this._getDokumentItems('AV', this.dokumentIdsVermittlung)}"
            .i18n="${this.i18n}"
            .backendData="${this.backendData}"
            .readonly="${false}"
            .currentVersion="${this.currentVersion}"
          >
          </vzavg-document-dokid-upload>
        </isceco-card>
        <isceco-card class="flex-container-item" text="${this.i18n.translate('geschaeftsvorfall.dokument.box.verleih')}">
           <vzavg-document-dokid-upload fileInputId="verleih-dokument-upload"
            gesuchId="${this.gesuchId}"
            dokumentId="${this.dokumentId}"
            kategorie="dokumente"
            .fileInputService="${new VzavgFileInputService()}"
            .dokIdStammdaten="${this.dokumentIdsVerleih}"
            .dokumentItems="${this._getDokumentItems('PV', this.dokumentIdsVerleih)}"
            .i18n="${this.i18n}"
            .backendData="${this.backendData}"
            .readonly="${false}"
            .currentVersion="${this.currentVersion}"
           >
          </vzavg-document-dokid-upload>
        </isceco-card>
      </div>
      <isceco-card style="margin-top: 20px;" text="${this.i18n.translate('geschaeftsvorfall.dokument.box.verantwpersonen')}">
        ${this._getVerantwPersonBoxTemplate()}
      </isceco-card>
      <isceco-card style="margin-top: 20px;"  text="${this.i18n.translate('geschaeftsvorfall.dokument.box.bewilligung.verfuegung')}">
           <vzavg-document-dokid-upload fileInputId="bewilligung-verfuegung-dokument-upload"
            gesuchId="${this.gesuchId}"
            dokumentId="${this.dokumentId}"
            kategorie="entscheid"
            .fileInputService="${new VzavgFileInputService()}"
            .dokIdStammdaten="${AuthorizationFilter.hasKantonRole() ? this.dokumentIdsEntscheidKanton : this.dokumentIdsEntscheidSeco}"
            .dokumentItems="${this._getDokumentItemsForBewVerf(AuthorizationFilter.hasKantonRole() ? this.dokumentIdsEntscheidKanton : this.dokumentIdsEntscheidSeco)}"
            .i18n="${this.i18n}"
            .backendData="${this.backendDataEntscheid}"
            .readonly="${false}"
            .currentVersion="${this.currentVersion}">
        </vzavg-document-dokid-upload>
      </isceco-card>
    `
  }

  _getVerantwPersonBoxTemplate() {
    const dokumentItems = this._getDokumentItems('VP', this.dokumentIdsVerantwortlicheP)
    const personen = this.verantwortlichePersonen.totalCount > 0 ? this.verantwortlichePersonen.json.map(person => html`
          <div class="flex-container-item" style="margin-bottom: ${this._isMobileView() ? '30px;':'0px;'}">
            <b>${person.name} ${person.vorname}</b>
            ${dokumentItems.map(dokItem => html `
                ${this._getFileParagraphTemplate(dokItem, this._isMobileView(), person, this._fileExistForDokId(dokItem))}
            `)}
          </div>
      `) : html``

    return html`
      <div class="flex-container">
        <isceco-dropdown class="flex-container-item" id="verantw-person-combo" placeholder="---"
                         ?disabled="${false}"
                         @change="${e => this._activateFileInputAndSetUrl(e, 'verantwPerson-dokument-upload', this.vzavgFileInputServiceVerantwPerson)}"
                         label="Verantw. Person"
                         .items="${VerantwortlichePersonHelper.getComboboxItems(this.verantwortlichePersonen)}">
        </isceco-dropdown>
        <isceco-dropdown class="flex-container-item" id="verantw-person-dokid" placeholder="---" label="Dokument Typ"
                         ?disabled="${false}"
                         @change="${e => this._activateFileInputAndSetUrl(e, 'verantwPerson-dokument-upload', this.vzavgFileInputServiceVerantwPerson)}"
                         .items="${this.dokumentIdsVerantwortlicheP}">
        </isceco-dropdown>
         <isceco-file-input class="flex-container-item" id="verantwPerson-dokument-upload" label="Dokument" disabled
             .fileInputService="${this.vzavgFileInputServiceVerantwPerson}"
             label=""
             accept="${this.validationConfig.allowedExtensions}"
             maxsize="${this.validationConfig.maxSize}"o
             clear-event="${DokumenteEdit.EVENT_KEYS.UPLOAD_COMPLETE}"
             @change="${e => this._onDokumentUploaded(e)}">
         </isceco-file-input>
      </div>
      <br>
      <div style="display: flex; flex-wrap: ${this._isMobileView() ? 'wrap;':'nowrap;'} overflow-x: ${this._isMobileView() ? 'none;':'auto;'} overflow-y: hidden;">
        ${this._isMobileView() ? html `` : html `
            <div class="flex-container-item">
              <b style="color: white">Transparent</b>
              ${dokumentItems.map(dokItem => html `
                <div class="file-content">
                  <i style="color:white" class="check circle icon"></i>
                  ${dokItem.name}
                  ${this._fileExistForDokId(dokItem) ? html`<div style="height: 25px;"></div>`: ''}
                </div>
              `)}
            </div>
        `}
        ${personen}
      </div>
    `
  }

  _isMobileView() {
    return window.innerWidth < 991.98
  }

  _fileExistForDokId(dokItem) {
    return this.backendData.items && this.backendData.items.find(item => item.dokId === dokItem.value)
  }

  _getFileParagraphTemplate(dokItem, renderTitle, person, fileforDokIdExist) {
    const dokIdItem = this.backendData.items && this.backendData.items.find(item => item.dokId === dokItem.value && item.personId === person.id)
    return dokIdItem ? html`
        <div class="${this._isMobileView() ? 'file-content-mobile' : 'file-content'}">
          <i style="color:green" class="check circle icon"></i>
          ${renderTitle ? dokItem.name : ''}
          ${this._getFileContent(dokIdItem)}
        </div>
      ` : html`
        <div class="${this._isMobileView() ? 'file-content-mobile' : 'file-content'}">
          <i style="color:${dokItem.mandatory ? 'red' : 'orange'}" class="question circle icon"></i>
          ${renderTitle ? dokItem.name : ''}
          ${fileforDokIdExist && !renderTitle ? html`<div style="height: 0px;"></div>`: ''}
        </div>
    `
  }

  _getFileContent(dokIdItem) {
     return html`
      <div style="display: flex; margin: 5px 0px -10px 25px;">
        <i style="margin-top: 0px;" class="file icon"></i>
        <isceco-button variant="tertiary"
                       ?disabled="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
                       size="small"
                       text="${this._getShortName(dokIdItem.fileName)}"
        @click="${_ => this.dokumentService.download(dokIdItem.downloadPath, dokIdItem.fileName)}">
      </isceco-button>
      <isceco-button id="${this._getDeleteBtnId('this.backendData.fileName')}"
                     title="${this.i18n.translate('dokumente.delete.button')}"
                     ?disabled="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco) || dokIdItem.version !== this.currentVersion}"
                     variant="tertiary"
                     size="small"
                     icon="trash"
                   @click="${_ => this._onDeleteClick(dokIdItem.dokId, dokIdItem.version, dokIdItem.fileName, dokIdItem.personId)}">
        </isceco-button>
      </div>
    `
  }

  _getDokumentItemsForBewVerf(dokumentItemsStammdaten) {
    const ruleItemsGenerell = this.backendData.ruleItems ? this.backendData.ruleItems.filter(ri =>
      this.backendData.isKantonProcess ? this.dokIdsKanton.includes(ri.value) : this.dokIdsSeco.includes(ri.value)) : []
    const notMandatoryItems = dokumentItemsStammdaten.filter(dig =>
      ruleItemsGenerell.find(ruleItem => ruleItem.value === dig.value) === undefined
    )
    notMandatoryItems.forEach(item => ruleItemsGenerell.push({
      'value': item.value,
      'name': item.name,
      'mandatory': false
    }))
    return ruleItemsGenerell.sort((i1, i2) => i1.value.localeCompare(i2.value))
  }

  _getDokumentItems(dokIdPattern, dokumentItemsStammdaten) {
    const ruleItemsGenerell = this.backendData.ruleItems ? this.backendData.ruleItems.filter(ri => ri.value.includes(dokIdPattern)) : []
    const notMandatoryItems = dokumentItemsStammdaten.filter(dig =>
      ruleItemsGenerell.find(ruleItem => ruleItem.value === dig.value) === undefined
    )
    notMandatoryItems.forEach(item => ruleItemsGenerell.push({'value': item.value, 'name': item.name, 'mandatory': false}))
    return ruleItemsGenerell.sort((i1,i2) => i1.value.localeCompare(i2.value))
  }

  _getShortName(name) {
    const maxLength = 30;
    return name.length > maxLength ? name.substring(0, maxLength).concat('...') : name;
  }

  _getDeleteBtnId(name) {
    return 'btn-delete-'.concat(this._getShortName(name).split(' ').join('').replaceAll('.', ''))
  }

  _onDeleteClick(dokId, version, fileName, personId) {
    const subResource = personId === undefined ? `${version}/${fileName}` : `${personId}/${version}/${fileName}`
    this.dokumentService.delete(dokId, subResource).then(_ => {
      this._refresh()
    });
  }

  _activateFileInputAndSetUrl(e, idFileInput, service) {
    service.setUrl(this.url)
    if(e.detail.value !== '') {
      const personId = document.getElementById('verantw-person-combo').value
      const dokId = document.getElementById('verantw-person-dokid').value
      if(personId !== undefined && !isEmpty(personId) && dokId !== undefined && !isEmpty(dokId)) {
        document.getElementById(idFileInput).removeAttribute('disabled')
        service.setUrl(`${this.url}/${dokId}/${personId}`)
      }
    } else {
      document.getElementById(idFileInput).setAttribute('disabled','')
    }
  }

  _onDokumentUploaded(e) {
    if (e.detail.valid) {
      send(DokumenteEdit.EVENT_KEYS.UPLOAD_COMPLETE)
      this._getDokumentAndRender()
    }
  }

  async _getDokumentAndRender() {
    try {
      Promise.all([
        this.gesuchService.read(this.gesuchId, `dokument/dokumente/detail/${this.dokumentId}`),
        this.gesuchService.read(this.gesuchId, `dokument/entscheid/detail/${this.dokumentId}`)
      ]).then(([gesuchDokumenteResponse, gesuchDokumenteEntscheidResponse]) => {
        if (isNoDataFound(gesuchDokumenteResponse)) {
          this._initBackendData()
          this.render()
          return
        }

        // object exists already
        this.backendData = gesuchDokumenteResponse
        this.currentVersion = gesuchDokumenteResponse.version
        this.backendDataEntscheid = gesuchDokumenteEntscheidResponse

        send(GeschaeftsvorfallNavigation.EVENT_KEYS.STATUS_CHANGED, {
          target: 'Geschaeftsvorfall_Dokumente',
          status: this.backendData.status
        })
        this.render()
      })
    } catch (_) {
      showAlert('', this.i18n.translate('geschaeftsvorfall.dokument.edit.error'))
      this.render()
    }
  }

  _getDokumentId() {
    return window.location.hash.split('?')[0].split('/')[2]
  }

  _backToDokumenteList() {
    navigate({to: geschaeftsVorfallNavigationLinks.dokumente.hash, id: this.gesuchId})
  }

}
customElements.define('vzavg-dokumente-edit', DokumenteEdit)
