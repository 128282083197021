import {css} from '@isceco/widget-library2/external/lit'

export default css`
  :host {
    font-family: var(--isceco-font);
    font-weight: normal;
    display: block;
  }

  :host(.error) .field {
    outline: 2px solid var(--isceco-color-red-500);
  }

  :host(:not(.error)) #message {
    display: none;
  }

  :host(.changed) .field {
    background-color: var(--isceco-color-yellow-100);
  }

  #message {
    margin: 8px 0 0;
    font-weight: normal;
    color: var(--isceco-color-red-500);
    font-size: 0.875rem;
  }

  .label-wrapper {
    display: block;
    margin-bottom: 4px;
  }

  label {
    font-weight: bold;
    color: var(--isceco-color-black-500);
  }

  .inline {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .inline > .select-container {
    flex: 1;
    margin: 0;
  }

  .inline > .label-wrapper {
    margin-right: var(--isceco-space-200);
    margin-bottom: 0;
  }

  /* dropdown */

  .select-container {
    margin-top: 4px;
    position: relative;
  }

  .field:has(.filter:active), .field:has(.filter:focus), .field:has(.filter:focus-visible), .dropdown.open > .field {
    outline: 2px solid var(--isceco-color-blue-500);
    border-color: var(--isceco-color-blue-500);
  }

  .dropdown.disabled > .field {
    opacity: 0.5;
    cursor: not-allowed;
    border: 1px solid var(--isceco-color-grey-300);
  }

  .field {
    padding: 0 10px;
    min-height: 48px;
    border: 1px solid var(--isceco-color-grey-700);
    border-radius: 4px;
    background-color: var(--isceco-color-white-500);
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .field:hover {
    border-color: var(--isceco-color-blue-500)
  }

  .field > .field-content {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-items: start;
    align-items: center;
  }

  .field .placeholder {
    color: var(--isceco-color-grey-300)
  }

  .field .filter {
    flex: 1;
    min-width: 0;
    font: inherit;
    box-shadow: none;
    border: none;
    outline: none;
    background-color: transparent;
    margin: 4px;
    cursor: inherit;
  }

  .field .filter:disabled {
    display: none;
  }

  .field > .field-icon {
    color: var(--isceco-color-blue-500);
    transition: all .5s ease-in-out;
  }

  .field > .field-icon > i {
    margin: 0;
    font-size: 1.5rem;
  }

  .dropdown.open > .field > .field-icon {
    transform: scaleY(-1);
  }

  .tag {
    padding: 0 16px;
    margin: 3px;
    background-color: var(--isceco-color-blue-500);
    color: var(--isceco-color-white-500);
    height: fit-content;
    min-height: 36px;
    border-radius: 18px;
    display: inline-flex;
    align-items: center;
  }

  .tag > .tag-text {
    font-size: 0.875rem;
  }

  .tag > .tag-remove-button {
    margin-left: 16px;
    font-size: 1.25rem;
  }

  .tag > .tag-remove-button > i {
    margin: 0;
  }

  .dropdown.closed > .options {
    display: none;
  }

  .options > ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: fixed;
    overflow-y: auto;
    max-height: 300px;
    border-radius: 4px;
    background-color: var(--isceco-color-white-500);
    z-index: 90;
  }

  .options > ul > li {
    padding: 0 10px;
  }

  .options > ul > li.focus {
    cursor: pointer;
    background-color: var(--isceco-color-blue-100);
  }

  /* readonly */

  .readonly > .label-wrapper > span {
    color: var(--isceco-color-black-500);
    font-weight: bold;
  }

  .readonly > p {
    flex-grow: 1;
    color: var(--isceco-color-black-500);
    margin: 0;
    min-height: 48px;
    padding-top: 10px;
    box-sizing: border-box;
  }

  .readonly.inline > p {
    display: flex;
    align-items: center;
    padding-top: 0;
  }

  .group-member {
    margin-left: 1em;
  }
`
