import {html} from '@isceco/widget-library2/external/lit'

export default class DefaultActionCell {
  constructor(configuration) {
    this.callback = configuration.callback;
    this.buttons = configuration.buttons;
    this.actionMenuItems = configuration.actionMenuItems;
  }

  render(i18n, cell, row) {
    const mobile = window.innerWidth < 991.98
    return html`
      <style>
        .action-container {
          display: flex;
          justify-content: right;
        }

        .action-container .icon {
          font-size: 0.9em;
        }

        .action-button {
          padding: 1px 3px 0 3px !Important;
        }
      </style>
      <div class="action-container">
        ${this._renderButtons(mobile, i18n, row)}
        ${this._renderActionMenu(mobile, i18n, row)}
      </div>
    `
  }

  _renderButtons(mobile, i18n, row) {
    return this._resolveList(this.buttons, i18n, row)
      .map(button => html`
        <isceco-button title="${button.title}"
                       class="action-button"
                       icon="${button.icon}"
                       size="${mobile ? 'medium' : 'small'}"
                       variant="${button.variant}"
                       ?disabled="${button.disabled}"
                       @click="${e => this._action(row, button.action, e)}"
                       text="${button.text}"
        >
          ${button.text}
        </isceco-button>
      `)
  }

  _renderActionMenu(mobile, i18n, row) {
    const actionMenuItems = this._resolveList(this.actionMenuItems, i18n, row)
    if (actionMenuItems.length === 0) {
      return html``
    }

    const actionItems = actionMenuItems.map(item => {
      const {action, ...rest} = item
      return {
        ...rest,
        callback: () => this._action(row, item.action)
      }
    })

    return html`
      <isceco-action-menu direction="down-left" .items="${actionItems}">
        <isceco-button
          slot="wrapper"
          title="${i18n.translate('common.weitere.aktionen')}"
          class="action-button"
          icon="caret down"
          size="${mobile ? 'medium' : 'small'}"
        </isceco-button>
      </isceco-action-menu>
    `
  }

  _resolveList(input, i18n, row) {
    if (typeof input === 'function') {
      return input(i18n, row)
    }
    if (Array.isArray(input)) {
      return input
    }
    return []
  }

  _action(row, action, event) {
    const handler = typeof action === 'function' ? action : this.callback
    handler(row, action, event)
  }
}
