import BaseService from '../BaseService'

export default class SucheTemplateService extends BaseService {
  constructor() {
    super('search/template')
  }

  async readSucheTemplates() {
    await this.retrieveBackendUrl();
    return this.checkError(
      fetch(`${this.backendUrl}`, {
        method: 'GET',
        cache: 'reload',
        headers: {
          'Authorization': 'Bearer ' + window.keycloak.token
        }
      })
    )
  }
}
