import {html} from '@isceco/widget-library2/external/lit'


export default class DokumentIOCell {

  constructor(configuration) {
    this.i18n = configuration.i18n
  }

  render(i18n, cell, row) {
    return html`
        ${row.isOutputFile ? this.i18n.translate('geschaeftsvorfall.dokumente.list.io.out') :
          this.i18n.translate('geschaeftsvorfall.dokumente.list.io.in')}
      `
  }
}
