import {css, html, LitElement} from '@isceco/widget-library2/external/lit'
import I18n from '../../i18n'
import CheckListDialog from '../CheckListDialog/CheckListDialog.js';
import AuthorizationFilter from '../../views/Authorization/AuthorizationFilter';


export default class CheckList extends LitElement {
  static DIALOG_ID = 'checklist-dialog';

  static get EVENT_KEYS() {
    return {
      RELOAD_TRIGGERED: 'checklist-button-reload-triggered'
    }
  }

  static get properties() {
    return {
      /**@type {('seco' | 'kanton')} **/
      type: {type: String},
      items: {type: Array},
      isChecklistOverridden: {type: Boolean},
      confirmCallback: {type: Function}
    }
  }

  static get styles() {
    return css`
      .red {
        background: linear-gradient(to right, #e779794d, #F6F6F6);
      }

      .orange {
        background: linear-gradient(to right, #f5e4bdd6, #F6F6F6);
      }

      .green {
        background: linear-gradient(to right, #98f99859, #F6F6F6);
      }

      .white {
        background: linear-gradient(to right, #FFFFFF, #F6F6F6);
      }
    `
  }

  set disabled(disabled) {
    const oldValue = this._disabled
    const isKantonButDifferent = () => !AuthorizationFilter.isUserFromSameKanton() && !AuthorizationFilter.hasSecoRole()

    this._disabled = this.items?.checks?.length === 0 || (this.type === 'kanton' ? isKantonButDifferent() : false) || disabled
    this.requestUpdate('disabled', oldValue);
  }

  get disabled() {
    return this._disabled
  }

  constructor() {
    super()
    this.items = []
    this.checks = []
    this.i18n = new I18n();
    this._disabled = false;
    this.disabled = false;
  }

  connectedCallback() {
    super.connectedCallback()
    this.translationLoaded = this.i18n.loadConfiguration('./components/CheckList/i18n.json')
      .then(i18n => {
        document.addEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this.reloadListener)
        this.requestUpdate()
        return i18n
      })
      .catch(error => {
        this.logging.log(error)
      })

    this._refresh = () => {
      this.requestUpdate()
    }

    document.addEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)
    document.addEventListener(CheckList.EVENT_KEYS.RELOAD_TRIGGERED, this._refresh)
  }

  disconnectedCallback() {
    super.disconnectedCallback()

    document.removeEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)
    document.removeEventListener(CheckList.EVENT_KEYS.RELOAD_TRIGGERED, this._refresh)
  }

  render() {
    return html`
      ${this._renderButton()}
      ${this._renderDialog()}
    `
  }

  _renderButton() {
    const hasNoItems = !this.items || this.items.length === 0;
    const secoChecklistWithoutSecoRole = this.type === 'seco' && !AuthorizationFilter.hasSecoRole()
    const kantonChecklistWithKantonRoleButDifferentKanton = (this.type === 'kanton' && AuthorizationFilter.hasKantonRole() && !AuthorizationFilter.isUserFromSameKanton())
    const noBewilligungspflicht = window.betrieb$?.getValue()?.bewilligungspflicht === 'no'
    if (hasNoItems || secoChecklistWithoutSecoRole || kantonChecklistWithKantonRoleButDifferentKanton || noBewilligungspflicht) {
      return html``
    }
    return html`
      <isceco-button
        id="buttonCheckliste"
        text="${this.i18n.translate('checklist.button.' + this.type)}"
        icon="list"
        variant="tertiary"
        style="border-style: solid; border-color: white;"
        class="${this._getCheckListStatus()}"
        .disabled="${this.disabled}"
        @click="${_ => this._openDialog()}"
      ></isceco-button>
    `
  }

  _renderDialog() {
    return html`
      <vzavg-checklist-dialog
        id="checklist-dialog"
        .i18n="${this.i18n}"
        .items="${this.items}"
        type="${this.type}"
        .confirmCallback="${dialogChecklist => this._mapChecks(dialogChecklist)}"
        ?readonly="${this.isChecklistOverridden}"
      ></vzavg-checklist-dialog>
    `
  }

  _mapChecks(dialogChecklist) {
    for (const dialogCheck of dialogChecklist) {
      for (const check of this.items) {
        if (dialogCheck.priority === check.priority) {
          check.isChecked = dialogCheck.value
        }
      }
    }
    this.confirmCallback(this.items);
    this._refresh();
  }

  getValue = () => this.shadowRoot.getElementById(CheckList.DIALOG_ID).getValues()

  validate = () => true

  _openDialog() {
    const dialog = this.shadowRoot.getElementById(CheckList.DIALOG_ID)
    dialog.show()
    send(CheckListDialog.EVENT_KEYS.RELOAD_TRIGGERED)
  }

  _getCheckListStatus() {
    if (this.isChecklistOverridden){
      return 'white'
    }

    let counter = 0
    for (const check of this.items) {
      if (check.isChecked) {
        counter++
      }
    }

    if (counter === 0) {
      return 'red'
    }
    if (counter === this.items?.length) {
      return 'green'
    }
    return 'orange'
  }
}
customElements.define('vzavg-checklist', CheckList)
