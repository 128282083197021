import {LitElement} from '@isceco/widget-library2/external/lit'
import I18n from '../i18n'
import ExceptionService from '../ExceptionService'

export default class TranslationElement extends LitElement {
  constructor() {
    super()
    this.i18n = new I18n()
    this.translationLoaded = false;
    this.logging = new ExceptionService()
    this.reloadListener = _ => {
      this.render()
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.i18n.loadConfiguration(this.translationFile)
      .then(() => {
        document.addEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this.reloadListener)
        this.translationLoaded = true;
        this.requestUpdate()
      })
      .catch(error => {
        this.logging.log(error)
      })
  }


}
