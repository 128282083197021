import ConfigUtil from './ConfigUtil.js'
import ExceptionService from '../ExceptionService.js'
import ApplicationException from '../ApplicationException.js'
import Betrieb from '../views/Geschaeftsvorfall/Betrieb/Betrieb.js'

export default class AdressverzeichnisService {

  constructor() {
    if (!AdressverzeichnisService.instance) {
      this.adressverzeichnisServiceUrl = ''
      this.configUtil = new ConfigUtil()
      this.logging = new ExceptionService()
      this.plzUrl = ''
      this.countriesUrl = ''
      this.communitiesUrl = ''

      this.plz = null
      this.countries = null
      this.communities = null

      this.loadCountries()
      this.loadCommunities()

      AdressverzeichnisService.instance = this
    }
    return AdressverzeichnisService.instance
  }

  async retrieveAdressVerzeichnisServiceUrl() {
    if (this.adressverzeichnisServiceUrl === '') {
      return this.configUtil.retrieveConfiguration()
        .then(config => {
          this.adressverzeichnisServiceUrl = `${config.adressverzeichnisServiceUrl}`
        }).catch(error => this.logging.log(error))
    }
    return this.adressverzeichnisServiceUrl
  }

  getPlzAutoComplete(zip, town) {
    return this._getPlzUrl()
      .then(url => {
         return this.checkError(
          fetch(url+'?zip='+zip+'&'+'town='+town, {
            cache: 'reload'
          }))
      })
  }

  loadCountries() {
    this._getCountriesUrl()
      .then(url => {
        this.checkError(
          fetch(url, {
              cache: 'reload'
            }
          ))
          .then(res => this.countries = res)
          .catch(_ => {
            this._showErrorMsg()
            return []
          })
      })
  }

  loadCommunities() {
    this._getCommunitiesUrl().then(url => {
      this.checkError(
        fetch(url, {
          cache: 'reload'
        })
      ).then(res => this.communities = res)
        .catch(_ => {
          this._showErrorMsg()
          return []
        })
    })
  }

  getCountryList() {
    const countryList = this.countries
      .map(country => ({value: country, name: country}))
      .sort((a, b) => a.name - b.name)

    const schweiz = countryList.splice(countryList.findIndex(country => country.value === Betrieb.SCHWEIZ), 1)
    const liechtenstein = countryList.splice(countryList.findIndex(country => country.value === Betrieb.LIECHTENSTEIN), 1)

    return [...schweiz, ...liechtenstein, {
      name: '--------------------',
      value: 'separator',
      disabled: true
    }, ...countryList]
  }

  _showErrorMsg() {
    showAlert('Adressverzeichnis-Service unavailable', 'Request to backend could not be sent', 'error')
  }

  async checkError(promise) {
    this.configUtil.updateToken()
    return promise
      .then(result => {
        if (result.ok) {
          const contentType = result.headers.get('content-type')
          if (contentType && contentType.indexOf('application/json') !== -1) {
            return result.json()
          } else {
            return result
          }
        } else {
          result.json().then(err => showAlert(err.title, err.detail, 'error'))
          throw new ApplicationException()
        }
      }).catch(error => {
        this.logging.log(error)
        throw error
      })
  }

  async _getPlzUrl() {
    if (this.plzUrl === '') {
      await this.retrieveAdressVerzeichnisServiceUrl();
      this.plzUrl = `${this.adressverzeichnisServiceUrl}/addresses/plz-autocomplete`
    }
    return this.plzUrl
  }

  async _getCountriesUrl() {
    if (this.countriesUrl === '') {
      await this.retrieveAdressVerzeichnisServiceUrl();
      this.countriesUrl = `${this.adressverzeichnisServiceUrl}/addresses/countries`
    }
    return this.countriesUrl
  }

  async _getCommunitiesUrl() {
    if (this.communitiesUrl === '') {
      await this.retrieveAdressVerzeichnisServiceUrl();
      this.communitiesUrl = `${this.adressverzeichnisServiceUrl}/addresses/communities`
    }
    return this.communitiesUrl;
  }

}
