import GenericColumn from '@isceco/widget-library2/basic-elements/List/column/GenericColumn'
import StringCell from '@isceco/widget-library2/basic-elements/List/cell/StringCell'
import DateCell from '@isceco/widget-library2/basic-elements/List/cell/DateCell'
import DefaultActionCell from '../../../../common/list/DefaultActionCell'
import {geschaeftsVorfallNavigationLinks} from '../../../Geschaeftsvorfall/Geschaeftsvorfall'

export default class NotizSearchColumns {
  static getColumns() {
    return [
      {
        column: new GenericColumn({
          key: 'codeKanton',
          text: 'suchen.result.column.kanton',
          sortable: true,
          cell: new StringCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },

      {
        column: new GenericColumn({
          key: 'firmenbezeichnung',
          text: 'suchen.result.column.firmenbezeichnung',
          sortable: true,
          cell: new StringCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },

      {
        column: new GenericColumn({
          key: 'strasse',
          text: 'suchen.result.column.strasse',
          sortable: true,
          cell: new StringCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },

      {
        column: new GenericColumn({
          key: 'ort',
          text: 'suchen.result.column.ort',
          sortable: true,
          cell: new StringCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },

      {
        column: new GenericColumn({
          key: 'eingangsdatum',
          text: 'suchen.result.pendenz.column.eingangsdatum',
          sortable: true,
          cell: new DateCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },

      {
        column: new GenericColumn({
          key: 'titel',
          text: 'suchen.result.notiz.column.titel',
          sortable: true,
          cell: new StringCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },
      {
        column: new GenericColumn({
          key: 'kategorie',
          text: 'suchen.result.notiz.column.kategorie',
          sortable: true,
          cell: new StringCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },

      {
        column: new GenericColumn({
          key: 'erfasser',
          text: 'suchen.result.column.erfasser',
          sortable: true,
          cell: new StringCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },

      {
        column: new GenericColumn({
          key: 'aktionen',
          text: '',
          weight: 0.5,
          align: 'right',
          cell: new DefaultActionCell({
            callback: (row, action) => {
              if (action === 'edit') {
                navigate({to: geschaeftsVorfallNavigationLinks.notizEdit.hash, id: `${row.gesuchId}/${row.id}`})
              }
            },
            buttons: (i18n, row) => {
              const buttons = []

              buttons.push(
                {
                  action: 'edit',
                  icon: 'clipboard',
                  variant: 'primary',
                  title: i18n.translate('suchen.result.betrieb.column.edit')
                }
              )

              return buttons
            }
          })
        }),
        alwaysDisplayed: true,
        defaultDisplayed: true
      }
    ]
  }
}
