import {css} from '@isceco/widget-library2/external/lit'

export default css`
  .status {
    padding: 1em;
    border-radius: 50%;
  }

  .check {
    background-color: var(--isceco-color-green-500);
    color:white;
  }

  .x {
    background-color: var(--isceco-color-red-500);
    color:white;
  }
`;
