import {css, html} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../../WebComponent.js'
import GesuchService from '../../../services/GesuchService'
import I18n from '../../../i18n'
import GeschaeftsvorfallNavigation from '../Navigation/GeschaeftsvorfallNavigation'
import '@isceco/widget-library2/basic-elements/DropdownMultiselect/DropdownMultiselect.js'
import '@isceco/widget-library2/basic-elements/Textarea/Textarea.js'
import StammdatenService from '../../../services/StammdatenService'
import AuthorizationFilter from '../../Authorization/AuthorizationFilter.js'
import BetriebsartDropdown from '../../../components/Gesuch/BetriebsartDropdown.js'
import '../../../components/DateInputWithWarning/DateInputWithWarning'
import Options from '../../../common/Options.js'
import '../../../components/DropdownMultiSelectGroup/DropdownMultiselectGroup.js'
import {geschaeftsVorfallNavigationLinks, geschaeftsVorfallNavigationLinksHelper} from '../Geschaeftsvorfall.js'
import '../../../components/Title/Title.js'


export const noYesInternationalFlOptions = i18n => {
  return [
    {
      value: 'no',
      name: i18n.translate('no')
    },
    {
      value: 'yes',
      name: i18n.translate('geschaeftsvorfall.gesuch.yesSwissOnly')
    },
    {
      value: 'yes_international',
      name: i18n.translate('geschaeftsvorfall.gesuch.yesInternational')
    },
    {
      value: 'yes_fl',
      name: i18n.translate('geschaeftsvorfall.gesuch.yesInternationalFl')
    }
  ]
}
export default class Gesuch extends WebComponent {

  static GESUCH_FORM_ID = '#gesuch-form'
  static I18N_BITTE_WAEHLEN_KEY = 'geschaeftsvorfall.gesuch.wert.auswaehlen'
  static BRANCH_GROUP = {
    nameKey: 'geschaeftsvorfall.gesuch.branchen.group',
    members: ['Industrielle',
      'Handwerkliche',
      'Technische',
      'Informatik',
      'Landwirtschaft',
      'Spedition',
      'Verkaufsberufe',
      'Gastgewerbe',
      'Kaufmaennische',
      'Bank',
      'Werbung',
      'Verlagswesen',
      'Gesundheits',
      'Uebrige',
      'Oeffentliche']
  }

  constructor(isClosedForKanton, isClosedForSeco) {
    super()
    this.isClosedForKanton = isClosedForKanton
    this.isClosedForSeco = isClosedForSeco

    this.service = new GesuchService()
    this.stammdatenService = new StammdatenService()
  }

  get css() {
    return css`
      .flex {
        display: flex;
        flex-wrap: wrap;
        gap: 2em;
        min-width: 10em;
      }

      .flex > * {
        flex: 1;
        gap: 0.5em;
      }

      .flex-column {
        display: flex;
        flex-direction: column;
      }

      .buttons {
        margin-top: 3em;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 0.5em;
      }

      .buttons-column {
        display: flex;
        gap: 0.5em;
        flex-direction: column;
        align-items: start;
      }

      .title-content {
        display: flex;
        gap: 0.5em;
        margin-bottom: 0.5em;
        align-items: center;
      }

      .title-content .status {
        font-size: 1.5em;
        margin-top: 0.5em;
      }

      .hidden {
        display: none;
      }

      #gesuchstyp-error {
        margin: 8px 0 0;
        font-weight: normal;
        color: var(--isceco-color-red-500);
        font-size: 0.875rem;
      }
    `
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Gesuch/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    this.gesuchId = getNavigationId()
    this.betriebId = sessionStorage.getItem('betriebId')

    if (isNewGesuch()) {
      this.service.getCurrentGesuchForBetrieb(this.betriebId).then(currentGesuch => {
        this.backendData = currentGesuch
        this.backendData.id = null
        this.backendData.gesuchsart = 'aenderungsgesuch'
        this.backendData.codeAenderungsgruende = []
        this._fetchStammdatenAndRender()
      })
    } else {
      this._getDataAndRender()
    }

    this._refresh = () => {
      this._getDataAndRender()
    }

    document.addEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)
  }

  disconnectedCallback() {
    document.removeEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)
  }

  _getDataAndRender() {
    this.backendData = {
      kantonCheckList: [],
      secoCheckList: [],
      liechtenstein: 'no',
      provision: 'no',
      isKantonChecklistOverridden: false,
      isSecoChecklistOverridden: false
    }

    this.service.read(this.gesuchId)
      .then(res => {
        // check if http response status is NoContent = 204 , then init for new entry
        if (res.status && res.status === 204) {
          this._fetchStammdatenAndRender()
          return
        }

        // object exist already, we are in an update case, fill data from backend response
        this.backendData = res
        this.backendData.kantonCheckList = []
        this.backendData.secoCheckList = []
        this.backendData.liechtenstein = this.backendData.liechtenstein ? this.backendData.liechtenstein : 'no'
        this.backendData.provision = this.backendData.provision ? this.backendData.provision : 'no'
        this.backendData.isChecklistOverridden = AuthorizationFilter.hasKantonRole() ? this.backendData.isKantonChecklistOverridden : this.backendData.isSecoChecklistOverridden

        this.service.readGesuchChecklist(this.gesuchId)
          .then(res1 => {
            if (res1.checks) {
              for (const check of res1.checks) {
                if (check.bereich === 'kanton') {
                  this.backendData.kantonCheckList.push(check)
                } else {
                  this.backendData.secoCheckList.push(check)
                }
              }
            }
          }).then(_ => {
          this._fetchStammdatenAndRender()
        })
      })
  }

  _fetchStammdatenAndRender() {
    this._getStammdaten()
    this._fetchOptions().then(_ => {
      this.render()
    })
  }

  _fetchOptions() {
    return Promise.all([
      this.stammdatenService.getBranchen(),
      this.stammdatenService.getQualifikationen(),
      this.stammdatenService.getBetriebsarten(),
      this.stammdatenService.getAenderungsgruende()
    ]).then(([branchen, qualifikationen, betriebsarten, aenderungsgruende]) => {
      this.qualifikationen = qualifikationen.map(qualifikation => (
        {value: qualifikation.code, name: qualifikation.bezeichnung}
      ))
      this.branchen = branchen.map(branche => (
        {value: branche.code, name: branche.bezeichnung}
      ))
      this.betriebsarten = betriebsarten.map(betriebsart => (
        {value: betriebsart.code, name: betriebsart.bezeichnung}
      ))
      this.aenderungsgruende = aenderungsgruende.map(aenderungsgrund => (
        {value: aenderungsgrund.code, name: aenderungsgrund.bezeichnung}
      ))
    })
  }

  _getStammdaten() {
    this.qualifikationen = []
    this.branchen = []
    this.betriebsarten = []
    this.aenderungsgruende = []
  }

  getTemplate() {
    return html`
      <vzavg-title text="${this.i18n.translate('geschaeftsvorfall.gesuch.header')}">
        <div slot="center" class="title-content">
          <vzavg-checklist
            id="kantonCheckListChecks"
            name="kantonCheckListChecks"
            type="kanton"
            .items="${this.backendData.kantonCheckList}"
            .isChecklistOverridden="${this.backendData.isKantonChecklistOverridden}"
            .confirmCallback="${_ => this._handleKantonCheckList()}"
          ></vzavg-checklist>
          <isceco-button
            id="buttonDokumenteAnzeigen"
            icon="file pdf outline"
            variant="secondary"
            @click="${_ => this._openDokumenteViewer()}"
          ></isceco-button>
          <vzavg-checklist
            id="secoCheckListChecks"
            name="secoCheckListChecks"
            type="seco"
            .items="${this.backendData.secoCheckList}"
            .isChecklistOverridden="${this.backendData.isSecoChecklistOverridden}"
            .confirmCallback="${_ => this._handleSecoCheckList()}"
          ></vzavg-checklist>
        </div>
        <div slot="right" class="title-content">
          <isceco-button
            id="save"
            title="${this.i18n.translate('geschaeftsvorfall.gesuch.button.speichern')}"
            icon="save"
            variant="primary"
            @click="${_ => this.save()}"
            ?disabled="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
          ></isceco-button>
          <isceco-button
            id="checklistOk"
            text="${this.i18n.translate('geschaeftsvorfall.checklist.checklist.ok')}"
            variant="secondary"
            ?disabled="${this.backendData.isChecklistOverridden || this.gesuchId === "0"}"
            @click="${() => this._handleChecklistOverride()}"
          ></isceco-button>
          <isceco-button
            id="buttonWeiter"
            variant="primary"
            icon="chevron right"
            @click="${_ => this._handleClickNext()}"
          ></isceco-button>
        </div>
      </vzavg-title>

      <isceco-form id="gesuch-form">
        <div slot="form-elements">
          <div class="flex">
            ${this.backendData.bewilligungspflicht === 'yes' ? this._renderGesuchInfoWithBewilligungspflichtYes() : this._renderGesuchInfoWithBewilligungspflichtNo()}
            ${this._renderBranchenAndQualifikationen()}
            ${this._renderSonstiges()}
          </div>
          <div style="text-align:right; padding-top:0.5em">
            <small>${this.i18n.translate('star.field.are.required')}</small>
          </div>
        </div>
      </isceco-form>
    `
  }

  _renderGesuchInfoWithBewilligungspflichtYes() {
    return html`
      <div class="flex-column">
        <isceco-title
          id="title-gesuch"
          text="${this.i18n.translate('geschaeftsvorfall.gesuch.header.anzeigen')}"></isceco-title>
        <isceco-dropdown
          id="bewilligungspflicht"
          name="bewilligungspflicht"
          label="${this.i18n.translate('geschaeftsvorfall.gesuch.bewilligungspflicht')}"
          value="${this.backendData.bewilligungspflicht}"
          .items="${this._bewilligungspflichtOptions()}"
          ?readonly="${true}"
        ></isceco-dropdown>
        <isceco-text-input
          id="eingangsdatum"
          name="eingangsdatum"
          value="${this.backendData.eingangsdatum ? this.backendData.eingangsdatum : new Date().toISOString().slice(0, 10)}"
          label="${this.i18n.translate('geschaeftsvorfall.gesuch.eingangsdatum')} *"
          type="date"
          @change="${e => this._updateValue(e)}"
          ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
          required
        ></isceco-text-input>
        <isceco-title
          text="${this.i18n.translate('geschaeftsvorfall.gesuch.gesuchstyp')}"
          size="small"
        ></isceco-title>
        <div>
          <isceco-dropdown
            id="arbeitsVermittlung"
            name="arbeitsVermittlung"
            value="${this.backendData.arbeitsVermittlung}"
            label="${this.i18n.translate('geschaeftsvorfall.gesuch.arbeitsVermittlung')} *"
            .items="${noYesInternationalFlOptions(this.i18n)}"
            @change="${e => this._handleArbeitsVermittlungChange(e)}"
            ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
            required
          ></isceco-dropdown>
          <isceco-dropdown
            id="personalVerleih"
            name="personalVerleih"
            value="${this.backendData.personalVerleih}"
            label="${this.i18n.translate('geschaeftsvorfall.gesuch.personalverleih')} *"
            .items="${noYesInternationalFlOptions(this.i18n)}"
            @change="${e => this._handlePersonalVerleihChange(e)}"
            ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
            required
          ></isceco-dropdown>
          <p id="gesuchstyp-error" class="hidden">
            <i class="exclamation triangle icon" style="color: var(--isceco-color-red-500)"></i>
            ${this.i18n.translate('geschaeftsvorfall.gesuch.gesuchstyp.error')}
          </p>
        </div>
        <isceco-dropdown
          id="gesuchsart"
          name="gesuchsart"
          value="${this.backendData.gesuchsart}"
          label="${this.i18n.translate('geschaeftsvorfall.gesuch.gesuchsart')} *"
          placeholder="${this.i18n.translate(Gesuch.I18N_BITTE_WAEHLEN_KEY)}"
          .items="${this._gesuchsartOptions()}"
          @change="${e => this._updateValueAndReload(e)}"
          readonly
          required
        ></isceco-dropdown>
        ${this.backendData.gesuchsart === 'aenderungsgesuch' ?
          html`
            <isceco-dropdown-multiselect
              id="codeAenderungsgruende"
              name="codeAenderungsgruende"
              label="${this.i18n.translate('geschaeftsvorfall.gesuch.aenderungsgrund') + ' *'}"
              placeholder="${this.i18n.translate(Gesuch.I18N_BITTE_WAEHLEN_KEY)}"
              .value="${this.backendData.codeAenderungsgruende}"
              .items="${this.aenderungsgruende}"
              @change="${e => this._updateValueAndReload(e)}"
              ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
              ?required="${this.backendData.gesuchsart === 'aenderungsgesuch'}"
            ></isceco-dropdown-multiselect>
          ` : html``}
        ${this.backendData.codeAenderungsgruende && this.backendData.codeAenderungsgruende.includes('aufhebungDerBewilligung') ?
          html`
            <vzavg-date-input-with-warning
              id="aufhebungPer"
              name="aufhebungPer"
              value="${this.backendData.aufhebungPer}"
              label="${this.i18n.translate('geschaeftsvorfall.gesuch.aufhebungPer')} *"
              .warningMinDescription="${this.i18n.translate('geschaeftsvorfall.gesuch.dialog.confirm.date.minDescription')}"
              .warningMaxDescription="${this.i18n.translate('geschaeftsvorfall.gesuch.dialog.confirm.date.maxDescription')}"
              .warningMaxDate="${this._maxDateAufhebungPer()}"
              .warningMinDate="${this._minDateAufhebungPer()}"
              @change="${e => this._updateValue(e)}"
              ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
              required
            ></vzavg-date-input-with-warning>
          `
          : html``
        }
      </div>
    `
  }

  _renderGesuchInfoWithBewilligungspflichtNo() {
    return html`
      <div class="flex-column">
        <isceco-title
          id="title-gesuch"
          text="${this.i18n.translate('geschaeftsvorfall.gesuch.header.anzeigen')}"></isceco-title>
        <isceco-dropdown
          id="bewilligungspflicht"
          name="bewilligungspflicht"
          label="${this.i18n.translate('geschaeftsvorfall.gesuch.bewilligungspflicht')}"
          value="${this.backendData.bewilligungspflicht}"
          .items="${this._bewilligungspflichtOptions()}"
          ?readonly="${true}"
        ></isceco-dropdown>
        <isceco-text-input
          id="eingangsdatum"
          name="eingangsdatum"
          value="${this.backendData.eingangsdatum ? this.backendData.eingangsdatum : new Date().toISOString().slice(0, 10)}"
          label="${this.i18n.translate('geschaeftsvorfall.gesuch.eingangsdatum')} *"
          type="date"
          @change="${e => this._updateValue(e)}"
          ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
          required
        ></isceco-text-input>
        <isceco-title
          text="${this.i18n.translate('geschaeftsvorfall.gesuch.gesuchstyp')}"
          size="small"
        ></isceco-title>
        <isceco-dropdown
          id="personalVerleih"
          name="personalVerleih"
          value="${this.backendData.personalVerleih}"
          label="${this.i18n.translate('geschaeftsvorfall.gesuch.personalverleih')}"
          .items="${noYesInternationalFlOptions(this.i18n)}"
          @change="${e => this._handlePersonalVerleihChange(e)}"
          ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
        ></isceco-dropdown>
        <isceco-dropdown
          id="arbeitsVermittlung"
          name="arbeitsVermittlung"
          value="${this.backendData.arbeitsVermittlung}"
          label="${this.i18n.translate('geschaeftsvorfall.gesuch.arbeitsVermittlung')}"
          .items="${noYesInternationalFlOptions(this.i18n)}"
          @change="${e => this._handleArbeitsVermittlungChange(e)}"
          ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
        ></isceco-dropdown>
        <isceco-text-input
          id="bestaetigungsdatum"
          name="bestaetigungsdatum"
          value="${this.backendData.bestaetigungsdatum}"
          label="${this.i18n.translate('geschaeftsvorfall.gesuch.bestaetigungsdatum')} *"
          type="date"
          @change="${e => this._updateValue(e)}"
          ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
          required
        ></isceco-text-input>

      </div>
    `
  }

  _renderBranchenAndQualifikationen() {
    return html`
      <div class="flex-column">
        <isceco-title
          id="title-branchen"
          text="${this.i18n.translate('geschaeftsvorfall.gesuch.header.branchen') + (this.backendData.bewilligungspflicht === 'yes' ? ' *' : '')}"
        ></isceco-title>
        <vzavg-dropdown-multiselect
          id="codeBranchen"
          name="codeBranchen"
          placeholder="${this.i18n.translate(Gesuch.I18N_BITTE_WAEHLEN_KEY)}"
          .value="${this.backendData.codeBranchen}"
          .items="${this.branchen}"
          @change="${e => this._handleBranchenChange(e)}"
          ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
          .group="${Gesuch.BRANCH_GROUP}"
          .i18n="${this.i18n}"
          ?required="${this.backendData.bewilligungspflicht === 'yes'}"
        ></vzavg-dropdown-multiselect>

        <isceco-title
          id="title-qualifikationen"
          text="${this.i18n.translate('geschaeftsvorfall.gesuch.header.qualifikationen') + (this.backendData.bewilligungspflicht === 'yes' ? ' *' : '')}"
        ></isceco-title>
        <isceco-dropdown-multiselect
          id="codeQualifikationen"
          name="codeQualifikationen"
          placeholder="${this.i18n.translate(Gesuch.I18N_BITTE_WAEHLEN_KEY)}"
          .value="${this.backendData.codeQualifikationen}"
          .items="${this.qualifikationen}"
          @change="${e => this._updateValue(e)}"
          ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
          ?required="${this.backendData.bewilligungspflicht === 'yes'}"
        ></isceco-dropdown-multiselect>
      </div>
    `
  }

  _renderSonstiges() {
    return html`
      <div class="flex-column">
        <isceco-title
          id="title-sonstiges"
          text="${this.i18n.translate('geschaeftsvorfall.gesuch.header.sonstiges')}"
        ></isceco-title>
        <betriebsart-dropdown
          id="codeBetriebsart"
          name="codeBetriebsart"
          value="${this.betriebsarten.length > 0 ? this.backendData.codeBetriebsart : []}"
          placeholder="${this.i18n.translate(Gesuch.I18N_BITTE_WAEHLEN_KEY)}"
          .i18n="${this.i18n}"
          .items="${this.betriebsarten}"
          .readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
          ?required="${this.backendData.bewilligungspflicht === 'yes'}"
        ></betriebsart-dropdown>
        ${this.backendData.arbeitsVermittlung !== 'no' && this.backendData.bewilligungspflicht === 'yes' ?
          html`
            <isceco-radio-group
              id="provision"
              name="provision"
              value="${this.backendData.provision}"
              label="${this.i18n.translate('geschaeftsvorfall.gesuch.provision')} *"
              .items="${Options.yesNo}"
              @change="${e => this._updateValue(e)}"
              ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
              required
            ></isceco-radio-group>
          ` : html``
        }
        <isceco-textarea
          id="angabenBrancheBeruf"
          name="angabenBrancheBeruf"
          value="${this.backendData.angabenBrancheBeruf}"
          label="${this.i18n.translate('geschaeftsvorfall.gesuch.angabenBrancheBeruf')}"
          @change="${e => this._updateValue(e)}"
          ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
          resize="auto"
          rows="10"
          maxlength="300"
        ></isceco-textarea>
      </div>
    `
  }

  _bewilligungspflichtOptions() {
    return [
      {
        value: 'yes',
        name: this.i18n.translate('yes')
      },
      {
        value: 'no',
        name: this.i18n.translate('no')
      },
      {
        value: 'inClarification',
        name: this.i18n.translate('geschaeftsvorfall.gesuch.bewilligungspflicht.in.clarification')
      }
    ]
  }

  _gesuchsartOptions() {
    return [
      {value: 'erstgesuch', name: this.i18n.translate('geschaeftsvorfall.gesuch.gesuchsart.erstgesuch')},
      {value: 'aenderungsgesuch', name: this.i18n.translate('geschaeftsvorfall.gesuch.gesuchsart.aenderungsgesuch')}
    ]
  }

  _handleClickNext() {
    const link = geschaeftsVorfallNavigationLinksHelper.next()
    navigate({to: link.hash, id: this.gesuchId})
  }

  setGesuchstypErrorVisible(visible) {
    const gesuchstypErrorMessage = document.getElementById('gesuchstyp-error')
    if (gesuchstypErrorMessage) {
      if (visible) {
        gesuchstypErrorMessage.classList.remove('hidden')
      } else {
        gesuchstypErrorMessage.classList.add('hidden')
      }
    }
  }

  _openDokumenteViewer() {
    const to = `#${geschaeftsVorfallNavigationLinks.documentViewer.hash}`
    const id = this.gesuchId
    window.open(constructURL({to, id}), "_blank");
  }

  _handleKantonCheckList() {
    this.kantonCheckListChecks = document.getElementById('kantonCheckListChecks').getValue()
    this.save()
  }

  _handleSecoCheckList(){
    this.secoCheckListChecks = document.getElementById('secoCheckListChecks').getValue()
    this.save()
  }

  save() {
    const formElement = document.querySelector(Gesuch.GESUCH_FORM_ID)
    let isGesuchstypInvalid = false
    if (this.backendData.bewilligungspflicht === 'no' || this.isArbeitVermittlungValid() || this.isPersonalVerleihValid()) {
      this.setGesuchstypErrorVisible(false)
    } else {
      this.setGesuchstypErrorVisible(true)
      isGesuchstypInvalid = true
    }

    if (!formElement.validate() || isGesuchstypInvalid) {
      this.reload()
      return Promise.resolve(false);
    }

    const values = formElement.getValues()
    values.kantonCheckListChecks = this.kantonCheckListChecks
    values.secoCheckListChecks = this.secoCheckListChecks
    values.isKantonChecklistOverridden = this.backendData.isKantonChecklistOverridden
    values.isSecoChecklistOverridden = this.backendData.isSecoChecklistOverridden
    values.aufhebungPer = this.backendData.aufhebungPer

    if (this.backendData.id) {
      values.id = this.backendData.id
      values.gemeinnuetzigkeit = this.backendData.gemeinnuetzigkeit
      return this.service.update(values)
        .then(_1 => {
          this._createOrGetDokument()
        })
    } else {
      values.betriebId = this.betriebId
      this.service.create(values)
        .then(gesuchId => {
          this.gesuchId = gesuchId
          navigate({to: geschaeftsVorfallNavigationLinks.gesuch.hash, id: gesuchId})
          this._showSuccessAlert()
        })
    }
    return Promise.resolve(false)
  }

  _showSuccessAlert() {
    showAlert(
      this.i18n.translate('gesuch.update.success.title'),
      this.i18n.translate('gesuch.update.success.message'),
      'success'
    )
  }

  _createOrGetDokument() {
    // refresh status for dokumente, based on aenderungsgründe
    this.service.createOrGetDokument(this.gesuchId + '/dokument/gesuch/detail')
      .then(dokumentId => {
        this.service.read(this.gesuchId, `dokument/gesuch/status/${dokumentId}`).then(_ => {
          this._showSuccessAlert()
          this._refresh()
          send(GeschaeftsvorfallNavigation.EVENT_KEYS.RELOAD_STATUS_TRIGGERED)
          return true
        })
      }).catch(_2 => false)
  }

  isPersonalVerleihValid() {
    return this.backendData.personalVerleih === 'yes' || this.backendData.personalVerleih === 'yes_international' || this.backendData.personalVerleih === 'yes_fl'
  }

  isArbeitVermittlungValid() {
    return this.backendData.arbeitsVermittlung === 'yes' || this.backendData.arbeitsVermittlung === 'yes_international' || this.backendData.arbeitsVermittlung === 'yes_fl'
  }

  async _handlePersonalVerleihChange(e) {
    this._updateValueAndReload(e)
    await this._getBetriebsartVorlage()
  }

  async _handleArbeitsVermittlungChange(e) {
    this._updateValueAndReload(e)
    await this._getBetriebsartVorlage()
  }

  async _handleBranchenChange(e) {
    this._updateValue(e)
    await this._getBetriebsartVorlage()
  }

  async _getBetriebsartVorlage() {
    const {personalVerleih, arbeitsVermittlung, codeBranchen, gemeinnuetzigkeit} = this.backendData
    const response = await this.service.getBetriebsartVorlage(this.betriebId, personalVerleih, arbeitsVermittlung, codeBranchen, gemeinnuetzigkeit)
    const vorschlag = response.betriebsartCode

    send(BetriebsartDropdown.EVENT_KEYS.RELOAD_TRIGGER, vorschlag)
  }

  _minDateAufhebungPer() {
    const aYearAgo = new Date()
    aYearAgo.setFullYear(aYearAgo.getFullYear() - 1)
    aYearAgo.setHours(0, 0, 0, 0)
    return aYearAgo
  }

  _maxDateAufhebungPer() {
    const aMonthInFuture = new Date()
    aMonthInFuture.setMonth(aMonthInFuture.getMonth() + 1)
    aMonthInFuture.setHours(23, 59, 59, 999)
    return aMonthInFuture
  }

  _updateValue(event) {
    if ((event.target.tagName === 'ISCECO-DROPDOWN-MULTISELECT' || event.target.tagName === 'VZAVG-DROPDOWN-MULTISELECT')
      && event.detail.value === undefined) {
      event.detail.value = []
    }
    const key = event.target.id
    const value = event.detail.value === undefined ? event.detail : event.detail.value
    if (this.backendData[key] !== value) {
      window.hasChanges = true;
      this.backendData[key] = value
    }
  }

  _updateValueAndReload(event) {
    this._updateValue(event)
    this.reload()
  }

  _handleChecklistOverride() {
    if (AuthorizationFilter.hasKantonRole()) {
      this.backendData.isKantonChecklistOverridden = true
    } else {
      this.backendData.isSecoChecklistOverridden = true
    }
    this.backendData.isChecklistOverridden = true
    this.save()
  }
}
customElements.define('vzavg-frontend-geschaeftsvorfall-gesuch', Gesuch)
