import WebComponent from '../../../../WebComponent'
import StammdatenService from '../../../../services/StammdatenService'
import {html} from '@isceco/widget-library2/external/lit'
import I18n from '../../../../i18n'

export default class VerantwortlichePFilter extends WebComponent {

  constructor() {
    super()
    const stammdatenService = new StammdatenService();
    stammdatenService.getKantone()
      .then(kantone => {
        this.kantone = kantone.map(kanton => ({value: kanton.code, name: kanton.bezeichnung}))
      })
      .then(_ => this.render())
  }

  get translationFile() {
    return './views/Suchen/Form/Filter/i18n.json'
  }

  get values() {
    return this._values
  }

  get verantwortlichePStatus() {
    return [
      {name: this.i18n.translate('suchen.aktiv'), value: true},
      {name: this.i18n.translate('suchen.inaktiv'), value: false}
    ]
  }

  set values(val) {
    this._values = val
  }

  async connectedCallback() {
    super.connectedCallback()
    this._refresh = () => {
      this.render()
    }


    document.addEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)
    this.render()
  }

  disconnectedCallback() {
    document.removeEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)
  }

  getTemplate() {
    return html`
      <div class="filter-wrapper">
        <isceco-dropdown label="${this.i18n.translate('suchen.kanton')}" id="kanton" name="kanton"
                         .items="${this.kantone}"
                         .value="${this.values?.kanton ?? sessionStorage.getItem('userRechtCodeKanton')}"
        ></isceco-dropdown>
        <isceco-dropdown label="${this.i18n.translate('suchen.status')}" id="isActive" name="isActive"
                         .items="${this.verantwortlichePStatus}"
                         .value="${this.values?.isActive}"
        ></isceco-dropdown>
        <isceco-date-input label="${this.i18n.translate('suchen.verantwortlicheP.taetigVon')}" id="taetigVon"
                           name="taetigVon"
                           .value="${this.values?.taetigVon}"
                           @change="${event => this._updateBisDate(event.detail.value, "taetigBis", "taetigVon")}"
        >
        </isceco-date-input>
        <isceco-date-input label="${this.i18n.translate('suchen.verantwortlicheP.taetigBis')}" id="taetigBis"
                           name="taetigBis"
                           .min="${this.values?.taetigVon}"
                           .value="${this.values?.taetigBis}"


        ></isceco-date-input>
        <isceco-date-input label="${this.i18n.translate('suchen.verantwortlicheP.sperrdatumVon')}"
                           id="sperrdatumVon"
                           name="sperrdatumVon"
                           .value="${this.values?.sperrdatumVon}"
                           @change="${event => this._updateBisDate(event.detail.value, "sperrdatumBis", "sperrdatumVon")}"
        >
        </isceco-date-input>
        <isceco-date-input label="${this.i18n.translate('suchen.verantwortlicheP.sperrdatumBis')}"
                           id="sperrdatumBis"
                           name="sperrdatumBis"
                           .min="${this.values?.sperrdatumVon}"
                           .value="${this.values?.sperrdatumBis}"
        >
        </isceco-date-input>
      </div>
    `
  }

  _updateBisDate(newDate, key1, key2 = undefined) {
    this.values[key1] = newDate
    this.values[key2] = newDate
    this.render()
  }
}
customElements.define('vzavg-frontend-suche-verantwortliche-person-filter', VerantwortlichePFilter)
