import {html, LitElement} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Popup/Popup.js'
import ModalDialog from '@isceco/widget-library2/basic-elements/ModalDialog/ModalDialog.js'
import DropdownCss from './SearchableTextInputCss.js'


export default class SearchableTextInput extends LitElement {

  static get styles() {
    return [DropdownCss]
  }

  static get properties() {
    return {
      id: {type: String},
      label: {type: String},
      helpText: {attribute: 'help-text', type: String | Function},
      value: {type: String | Number},
      valueText: {type: String},
      items: {type: Array},
      i18n: {type: Array},
      placeholder: {type: String},
      placeholderEmpty: {attribute: 'placeholder-empty', type: String},
      inline: {type: Boolean},
      disabled: {type: Boolean},
      required: {type: Boolean},
      readonly: {type: Boolean}
    }
  }

  constructor() {
    super()
    this.optionsOpened = false
    this.message = ''
    this.valueChanged = false
    this.focusedValue = null
    this.itemsFiltered = []
  }

  connectedCallback() {
    super.connectedCallback()
    this.focusedValue = this.value
    this.itemsFiltered = this.items

    this._refresh = _ => {
      this.itemsFiltered = this.items
      this.requestUpdate()
      this.updateComplete.then(_1 => {
        this._updateComplete()
      })
    }

    document.addEventListener('refreshSearchableTextInput', this._refresh)
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    document.removeEventListener('refreshSearchableTextInput', this._refresh)
  }

  render() {
    return this.readonly ? this.renderReadonly() : this.renderComponent()
  }

  renderComponent() {
    const displayedText = this.optionsOpened ? '' : this._selectedName()
    const placeholder = this.optionsOpened ? this._selectedName() : this.placeholder

    return html`
      <style>@import '${iscecoWidgetLibrary.iconCss}'</style>
      <div class="${this.inline ? 'inline' : ''}">
        <div class="label-wrapper" @click="${() => this._toggleSelect()}">
          <label for="isceco-dropdown">
            ${this.label}
          </label>
          ${this.renderInfo()}
        </div>
        <div class="select-container">
          <div class="dropdown ${this.optionsOpened ? 'open' : 'closed'} ${this.disabled ? 'disabled' : ''}">
            <input type="text"
                   class="field"
                   .value="${displayedText}"
                   placeholder="${placeholder}"
                   ?disabled="${this.disabled}"
                   @click="${() => this._toggleSelect()}"
                   @keydown="${e => this._handleKeydown(e)}"
                   @keyup="${e => this._handleKeyup(e)}"/>
            ${this._renderArrowOrClear()}
            <div class="options">
              <ul>${this._renderSelectOptions()}</ul>
            </div>
          </div>
        </div>
      </div>
      <p id="message">
        <i class="exclamation triangle icon"></i>
        ${this.message}
      </p>
    `
  }

  renderReadonly() {
    const selectedItems = this.items.filter(x => x.value === this.value)
    const selectedItem = selectedItems.length > 0 ? selectedItems[0].name : ''
    let name = this._getOptionName(selectedItem)

    // Wenn kein Wert ausgewählt ist, wird der Platzhalter-Text angezeigt
    if (isEmpty(name) && this.placeholderEmpty) {
      name = this.placeholderEmpty
    }

    return html`
      <style>@import '${iscecoWidgetLibrary.iconCss}'</style>
      <div class="readonly ${this.inline ? 'inline' : ''}">
        <div class="label-wrapper">
          <span>${this.label}</span>
          ${this.renderInfo()}
        </div>
        <p>
          ${name}
        </p>
      </div>
    `
  }

  renderInfo() {
    if (isEmpty(this.helpText)) {
      return html``
    } else {
      const helpText = this.helpText instanceof Function ? this.helpText() : this.helpText
      return html`
        <isceco-popup direction="right">
          <i slot="wrapper" class="info circle icon"></i>
          <div slot="content">${helpText}</div>
        </isceco-popup>
      `
    }
  }

  validate = () => {
    if (this.readonly) {
      return true
    }

    const valid = !this.required || !isEmpty(this.value)
    if (valid) {
      this.classList.remove('error')
      this.message = ''
    } else {
      this.classList.add('error')
      this.message = translateText(this._translations(), 'empty')
    }
    this.requestUpdate()
    return valid
  }

  getValue = () => this.value

  hasChanges = () => this.valueChanged

  _change(value, name) {
    this.value = value ?? ''
    this.valueText = name ?? ''
    this.valueChanged = true

    const valid = this.validate()

    send('change', {
      valid: valid,
      value: this.value,
      name: this.valueText
    }, this)

    this.closeOptions()
  }

  _toggleSelect() {
    if (!this.disabled) {
      if (this.optionsOpened) {
        this.closeOptions()
      } else {
        this._openOptions()
      }
    }
  }

  _openOptions() {
    if (this.optionsOpened) {
      return
    }
    this.shadowRoot.querySelector('input.field').focus()
    this.optionsOpened = true
    this._setDropdownPosition()
    this.focusedValue = this.value
    this.itemsFiltered = this.items
    this._focusValue(true)
    window.addEventListener('click', this._hide)
    window.addEventListener('scroll', this._setDropdownPosition)
    window.addEventListener('resize', this._setDropdownPosition)
    window.addEventListener(ModalDialog.EVENT_KEYS.SCROLL, this._setDropdownPosition)
    this.requestUpdate()
  }

  closeOptions() {
    if (!this.optionsOpened) {
      return
    }
    this.optionsOpened = false
    window.removeEventListener('click', this._hide)
    window.removeEventListener('scroll', this._setDropdownPosition)
    window.removeEventListener('resize', this._setDropdownPosition)
    window.removeEventListener(ModalDialog.EVENT_KEYS.SCROLL, this._setDropdownPosition)
    this.requestUpdate()
  }

  _hide = e => {
    if (this !== e.target) {
      this.closeOptions()
    }
  }

  _setDropdownPosition = () => {
    const fieldRect = this.shadowRoot.querySelector('.field').getBoundingClientRect()
    const items = this.shadowRoot.querySelector('.options > ul')
    items.style.top = `${this._calculatePosition(fieldRect, items)}px`
    items.style.left = `${fieldRect.left}px`
  }

  // avoid options going off-screen at the bottom
  _calculatePosition = (fieldRect, items) => {
    const itemHeight = items.getBoundingClientRect().height > 0 ? items.getBoundingClientRect().height : Math.min(300, this.itemsFiltered.length * 50)
    const maxYPos = window.innerHeight - itemHeight
    return (fieldRect.bottom < window.innerHeight && fieldRect.bottom > maxYPos) ? maxYPos : fieldRect.bottom
  }

  _selectedName(value = this.value) {
    return isEmpty(value) ? '' : this._getOptionName(this.items.find(option => option.value.toString() === value.toString())?.name)
  }

  _renderArrowOrClear() {
    if (this.value && !this.required) {
      return html`
        <div class="field-icon clear" @click="${() => this._change('', '')}">
          <i class="icon times circle"></i>
        </div>
      `
    }
    return html`
      <div class="field-icon arrow">
        <i class="icon angle down"></i>
      </div>
    `
  }

  _renderSelectOptions() {
    const options = []
    if (this.placeholder !== undefined) {
      options.push(html`
        <li value="" @click="${() => this._change('', this.placeholder)}">${this.placeholder}</li>
      `)
    }
    this.itemsFiltered.forEach(option => {
      const name = this._getOptionName(option.listValueText ?? option.name)
      options.push(html`
        <li title="${name}"
            value="${option.value}"
            class="${option.disabled ? 'field-disabled' : ''}"
            @click="${() => this._change(option.value, name)}"
            @mouseenter="${() => {
              if (!option.disabled) {
                this.focusedValue = option.value
                this._focusValue()
              }
            }}"
        >
          ${name}
        </li>
      `)
    })
    return options
  }

  _getOptionName(key) {
    return this.i18n ? this.i18n.translate(key) : key
  }

  _translations() {
    return {
      de: {
        empty: 'Wert darf nicht leer gelassen werden.'
      },
      fr: {
        empty: 'La valeur ne doit pas être laissée vide.'
      },
      it: {
        empty: 'Il valore non deve essere lasciato vuoto.'
      },
      en: {
        empty: 'Value must not be left empty.'
      }
    }
  }

  // open / close dropdown
  _handleKeydown(e) {
    switch (e.code) {
      case 'Escape':
        e.preventDefault()
        this.closeOptions()
        return
      case 'Tab':
        if (this.optionsOpened) {
          this._change(this.focusedValue, this._focusedName())
        }
        return
      case 'Enter':
        e.preventDefault()
        if (this.optionsOpened) {
          this._change(this.focusedValue, this._focusedName())
        } else {
          this._openOptions()
        }
        return
      case 'ArrowDown':
        e.preventDefault()
        this._updateFocus(this._nextValue)
        return
      case 'ArrowUp':
        e.preventDefault()
        this._updateFocus(this._previousValue)
        return
      default:
        break
    }

    // any key other than Escape, Tab, Enter, Up and Down opens the dropdown
    if (!this.optionsOpened) {
      this._openOptions()
    }
  }
  // update filtered items list
  _handleKeyup(e) {
    if (['Escape', 'Tab', 'Enter', 'ArrowDown', 'ArrowUp'].includes(e.code)) {
      return
    }

    if (this.id === 'plz' || this.id === 'ort') {
      send('change', {
        valid: false,
        value: e.target.value.toLowerCase(),
        name: e.target.value.toLowerCase()}, this)
    } else {
      this.itemsFiltered = this.items
        .filter(({name, disabled}) => name.toLowerCase().startsWith(e.target.value.toLowerCase()) || disabled)
      this.requestUpdate()
      this.updateComplete.then(_ => {
          this._updateComplete()
      })
    }
  }

  _updateComplete() {
      const itemsEnabled = this.itemsFiltered.filter(item => !item.disabled)
      if (itemsEnabled.length > 0 && this._indexOfValue() < 0) {
        this.focusedValue = itemsEnabled[0].value
        this._focusValue(true)
      }
  }

  // update the focused item, func is either _nextValue or _previousValue
  _updateFocus = func => {
    if (this.optionsOpened) {
      this.focusedValue = func()
      this._focusValue(true)
    } else {
      this.focusedValue = this.value
      this.focusedValue = func()
      if (this.focusedValue !== this.value) {
        this._change(this.focusedValue, this._focusedName())
      }
    }
  }

  // get display name of the focused value
  _focusedName = () => this._selectedName(this.focusedValue)

  // get index in the current item list of the focused item
  _indexOfValue = () => this.itemsFiltered.findIndex(item => item.value === this.focusedValue)

  // get the value of the next item
  _nextValue = () => this.itemsFiltered[Math.min(this._indexOfValue() + 1, this.itemsFiltered.length - 1)].value

  // get the value of the previous item
  _previousValue = () => {
    const index = this._indexOfValue(this.focusedValue)
    if (this.placeholder && index <= 0) {
      return ''
    }
    return this.itemsFiltered[Math.max(index - 1, 0)].value
  }

  // redraw the focus: unfocus previous, focus new, scroll to focused
  _focusValue = (scrollIntoView = false) => {
    this.shadowRoot.querySelectorAll(`li.focus`).forEach(item => item.classList.remove('focus'))

    let element
    if (isEmpty(this.focusedValue)) {
      if (this.placeholder) {
        element = this.shadowRoot.querySelector(`li[value]`)
      }
    } else {
      element = this.shadowRoot.querySelector(`li[value="${this.focusedValue}"]`)
    }
    element?.classList.add('focus')
    if (scrollIntoView) {
      this._scrollTo(element)
    }
  }

  // make the element fully visible in the options
  _scrollTo = element => {
    if (!element) {
      return
    }
    const options = this.shadowRoot.querySelector('.options > ul')
    if (options.clientHeight === options.scrollHeight) {
      return
    }
    const optionsTop = options.scrollTop;
    const optionsBottom = optionsTop + options.clientHeight;
    const elementTop = element.offsetTop;
    const elementBottom = elementTop + element.clientHeight;
    if (elementTop < optionsTop) {
      options.scrollTop -= (optionsTop - elementTop);
    } else if (elementBottom > optionsBottom) {
      options.scrollTop += (elementBottom - optionsBottom);
    }
  }
}

customElements.define('vzavg-searchable-text-input', SearchableTextInput)
